const base_url = window.location.origin;

const AvatarConstants = {
    AVATAR_COLORS: ['#f6a404', '#0c63be', '#dc3545', '#1f9436', '#d7441c', '#800080', '#b40199'],
    AVATAR_DEFAULT_IMAGES: {
        id: 1,
        imagePath: base_url + '/assets/images/avatars/camera.svg',
    },

    AVATAR_IMAGES: [
        {
            id: 1,
            imagePath: base_url + '/assets/images/avatars/avMonster_1.png',
        },
        {
            id: 2,
            imagePath: base_url + '/assets/images/avatars/avAnimal_2.png',
        },
        {
            id: 3,
            imagePath: base_url + '/assets/images/avatars/avAnimal_3.png',
        },
        {
            id: 4,
            imagePath: base_url + '/assets/images/avatars/avAnimal_6.png',
        },
        {
            id: 5,
            imagePath: base_url + '/assets/images/avatars/avAnimal_7.png',
        },
        {
            id: 6,
            imagePath: base_url + '/assets/images/avatars/avAnimal_9.png',
        },
        {
            id: 7,
            imagePath: base_url + '/assets/images/avatars/avHalloween_2.png',
        },
        {
            id: 8,
            imagePath: base_url + '/assets/images/avatars/avHalloween_4.png',
        },
        {
            id: 9,
            imagePath: base_url + '/assets/images/avatars/avHalloween_5.png',
        },
        {
            id: 10,
            imagePath: base_url + '/assets/images/avatars/avHalloween_6.png',
        },
        {
            id: 11,
            imagePath: base_url + '/assets/images/avatars/avHalloween_8.png',
        },
        {
            id: 12,
            imagePath: base_url + '/assets/images/avatars/avMonster_6.png',
        },
        {
            id: 13,
            imagePath: base_url + '/assets/images/avatars/avMonster_10.png',
        },
        {
            id: 14,
            imagePath: base_url + '/assets/images/avatars/avMonster_14.png',
        },
        {
            id: 15,
            imagePath: base_url + '/assets/images/avatars/avMonster_42.png',
        },
        {
            id: 16,
            imagePath: base_url + '/assets/images/avatars/avRobot_13.png',
        },
        {
            id: 17,
            imagePath: base_url + '/assets/images/avatars/avRobot_16.png',
        },
        {
            id: 18,
            imagePath: base_url + '/assets/images/avatars/avRobot_35.png',
        },
        {
            id: 19,
            imagePath: base_url + '/assets/images/avatars/avRobot_38.png',
        },
        {
            id: 20,
            imagePath: base_url + '/assets/images/avatars/avRobot_42.png',
        },
    ],
    SOUND_IMAGE: base_url + '/assets/animation/soundwave.gif',
};

export default AvatarConstants;
