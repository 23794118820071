import { Box, Button, Modal, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { InterfaceLanguages } from '../../../../constants/languages-constant';
import { useDispatch, useSelector } from 'react-redux';
import { participantActions } from '../../../../actions/participant.actions';
import { StoreInterface } from '../../../../interfaces/store.interface';

const ChangeLanguageModal = ({ openChangeLanguage, closeChangeLanguage }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const participant = useSelector((state: StoreInterface) => state.participant);
    const currentLanguageCode = participant.language;
    const [selectedLanguageCode, setSelectedLanguageCode] = useState(currentLanguageCode);

    const currentLanguage = InterfaceLanguages.find((result) => {
        return result.code === currentLanguageCode;
    });

    const languagesExceptCurrent = InterfaceLanguages.filter((result) => {
        return result.code !== currentLanguageCode;
    }).sort(function (a: any, b: any) {
        if (a.nameInEnglish < b.nameInEnglish) {
            return -1;
        }
        if (a.nameInEnglish > b.nameInEnglish) {
            return 1;
        }
        return 0;
    });

    const chooseLanguage = (languageCode: string) => {
        setSelectedLanguageCode(languageCode);
    };

    const updateCurrentLanguage = () => {
        i18next.changeLanguage(selectedLanguageCode);
        dispatch(participantActions.changeLanguage(selectedLanguageCode));
        closeChangeLanguage();
    };

    const closeChangeLanguageModal = () => {
        setSelectedLanguageCode(currentLanguageCode);
        closeChangeLanguage();
    };

    return (
        <div>
            <Modal
                open={openChangeLanguage}
                onClose={closeChangeLanguageModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="language_modal"
            >
                <Box className="language_modal_container">
                    <div className="language_wrapper">
                        <div className="close_modal" onClick={closeChangeLanguageModal}>
                            <CloseRoundedIcon />
                        </div>
                        <div className="title">
                            <Typography variant="h4">{t(`lang_participant_main.heading_change_language`)}</Typography>
                        </div>
                        <div className="current_language_box">
                            <Typography variant="body1">
                                {t(`lang_participant_main.txt_current_language_details`)}
                                {': '}
                                {<Typography variant="caption">{currentLanguage?.name}</Typography>}
                            </Typography>
                        </div>
                        <div className="language_btn_sec">
                            <div className="language_wrap">
                                {languagesExceptCurrent.map((language, index: number) => (
                                    <Fragment key={index}>
                                        <Button
                                            className={
                                                selectedLanguageCode === language.code
                                                    ? 'language_box active'
                                                    : 'language_box'
                                            }
                                            onClick={() => chooseLanguage(language.code)}
                                        >
                                            <div className="language_name">
                                                <img
                                                    src={
                                                        InterfaceLanguages.find(
                                                            (languageValue) => languageValue.code === language.code,
                                                        )?.country_flag
                                                    }
                                                    alt="language"
                                                />
                                                <Typography variant="h4">{language.name}</Typography>
                                            </div>
                                        </Button>
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                        <div className="sh_btn connect_btn">
                            <Button variant="contained" onClick={() => updateCurrentLanguage()}>
                                {t(`lang_common.btn_update`)}
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default ChangeLanguageModal;
