import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers/root.reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const loggerMiddleware = createLogger();
let middleware: any = [];
if (process.env.REACT_APP_ENV === 'production') {
    middleware = [...middleware, thunkMiddleware];
} else {
    middleware = [...middleware, thunkMiddleware, loggerMiddleware];
}

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));
export type AppDispatch = typeof store.dispatch;
