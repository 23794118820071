import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { v4 } from 'uuid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Divider, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import Constants from '../../../../../../constants';
import LoaddingButton from '../../../../../Common/LoadingButton';
import { dataURItoBlob } from '../../../../../../helpers/utils';
import { activityActions } from '../../../../../../actions/activity.actions';
import ResponseToSubmitDto from '../../../../../../dtos/response-to-submit.dto';
import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import ImageVideoCaption from './ImageVideoCaption';
import rotate_left from '../../../../../../assets/svg/r_left.svg';
import rotate_right from '../../../../../../assets/svg/r_right.svg';
import { ModalType } from './ImageUploadInput';
import httpServices from '../../../../../../services/httpService';
import { participantActions } from '../../../../../../actions/participant.actions';
import ImageSearch from './ImageSearch';

import 'cropperjs/dist/cropper.css';
import './ImageUpload.scss';

interface ImageUploadModalInterface {
    isModalOpen: boolean;
    onModalClose: any;
    modalType: ModalType;
    setModalType: (arg: ModalType) => void;
    imageData: any;
    setImageData: (arg: any) => void;
    imgData: any;
    setImgData: (arg: any) => void;
    inputfileref: any;
    imageExtension: string;
    setIsShowAddNewButton: (arg: boolean) => void;
}

function ImageUploadModal({
    isModalOpen,
    onModalClose,
    modalType,
    setModalType,
    imageData,
    setImageData,
    imgData,
    setImgData,
    inputfileref,
    imageExtension,
    setIsShowAddNewButton,
}: ImageUploadModalInterface) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const participant = useSelector((state: StoreInterface) => state.participant);
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const [caption, setCaption] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const imageRef = useRef<ReactCropperElement>(null);
    const [isSearching, setIsSearching] = useState(false);
    const [isCropInitialise, setIsCropInitialise] = useState(false);
    const [selectedOnlineImageUrl, setSelectedOnlineImageUrl] = useState('');
    const responseId = 'resp-' + v4();
    const [canAllowCrop, setCanAllowCrop] = useState(true);

    const isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
        },
        any: function () {
            return (
                isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()
            );
        },
    };

    useEffect(() => {
        setCaption('');
    }, [isModalOpen]);
    useEffect(() => {
        setIsCropInitialise(!isCropInitialise);
        if (isMobile.any()) setCanAllowCrop(false);
        else setCanAllowCrop(true);
    }, []);

    useEffect(() => {
        const canvas = imageRef.current;
        if (canvas)
            (canvas as any).cropper.setCropBoxData({
                left: (canvas as any).cropper.imageData.left,
                top: (canvas as any).cropper.imageData.top,
                width: (canvas as any).cropper.imageData.width,
                height: (canvas as any).cropper.imageData.height,
            });
    }, [isCropInitialise]);

    const cancelImage = () => {
        inputfileref.current.value = '';
        setIsSubmitting(false);
        onModalClose();
    };

    const handleSubmitImageUpload = async () => {
        if (modalType === ModalType.List && !selectedOnlineImageUrl) return;

        if (activityProps.isCaptionRequired && !caption.trim())
            return setErrorMessage(t('lang_activity.err_caption_required'));

        setIsSubmitting(true);
        let imageUrl = '';
        if (modalType === ModalType.List) {
            // imageUrl = await httpServices.uploadUrlToStorage(selectedOnlineImageUrl);
            imageUrl = selectedOnlineImageUrl;
        } else if (modalType === ModalType.Preview) {
            let blob = dataURItoBlob(imageData);
            imageUrl = await httpServices.uploadToStorage(
                blob,
                `${responseId}.${imageExtension}`,
                participant.cpcsRegion,
                'user',
            );
        } else {
            let blob = dataURItoBlob(imgData.getCroppedCanvas().toDataURL());
            imageUrl = await httpServices.uploadToStorage(
                blob,
                `${responseId}.${imageExtension}`,
                participant.cpcsRegion,
                'user',
            );
        }
        if (!imageUrl) {
            setIsSubmitting(false);
            dispatch(participantActions.showApiError());
            return;
        }

        const responseData = [imageUrl, caption];
        const responseToSubmit: ResponseToSubmitDto = {
            activityId: activityProps.activityId,
            activityType: activityProps.activityType,
            responseId,
            responseData: JSON.stringify(responseData),
        };
        dispatch(activityActions.submitResponse(responseToSubmit));
        setIsSubmitting(false);
        setIsShowAddNewButton(true);
        onModalClose();
    };

    const anticlockwiseRotate = () => {
        const canvas = imageRef.current;
        (canvas as any).cropper.rotate(-90);
        (canvas as any).cropper.clear();
        rotateImage(canvas);
    };

    const clockwiseRotate = () => {
        const canvas = imageRef.current;
        (canvas as any).cropper.rotate(90);
        (canvas as any).cropper.clear();
        rotateImage(canvas);
    };

    const rotateImage = (canvas: any) => {
        const canvasElement = (canvas as any).cropper;
        const contData = canvasElement.containerData;
        const canvData = canvasElement.canvasData;
        const newWidth = canvData.width * (contData.height / canvData.height);
        let newCanvData: any;
        if (newWidth >= contData.width) {
            const newHeight = canvData.height * (contData.width / canvData.width);
            newCanvData = {
                height: newHeight,
                width: contData.width,
                top: (contData.height - newHeight) / 2,
                left: 0,
            };
        } else {
            newCanvData = {
                height: contData.height,
                width: newWidth,
                top: 0,
                left: (contData.width - newWidth) / 2,
            };
        }
        (canvas as any).cropper.setCanvasData(newCanvData);
        (canvas as any).cropper.crop();
        (canvas as any).cropper.setCropBoxData(newCanvData);
    };

    return (
        <Modal
            open={isModalOpen}
            // onClose={() => cancelImage()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="img_Upload_modal"
        >
            <Box className="short_modalinner">
                <div className="close_modal" onClick={() => cancelImage()}>
                    <CloseRoundedIcon />
                </div>
                <div className="short_ans_head">
                    <img src={Constants.IMAGE_URLS.imageModal} alt="msgicon" />
                    <Typography variant="h4">{t(`lang_activity_names.Image Upload`)}</Typography>
                </div>
                <Divider />
                <div className="img_upload_warp">
                    {modalType === ModalType.Cropper && (
                        <div className={`images_croper ${!canAllowCrop && 'cropNotAllow'}`}>
                            <div className="images_croper_box">
                                <Cropper
                                    className="cropper"
                                    ref={imageRef}
                                    // initialAspectRatio={1}
                                    // aspectRatio={4 / 4}
                                    preview=".img-preview"
                                    src={imageData}
                                    //viewMode={1}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={true}
                                    onInitialized={(instance) => setImgData(instance)}
                                    guides={false}
                                    zoomOnTouch={false}
                                    // scalable={true}
                                    movable={false}
                                    zoomable={false}
                                    //restore={true}
                                    rotatable={true}
                                    cropBoxMovable={canAllowCrop}
                                    cropBoxResizable={canAllowCrop}
                                    dragMode="move"
                                    viewMode={1}
                                    //built={()=>{}}
                                />
                            </div>
                        </div>
                    )}
                    {(modalType === ModalType.Search || modalType === ModalType.List) && (
                        <ImageSearch
                            setSelectedOnlineImageUrl={setSelectedOnlineImageUrl}
                            setModalType={setModalType}
                            isSearching={isSearching}
                            setIsSearching={setIsSearching}
                        />
                    )}
                    {modalType === ModalType.Preview && (
                        <div className="images_gif_box">
                            <div className="images_prev">
                                <img src={imageData} alt="sourceimg" />
                            </div>
                        </div>
                    )}
                    {modalType !== ModalType.Search && !isSearching && (
                        <div className="cropping_panel">
                            {modalType === ModalType.Cropper && (
                                <div className="croping_icon">
                                    <Button>
                                        <img src={rotate_right} alt={'cropRight'} onClick={() => clockwiseRotate()} />
                                    </Button>
                                    <Button>
                                        <img src={rotate_left} alt={'cropLeft'} onClick={() => anticlockwiseRotate()} />
                                    </Button>
                                </div>
                            )}
                            <ImageVideoCaption
                                isCaptionRequired={activityProps.isCaptionRequired}
                                caption={caption}
                                setCaption={setCaption}
                                onHitEnter={() => handleSubmitImageUpload()}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                            />
                        </div>
                    )}
                </div>
                {modalType !== ModalType.Search && !isSearching && (
                    <div className="short_ans_form">
                        <div className="sh_btn images_crop">
                            <Button variant="contained" className="cancel" onClick={() => cancelImage()}>
                                {t(`lang_activity.btn_discard`)}
                            </Button>
                            {!isSubmitting ? (
                                <Button variant="contained" onClick={() => handleSubmitImageUpload()}>
                                    {t(`lang_activity.btn_submit`)}
                                </Button>
                            ) : (
                                <LoaddingButton />
                            )}
                        </div>
                    </div>
                )}
            </Box>
        </Modal>
    );
}

export default ImageUploadModal;
