import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import { Fragment } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Constants from '../../../../../../constants';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import LoaddingButton from '../../../../../Common/LoadingButton';

import { ActivityTypes } from '../../../../../../constants/activity-types.enum';
import ImageVideoCaption from '../ImageUpload/ImageVideoCaption';

const VideoPreviewModal = ({
    open,
    onClose,
    onDiscard,
    videoUrl,
    videoUploadSubmit,
    isSubmitting,
    isCaptionRequired,
    caption,
    setCaption,
    errorMessage,
    setErrorMessage,
}: any) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Modal
                open={open}
                // onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="video_Upload_modal"
            >
                <Box className="short_modalinner">
                    <div className="close_modal" onClick={onClose}>
                        <CloseRoundedIcon />
                    </div>
                    <div className="short_ans_head">
                        <img src={Constants.IMAGE_URLS.videoUpload} alt="msgicon" />
                        <Typography variant="h4">{ActivityTypes.VIDEO_UPLOAD}</Typography>
                    </div>
                    <Divider />
                    <div className="video_upload_warp">
                        <div className="video_container">
                            <div className="video_box">
                                {videoUrl ? (
                                    <ReactPlayer
                                        className="video_player"
                                        width="100%"
                                        height="350px"
                                        controls={true}
                                        url={videoUrl}
                                        controlsList="nodownload"
                                    />
                                ) : null}
                            </div>
                        </div>
                        <ImageVideoCaption
                            isCaptionRequired={isCaptionRequired}
                            caption={caption}
                            setCaption={setCaption}
                            onHitEnter={() => videoUploadSubmit()}
                            errorMessage={errorMessage}
                            setErrorMessage={setErrorMessage}
                        />
                        <div className="sh_btn images_crop">
                            <Button variant="contained" className="cancel" onClick={onDiscard}>
                                {t(`lang_activity.btn_discard`)}
                            </Button>
                            {!isSubmitting ? (
                                <Button
                                    variant="contained"
                                    onClick={(e) => {
                                        videoUploadSubmit(e);
                                    }}
                                >
                                    {t(`lang_activity.btn_submit`)}
                                </Button>
                            ) : (
                                <LoaddingButton />
                            )}
                        </div>
                    </div>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default VideoPreviewModal;
