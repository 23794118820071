import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import Constants from '../../../constants';
import Lottie from 'react-lottie-player';
import grayLoader from '../../../../src/assets/animation/grayLoader.json';
import React from 'react';

const ReDirecting = () => {
    const { t } = useTranslation();
    const logo = Constants.IMAGE_URLS.brand_logo;

    return (
        <React.Fragment>
            <div className="join_card_wrap join_verifi">
                <div className="sign_logo">
                    <img src={logo} alt="brand" height={60} />
                    <h2>{t(`lang_common.app_title`)}</h2>
                </div>

                <div className="reDirecting">
                    <Typography variant="body1">
                        Class code found in older version of ClassPoint. Redirecting...
                    </Typography>
                    <Lottie animationData={grayLoader} loop={true} play />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReDirecting;
