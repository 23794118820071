export const PresenterReplyMessages = {
    ar: {
        singleResponseSubmitted: ['رائع! شكرا لك على تقديم اجابتك!', 'أحسنت صنعًا، لقد أرسلت للتو إجابتك!'],
        submittedAddMore: ['رائع! هل تريد إضافة شيء آخر؟', 'عمل جيد! هل تريد إضافة شيء آخر؟'],
        closedMultipleSubmitted: ['!شكرا لك على تقديم اجاباتك'],
        closedNotSubmitted: [
            'لقد فات الأوان ... تم إغلاق التقديم للتو.',
            '.عفوًا! تم إغلاق التقديم، دعنا نحاول مرة أخرى في وقت لاحق',
        ],
        responseDeleted: ['عذراً! تم حذف ردك، لنجرب مجددا', 'عذراً! تمت إزالة إجابتك، هل تحاول مرة أخرى؟'],
    },
    de: {
        singleResponseSubmitted: [
            'Fantastisch! Danke, dass Sie uns Ihre Antwort geschickt haben!',
            'Gute Arbeit, Sie haben gerade Ihre Antwort eingereicht!',
        ],
        submittedAddMore: [
            'Fantastisch! Haben Sie noch etwas hinzuzufügen?',
            'Gute Arbeit! Haben Sie noch etwas hinzuzufügen?',
        ],
        closedMultipleSubmitted: ['Ich danke Ihnen für Ihre Antworten!!'],
        closedNotSubmitted: [
            'Es ist zu spät... die Einreichung wurde geschlossen.',
            'Ups, die Anmeldung ist geschlossen. Versuchen wir es später noch einmal.',
        ],
        responseDeleted: [
            'Ups! Ihre Antwort wurde gelöscht. Versuchen wir es noch einmal!',
            'Oje! Ihre Antwort wurde entfernt. Sollen wir es noch einmal versuchen?',
        ],
    },
    en: {
        singleResponseSubmitted: [
            'Awesome! Thank you for submitting your response!',
            "Nice job, you've just submitted your response!",
        ],
        submittedAddMore: ['Awesome! Anything else to add?', 'Nice job! Anything else to add?'],
        closedMultipleSubmitted: ['Thank you for your responses!'],
        closedNotSubmitted: [
            "It's too late... submission has been closed.",
            "Oops, submission is closed. Let's try again later.",
        ],
        responseDeleted: [
            'Oops! Your response was deleted. Let’s try again!',
            'Uh oh! Your response was removed. Shall we try again?',
        ],
    },
    es: {
        singleResponseSubmitted: [
            '¡Genial! ¡Gracias por enviar tu respuesta!',
            '¡Buen trabajo! ¡Acabas de enviar tu respuesta!',
        ],
        submittedAddMore: ['¡Genial! ¿Algo más que añadir? ', '¡Buen trabajo! ¿Algo más que añadir?'],
        closedMultipleSubmitted: ['¡Gracias por tus respuestas!'],
        closedNotSubmitted: [
            'Es demasiado tarde... ya no se pueden enviar respuestas.',
            '¡Vaya! Ya no se pueden enviar respuestas. Inténtalo más tarde.',
        ],
        responseDeleted: [
            '¡Vaya! Tu respuesta ha sido eliminada. Inténtalo de nuevo',
            '¡Vaya! Tu respuesta ha sido eliminada. ¿Lo intentamos de nuevo?',
        ],
    },
    fil: {
        singleResponseSubmitted: [
            'Magaling! Salamat sa pag-sumite ng tugon!',
            'Yehey, ikaw ay nakapag-sumite na ng tugon!',
        ],
        submittedAddMore: ['Kahanga-hanga! May gusto ka pa bang idagdag?', 'Mahusay! May gusto ka pa bang idagdag?'],
        closedMultipleSubmitted: ['Salamat sa iyong mga tugon!'],
        closedNotSubmitted: [
            'Huli na... ang pagsu-sumite ay nagsara na.',
            'Naku, hindi na maaaring mag-sumite. Bumawi nalang tayo mamaya!',
        ],
        responseDeleted: [
            'Hala! Ang iyong tugon ay nabura. Subukan nating muli!',
            'Anong nangyari? Ang tugon mo ay naalis. Gusto mo bang subukang muli?',
        ],
    },
    fr: {
        singleResponseSubmitted: [
            "Impressionnant! Merci d'avoir soumis votre réponse !",
            'Bravo, vous venez de soumettre votre réponse !',
        ],
        submittedAddMore: ['Impressionnant! Autre chose à ajouter ?', 'Excellent travail! Autre chose à ajouter ?'],
        closedMultipleSubmitted: ['Merci pour vos réponses!'],
        closedNotSubmitted: [
            "Il est trop tard... la soumission vient d'être fermée.",
            'Oups, la soumission est fermée. Essayons plus tard.',
        ],
        responseDeleted: [
            'Oops! Votre réponse a été supprimée. Essayons une deuxième fois !',
            'Oh ! Votre réponse a été supprimée. on réessaye une autre fois ?',
        ],
    },
    hi: {
        singleResponseSubmitted: [
            'बहुत बढ़िया! अपना जवाब सबमिट करने के लिए धन्यवाद!',
            'बहुत बढ़िया, आपने अभी-अभी अपना जवाब सबमिट किया है!',
        ],
        submittedAddMore: ['बहुत बढ़िया! कुछ और जोड़ना है?', 'बढ़िया काम! कुछ और जोड़ना है?'],
        closedMultipleSubmitted: ['आपकी प्रतिक्रियाओं के लिए धन्यवाद!'],
        closedNotSubmitted: [
            'बहुत देर हो चुकी है... सबमिशन बंद कर दिया गया है।',
            'उफ़, सबमिशन बंद हो गया है। चलिए बाद में फिर से कोशिश करते हैं।',
        ],
        responseDeleted: [
            'उफ़! आपकी प्रतिक्रिया हटा दी गई थी। चलो फिर से कोशिश करें!',
            'उह ओह! आपकी प्रतिक्रिया हटा दी गई थी। क्या हम फिर से कोशिश करें?',
        ],
    },
    id: {
        singleResponseSubmitted: [
            'Keren 🤩 Terima kasih sudah mengirimkan jawaban',
            'Luar biasa! Terima kasih telah mengirimkan respons Anda 🤗 ',
            'Bagus sekali! Respon Anda telah terkirim!',
        ],
        submittedAddMore: [
            'Keren! Mau tambah jawaban lagi? 🚀',
            'Bagus! Ada lagi yang mau ditambahkan? 😉',
            'Bagus sekali! Ada lagi yang mau ditambahkan? 😁',
        ],
        closedMultipleSubmitted: [
            'Terima kasih telah mengirimkan respons ya 😍',
            'Respons sudah terkirim, terima kasih ya 🌞',
        ],
        closedNotSubmitted: [
            'Yaah.... jawaban sudah ditutup 🤧 .',
            'Yah, waktu habis 😫 .... jawaban telah ditutup.',
            'Waduh, jawaban telah ditutup. Coba lagi nanti ya 😉',
        ],
        responseDeleted: [
            'Yaah 😣 respons Anda dihapus. Yuk coba lagi',
            'Oh tidak 😱 respons Anda dihapus. Coba kirimkan lagi ya 🤩',
        ],
    },
    ja: {
        singleResponseSubmitted: [
            '素晴らしいです！回答の送信ありがとうございます！',
            'よくできました。回答が送信されました！',
        ],
        submittedAddMore: [
            '素晴らしいです！他に追加することはありますか？',
            'よくできました。他に追加することはありますか？',
        ],
        closedMultipleSubmitted: ['ご回答ありがとうございます！'],
        closedNotSubmitted: [
            '間に合いませんでした。提出は締め切られました。',
            '提出は締め切られています。後でもう一度試してみましょう。',
        ],
        responseDeleted: [
            'あなたの回答は削除されました。もう一度やってみましょう！',
            'あなたの回答は削除されました。もう一度やり直しましょうか？',
        ],
    },
    ms: {
        singleResponseSubmitted: ['Terima kasih atas penghantaran anda!', 'Syabas, respon anda sudah dihantarkan!'],
        submittedAddMore: ['Bagus! Ada apa lagi nak tambah?'],
        closedMultipleSubmitted: ['Terima kasih atas penghantaran anda!'],
        closedNotSubmitted: [
            'Dah terlambat... penghantaran sudah ditutup.',
            'Oops, penghantaran sudah ditutup. Cuba lagi nanti.',
        ],
        responseDeleted: [
            'Oops! Respon anda sudah dipadamkan. Cuba lagi!',
            'Uh oh! Respon anda sudah dipadamkan. Boleh tak kita cuba lagi?',
        ],
    },
    pt: {
        singleResponseSubmitted: [
            'Ótimo! Obrigado por enviar sua resposta!',
            'Muito bem, você acaba de enviar sua resposta!',
        ],
        submittedAddMore: [
            'Ótimo! Gostaria de acrescentar alguma coisa?',
            'Muito bem! Gostaria de acrescentar alguma coisa?',
        ],
        closedMultipleSubmitted: ['Obrigado por suas respostas!'],
        closedNotSubmitted: [
            'Tarde demais... não é possível enviar mais respostas.',
            'Não é possível enviar mais respostas. Vamos tentar novamente mais tarde.',
        ],
        responseDeleted: [
            'Sua resposta foi excluída. Vamos tentar novamente!',
            'Sua resposta foi removida. Vamos tentar novamente?',
        ],
    },
    ru: {
        singleResponseSubmitted: ['Здорово! Спасибо за ответ!', 'Отличная работа, вы только что отправили свой ответ!'],
        submittedAddMore: ['Потрясающе! Добавите еще что-нибудь?', 'Хорошая работа! Добавите еще что-нибудь?'],
        closedMultipleSubmitted: ['Спасибо за ваши ответы!'],
        closedNotSubmitted: [
            'Слишком поздно... срок приема ответов истек.',
            'К сожалению, прием ответов завершен. Давайте попробуем позже»',
        ],
        responseDeleted: [
            'Упс! Ваш ответ был удален. Давайте попробуем снова!',
            'Ой! Ваш ответ был удален. Попробуем еще раз?',
        ],
    },
    th: {
        singleResponseSubmitted: [
            'เยี่ยมไปเลย! ขอบคุณที่ส่งคำตอบเข้ามา 😀',
            'เยี่ยมมาก! คำตอบของคุณได้ถูกส่งเรียบร้อยแล้ว',
        ],
        submittedAddMore: ['เยี่ยมมาก! อยากเพิ่มเติมอะไรอีกไหม？', 'ยอดเยี่ยม! อยากเพิ่มเติมอะไรอีกไหม？'],
        closedMultipleSubmitted: ['ขอบคุณสำหรับคำตอบ! 😀'],
        closedNotSubmitted: ['ช้าไปแล้ว...การส่งคำตอบได้ถูกปิดแล้ว', 'อ๊ะ...ปิดรับคำตอบแล้ว มาลองใหม่ในครั้งหน้า'],
        responseDeleted: [
            'อ๊ะ! คำตอบของคุณเพิ่งจะถูกลบออก ลองใหม่อีกครั้ง',
            'ไม่นะ！คำตอบของคุณเพิ่งจะถูกลบออก ลองใหม่อีกครั้งดีไหม？ ',
        ],
    },
    tw: {
        singleResponseSubmitted: ['很棒！謝謝您的回答！', '做得好，您已提交您的答案！'],
        submittedAddMore: ['真棒！還有要補充的嗎？', '做得很好！還有要補充的嗎？'],
        closedMultipleSubmitted: ['感謝您的回答！'],
        closedNotSubmitted: ['您太晚了...提交時間已經結束了。', '糟了，作答已結束。下次再加油！'],
        responseDeleted: ['糟了！您的回答被刪除了。再試一次吧！', '哎呀！您的回答被刪除了。再來試一次吧！'],
    },
    vi: {
        singleResponseSubmitted: ['Tuyệt! Cảm ơn vì câu trả lời của bạn!', 'Làm tốt lắm, bạn vừa nộp câu trả lời rồi!'],
        submittedAddMore: [
            'Tuyệt vời! Bạn muốn bổ sung câu trả lời không?',
            'Làm tốt lắm! Bạn muốn bổ sung câu trả lời không?',
        ],
        closedMultipleSubmitted: ['Cảm ơn vì câu trả lời của bạn!'],
        closedNotSubmitted: [
            'Ôi không muộn mất rồi... hoạt động này vừa được kết thúc.',
            'Aww, hoạt động này kết thúc mất rồi. Thử lại ở các hoạt động sau nhé.',
        ],
        responseDeleted: [
            'Ơ! Câu trả lời của bạn đã bị xoá. Thử nộp một câu trả lời khác nhé!',
            'Ối! Câu trả lời của bạn đã bị gỡ. Bạn muốn thử một câu trả lời khác không?',
        ],
    },
    zh: {
        singleResponseSubmitted: ['赞！感谢作答！', '真棒！'],
        submittedAddMore: ['赞！还有要补充的吗？', '漂亮！还有要补充的吗？'],
        closedMultipleSubmitted: ['感谢参与！', '作答结束啦。'],
        closedNotSubmitted: [
            '抱歉，有点晚了······ 作答已经结束了。',
            '抱歉，作答结束了。下次加油哦。',
            '差一点就赶上了，下次加油！',
        ],
        responseDeleted: ['老师删除了你的作答，再试一次！', '加油，再试一次！'],
    },
};
