import React from 'react';
import { ActivityTypes } from '../../../../../../constants/activity-types.enum';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { ActivityPropsInterface, SubmittedResponseInterface } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';

function VideoUploadResponse() {
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const iosDevice = () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    };
    return (
        <React.Fragment>
            <div className="chat_text_group">
                {activityProps.activityType === ActivityTypes.VIDEO_UPLOAD &&
                    activity.submittedResponses.map((response: SubmittedResponseInterface, index: number) => (
                        <div className="chat_text update_images video_uploadarea" key={index}>
                            <div className={iosDevice() ? 'video_response_box fixed_height_ios' : 'video_response_box'}>
                                <ReactPlayer
                                    className="video_player"
                                    width="100%"
                                    height="100%"
                                    // controls={true}
                                    url={JSON.parse(response.responseData)[0]}
                                    onClick={() => setIsModalOpen(true)}
                                />
                                <div className="video_icon" onClick={() => setIsModalOpen(true)}>
                                    <PlayCircleOutlineIcon />
                                </div>
                            </div>
                            {JSON.parse(response.responseData)[1] && (
                                <Typography variant="h5" className="mali notranslate">
                                    {JSON.parse(response.responseData)[1]}
                                </Typography>
                            )}
                        </div>
                    ))}
            </div>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="lightbox_modal"
            >
                <Box className="modal_main_container video_lightbox">
                    <div className="image_light_box">
                        <div className="big__content video_background">
                            <ReactPlayer
                                className="video_player"
                                controls={true}
                                url={JSON.parse(activity.submittedResponses[0].responseData)[0]}
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
}

export default VideoUploadResponse;
