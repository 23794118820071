export const getDrawCursor = (eraserBrushSize: number) => {
    const circle = `
          <svg
              height="${eraserBrushSize}"
              width="${eraserBrushSize}"

              xmlns="http://www.w3.org/2000/svg"
          >
              <circle 
                cx="50%" 
                cy="50%"  
                r="49%" 
                stroke="#808080" 
                strokeWidth="1" 
                stroke-linecap="round" 
                stroke-dasharray="1,2" 
                fill="#ffffff00" 
                />
          </svg>
      `;

    return `data:image/svg+xml;base64,${window.btoa(circle)}`;
};

export const getRotationDrawCursor = () => {
    const rotationIcon = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEyIDZ2M2w0LTQtNC00djNjLTQuNDIgMC04IDMuNTgtOCA4IDAgMS41Ny40NiAzLjAzIDEuMjQgNC4yNkw2LjcgMTQuOGMtLjQ1LS44My0uNy0xLjc5LS43LTIuOCAwLTMuMzEgMi42OS02IDYtNnptNi43NiAxLjc0TDE3LjMgOS4yYy40NC44NC43IDEuNzkuNyAyLjggMCAzLjMxLTIuNjkgNi02IDZ2LTNsLTQgNCA0IDR2LTNjNC40MiAwIDgtMy41OCA4LTggMC0xLjU3LS40Ni0zLjAzLTEuMjQtNC4yNnoiLz48L3N2Zz4=`;

    return rotationIcon;
};

export const removeCanvasListener = (canvas: any) => {
    canvas.off('mouse:down');
    canvas.off('mouse:move');
    canvas.off('mouse:up');
};

export const setObjectSelection = (canvas: any, selectable: boolean = false) => {
    canvas._objects.forEach((obj: any) => (obj.selectable = selectable));
};

export const setCanvasStateOnCreate = (canvas: any) => {
    canvas.selection = true;
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    // canvas.discardActiveObject().requestRenderAll();
};

export const setCanvasMaxWidthAndHeight = (canvas: any) => {
    const slideBox: any = document.getElementsByClassName('slide_upload_warp');
    if (slideBox[0]) {
        const height = slideBox[0].offsetHeight;
        const width = slideBox[0].offsetWidth;
        canvas.setDimensions({
            width: width,
            height: height,
        });
    }
};
