import { Box, Button, Modal, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import * as filestack from 'filestack-js';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import LoaderAnimation from '../../../../../../assets/animation/87884-loader.json';
import { sentryLogApiError } from '../../../../../../services/sentryService';

function LoadingVideoModal({
    onClose,
    errorMessage,
    fileData,
    setErrorMessage,
    setVideoUrl,
    setIsLoadingVideoModalOpen,
    setIsPreviewModalOpen,
    inputfileref,
    inputfilerefMob,
}: any) {
    const { t } = useTranslation();

    const client = filestack.init('AUrBr0SYrTo2kuT7HQXaRz');
    const [uploadPercentage, setUploadPercentage] = useState(0);

    useEffect(() => {
        if (!errorMessage) {
            startUpload();
        }
    }, [fileData]);

    const onProgress: any = (obj: any) => {
        setUploadPercentage(obj.totalPercent > 0 ? obj.totalPercent - 1 : 0);
    };

    const startUpload = () => {
        let btn: any = document.getElementById('cancelBtn');
        const token: any = {};
        btn.addEventListener('click', () => {
            token.cancel();
            if (inputfileref.current) inputfileref.current.value = null;
            if (inputfilerefMob.current) inputfilerefMob.current.value = null;
            onClose();
            return;
        });

        client
            .upload(fileData, { onProgress }, { location: 'azure', path: `/${process.env.REACT_APP_ENV}/` }, token)
            .then(
                function (result) {
                    setErrorMessage('');
                    setVideoUrl(result.url);
                    setIsLoadingVideoModalOpen(false);
                    setIsPreviewModalOpen(true);
                },
                function (error) {
                    setErrorMessage(t('lang_activity.err_video_upload_failed'));
                    if (inputfileref.current) inputfileref.current.value = null;
                    if (inputfilerefMob.current) inputfilerefMob.current.value = null;
                    if (error.response) {
                        sentryLogApiError(error, 'uploadVideoThroughFileStack', {
                            body: { error: error.response },
                        });
                    }
                },
            )
            .catch((error) => {
                setErrorMessage(t('lang_activity.err_video_upload_failed'));
                if (inputfileref.current) inputfileref.current.value = null;
                if (inputfilerefMob.current) inputfilerefMob.current.value = null;
                sentryLogApiError(error, 'uploadVideoThroughFileStack', {
                    body: { error },
                });
            });
    };

    const progressBarValue: any = {
        '--percent': uploadPercentage,
        '--color': uploadPercentage > 0 ? '#6378FF' : '',
        '--light_color': '#E6E1E5',
    };

    return (
        <Fragment>
            {errorMessage ? (
                <div className="video_pre_upload_modal">
                    <Typography variant="h4" className="notranslate">
                        {errorMessage}
                    </Typography>
                    <div className="foot_btn">
                        <Button variant="contained" onClick={onClose}>
                            {t(`lang_common.btn_ok`)}
                        </Button>
                    </div>
                </div>
            ) : (
                <Fragment>
                    {!fileData ? (
                        <Modal
                            open={true}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className="correctAnsModal pre_loader">
                                <div className="preparing_video">
                                    <Lottie loop={false} animationData={LoaderAnimation} play speed={1} />
                                    <Typography variant="h4">{t(`lang_activity.txt_preparing_video`)} </Typography>
                                </div>
                            </Box>
                        </Modal>
                    ) : (
                        <Fragment>
                            <div className="video_pre_upload_modal">
                                <div className="video_loading">
                                    <div className="percent">
                                        <svg>
                                            <circle cx="70" cy="70" r="64" style={progressBarValue}></circle>
                                            <circle cx="70" cy="70" r="64" style={progressBarValue}></circle>
                                        </svg>
                                        <div className="number">
                                            <Typography variant="h4">
                                                {uploadPercentage}%{' '}
                                                <Typography variant="caption">
                                                    {t('lang_activity.txt_video_size', {
                                                        videoSize: Math.ceil(fileData.size / 1024 / 1024),
                                                    })}
                                                </Typography>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="sh_btn">
                                    <Button variant="contained" className="cancel" id="cancelBtn">
                                        {t(`lang_activity.btn_discard`)}
                                    </Button>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

export default LoadingVideoModal;
