import { Fragment } from 'react';

export const TextIcon = () => {
    return (
        <Fragment>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.5013 15.1633V6.83683C20.9428 6.4039 21.9995 5.08294 22 3.49993C21.9993 1.56668 20.4333 0.000698416 18.5001 0C16.9151 0.000511546 15.5923 1.05989 15.1615 2.5043H6.83853C6.40748 1.05989 5.08499 0.000523812 3.49993 0C1.56668 0.000682046 0.000698416 1.56668 0 3.49993C0.000682046 5.08364 1.05819 6.40543 2.50124 6.83753V15.1622C1.05819 15.5945 0.000698416 16.9161 0 18.5C0.000682046 20.4332 1.56668 21.9992 3.49993 21.9999C5.08176 21.9994 6.40172 20.9441 6.83565 19.5043H15.1638C15.5979 20.9441 16.9184 21.9994 18.5001 21.9999C20.4333 21.9992 21.9993 20.4332 22 18.5C21.9995 16.9169 20.9428 15.596 19.5013 15.1631V15.1633ZM15.1616 17.5046H6.83827C6.50237 16.3794 5.62508 15.5009 4.5009 15.1633V6.83683C5.6222 6.50025 6.49775 5.62517 6.83552 4.50439H15.1637C15.5015 5.62582 16.3784 6.50124 17.5009 6.83766V15.1623C16.3755 15.4993 15.4972 16.3784 15.1613 17.5043L15.1616 17.5046ZM18.5001 2.00017C19.3281 2.00171 19.9985 2.67218 20 3.50033C19.9983 4.32612 19.331 4.99419 18.506 4.99895L18.5011 4.99793L18.4949 4.99912C17.6691 4.99469 17.0014 4.32646 16.9997 3.50015C17.0016 2.67213 17.6718 2.00152 18.5 2.00017L18.5001 2.00017ZM1.99956 3.49998C2.00127 2.67196 2.67157 2.00153 3.49972 1.99983C4.32345 2.00136 4.98913 2.6662 4.99747 3.48854L4.99423 3.5044L4.99645 3.51582C4.98587 4.33393 4.32514 4.9938 3.50585 4.9986L3.50091 4.99758L3.49477 4.99877C2.66915 4.99485 2.00125 4.32629 1.99955 3.49997L1.99956 3.49998ZM3.49972 20C2.67153 19.9985 2.00127 19.328 1.99956 18.5001C2.00127 17.6721 2.67157 17.0016 3.49972 16.9999C4.32345 17.0014 4.98913 17.6663 4.99747 18.4886L4.99423 18.5047L4.99645 18.5161C4.98604 19.3364 4.32208 19.9987 3.4997 20L3.49972 20ZM18.4999 20C17.6717 19.9985 17.0015 19.328 16.9998 18.5001C17.0015 17.6721 17.6718 17.0016 18.4999 16.9999C19.3279 17.0014 19.9984 17.6719 19.9999 18.5001C19.9984 19.3281 19.3278 19.9987 18.4999 20Z"
                    fill="#6378FF"
                />
                <path
                    d="M14.3334 7.08252H7.66655C7.1141 7.08252 6.6665 7.53012 6.6665 8.08256C6.6665 8.63484 7.1141 9.08261 7.66655 9.08261H9.99982V14.7494C9.99982 15.3016 10.4474 15.7494 10.9999 15.7494C11.5521 15.7494 11.9999 15.3016 11.9999 14.7494V9.08261H14.3332C14.8855 9.08261 15.3332 8.63484 15.3332 8.08256C15.3334 7.53029 14.8856 7.08269 14.3334 7.08269V7.08252Z"
                    fill="#6378FF"
                />
            </svg>{' '}
        </Fragment>
    );
};

export const Arrow = () => {
    return (
        <Fragment>
            <svg width="14" height="16" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.0039 3.414L2.39691 12.021L0.98291 10.607L9.58891 2H2.00391V0H13.0039V11H11.0039V3.414Z"
                    fill="#49454F"
                />
            </svg>
        </Fragment>
    );
};

export const Eraser = () => {
    return (
        <Fragment>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.58595 6.85797L2.63595 11.808L7.82995 17.002H8.99995V17H10.172L13.95 13.222L7.58595 6.85797ZM8.99995 5.44397L15.364 11.808L18.192 8.97897L11.828 2.61497L8.99995 5.44397ZM13 17H20V19H11L7.00195 19.002L0.514954 12.515C0.327483 12.3274 0.222168 12.0731 0.222168 11.808C0.222168 11.5428 0.327483 11.2885 0.514954 11.101L11.12 0.49397C11.2128 0.400994 11.3231 0.327235 11.4445 0.276911C11.5659 0.226586 11.696 0.200684 11.8275 0.200684C11.9589 0.200684 12.089 0.226586 12.2104 0.276911C12.3318 0.327235 12.4421 0.400994 12.535 0.49397L20.313 8.27197C20.5004 8.4595 20.6057 8.71381 20.6057 8.97897C20.6057 9.24414 20.5004 9.49844 20.313 9.68597L13 17Z"
                    fill="#49454F"
                />
            </svg>
        </Fragment>
    );
};

export const Pen = () => {
    return (
        <Fragment>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2425 0.970142C10.1 0.399983 9.58771 0 9 0C8.41229 0 7.9 0.399982 7.75746 0.970142L7 4H6V9.5L1 17H0V19H18V17H17L12 9.5V4H11L10.2425 0.970142ZM8 10.106L3.404 17H14.596L10 10.106V6H8V10.106Z"
                />
            </svg>
        </Fragment>
    );
};

export const Square = () => {
    return (
        <Fragment>
            <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 1C0 0.734784 0.105357 0.48043 0.292894 0.292894C0.48043 0.105358 0.734784 0 1 0H17C17.2652 0 17.5196 0.105355 17.7071 0.292892C17.8946 0.480428 18 0.734783 18 0.999999V17C18 17.2321 17.9193 17.4558 17.7735 17.6338C17.7527 17.6592 17.7305 17.6837 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.767936 18 0.544188 17.9193 0.366243 17.7735C0.340822 17.7527 0.316336 17.7305 0.292894 17.7071C0.105357 17.5196 0 17.2652 0 17V1ZM2 2L16 2V16H2V2Z"
                    fill="#49454F"
                />
            </svg>
        </Fragment>
    );
};

export const Highlighter = () => {
    return (
        <Fragment>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 0.939051C5 0.328432 5.61139 -0.119611 6.24254 0.0284863L12.2425 1.43637C12.6877 1.54083 13 1.91625 13 2.34694V9H14.7544L16.7544 16H18V18H0V16H1.2458L3.2458 9H5V0.939051ZM3.32583 16H14.6744L13.2458 11H4.7544L3.32583 16Z"
                    fill="#49454F"
                />
            </svg>
        </Fragment>
    );
};

export const ShapeIcon = () => {
    return (
        <Fragment>
            <svg
                width="22"
                height="22"
                className="shape"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.49997 1C4.36322 1 1 4.36328 1 8.50001C1 12.4071 3.99991 15.6262 7.81815 15.9716V20.5455C7.81815 20.7965 8.02171 21 8.27282 21H20.5455C20.7966 21 21 20.7964 21 20.5455V8.27274C21 8.02178 20.7966 7.81824 20.5455 7.81824H15.9716C15.6237 4.00264 12.405 1 8.49997 1ZM8.49997 1.90901C12.1454 1.90901 15.0909 4.85439 15.0909 8.49993C15.0909 12.1454 12.1455 15.0909 8.49997 15.0909C4.85453 15.0909 1.90909 12.1455 1.90909 8.49993C1.90909 4.85447 4.85461 1.90901 8.49997 1.90901ZM15.9926 8.72721H20.0908V20.0909H8.72724V15.9926C12.6837 15.8734 15.8758 12.6841 15.9926 8.72721Z"
                    fill="#49454F"
                    stroke="#49454F"
                />
            </svg>
        </Fragment>
    );
};
export const Circle = () => {
    return (
        <Fragment>
            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.995711"
                    y="0.9962"
                    width="22.0086"
                    height="22.0086"
                    rx="11.0043"
                    stroke="#49454F"
                    strokeWidth="1.6506"
                />
            </svg>
        </Fragment>
    );
};

export const Line = () => {
    return (
        <Fragment>
            <svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.5304 1.53039L1.53039 25.5304L0.469727 24.4697L24.4697 0.469725L25.5304 1.53039Z"
                    fill="#49454F"
                />
            </svg>
        </Fragment>
    );
};
export const Undo = () => {
    return (
        <Fragment>
            <svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.2649 1.5C8.61489 1.5 6.21489 2.49 4.36489 4.1L0.764893 0.5V9.5H9.76489L6.14489 5.88C7.53489 4.72 9.30489 4 11.2649 4C14.8049 4 17.8149 6.31 18.8649 9.5L21.2349 8.72C19.8449 4.53 15.9149 1.5 11.2649 1.5Z"
                    fill="#49454F"
                />
            </svg>
        </Fragment>
    );
};
