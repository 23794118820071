export enum ActivityTypes {
    MULTIPLE_CHOICE = 'Multiple Choice',
    WORD_CLOUD = 'Word Cloud',
    SHORT_ANSWER = 'Short Answer',
    SLIDE_DRAWING = 'Slide Drawing',
    IMAGE_UPLOAD = 'Image Upload',
    AUDIO_RECORD = 'Audio Record',
    VIDEO_UPLOAD = 'Video Upload',
    FILL_IN_THE_BLANKS = 'Fill in the Blanks',
    QUICK_POLL = 'Quick Poll',
}
