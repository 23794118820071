import Typography from '@mui/material/Typography';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';

import styles from './QnaHeader.module.scss';

import { QUESTION } from '../../../../../../../Icon';

interface QnaHeaderInterface {
    qnaModalViewHandler: (view: 'view' | 'ask' | undefined) => void;
    canCloseModal: boolean;
}

export default function QnaHeader({ qnaModalViewHandler, canCloseModal }: QnaHeaderInterface) {
    return (
        <div className={styles.card_head}>
            <div className={`${styles.content}`}>
                <div className={styles.card_head_left}>
                    <Typography className={styles.heading_text} variant="h4">
                        <i className={styles.icon}>
                            <QUESTION />
                        </i>
                        Q & A
                    </Typography>
                </div>
                <IconButton
                    className={styles.close_modal}
                    onClick={() => canCloseModal && qnaModalViewHandler(undefined)}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </div>
        </div>
    );
}
