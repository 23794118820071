import { TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ImageVideoCaption({
    isCaptionRequired,
    caption,
    setCaption,
    onHitEnter,
    errorMessage,
    setErrorMessage,
}: any) {
    const { t } = useTranslation();
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            onHitEnter();
        }
    };

    return (
        <div className="caption_img">
            <TextField
                fullWidth
                placeholder={
                    isCaptionRequired
                        ? t(`lang_activity.txt_placeholder_insert_caption_required`)
                        : t(`lang_activity.txt_placeholder_insert_caption`)
                }
                autoComplete="off"
                id="fullWidth"
                inputProps={{ maxLength: 50 }}
                value={caption}
                onChange={(e) => {
                    setCaption(e.target.value);
                    setErrorMessage('');
                }}
                onKeyDown={(e) => handleKeyDown(e)}
            />
            <div className="error_sec">
                <Typography variant="caption" className="notranslate">
                    {errorMessage}
                </Typography>
            </div>
        </div>
    );
}
