import { memo, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';

import 'react-quill/dist/quill.snow.css';

const Delta = Quill.import('delta');

function ShortAnswerTextBox({ checkValidity }: any) {
    const quillRefs = useRef<any>(null);

    /*
     * Quill modules to attach to editor
     * See https://quilljs.com/docs/modules/ for complete options
     */
    const modules = {
        toolbar: {
            container: '#toolbar',
            // handlers: {
            //     insertStar: insertStar,
            // },
        },
        clipboard: {
            matchers: [
                [
                    Node.ELEMENT_NODE,
                    function (node: any, delta: any) {
                        const ops = delta.ops.map((op: any) => ({
                            insert: op.insert,
                        }));
                        return new Delta(ops);
                    },
                ],
            ],
        },
    };

    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'color',
    ];

    // const CustomButton = () => <span className="octicon octicon-star" />;

    const CustomToolbar = () => (
        <div id="toolbar">
            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
            </span>
            {/* <span className="ql-formats">
                <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" />
            </span> */}
            <span className="ql-formats">
                <button className="ql-color" value="black" />
                <button className="ql-color" value="red" />
                <button className="ql-color" value="blue" />
                {/* <button className="ql-color" value="green" />
                <button className="ql-color" value="purple" /> */}
            </span>
            {/* <span className="ql-formats">
                <select className="ql-color">
                    <option value="black" />
                    <option value="red" />
                    <option value="blue" />
                    <option value="green" />
                    <option value="purple" />
                </select>
            </span> */}
        </div>
    );

    useEffect(() => {
        const colorsElement: any = document.querySelector('.ql-color');
        colorsElement.classList.add('ql-active');
        const textElement: any = document.querySelector('.jodit_editor');
        textElement.addEventListener('keydown', () => {
            setTimeout(() => {
                addDefaultClass();
            }, 150);
        });

        textElement.addEventListener('click', () => {
            addDefaultClass();
        });
        textElement.addEventListener('focusin', () => {
            addDefaultClass();
        });
        textElement.addEventListener('focusout', () => {
            setTimeout(() => {
                addDefaultClass();
            }, 150);
        });
        return () => {
            textElement.removeEventListener();
        };
    }, []);

    const addDefaultClass = () => {
        const colorsElements: any = document.querySelectorAll('.ql-color');
        if (colorsElements.length === 0) return;
        const isActiveExist = Array.from(colorsElements).some((colorElm: any) => {
            return colorElm.classList.contains('ql-active');
        });
        if (!isActiveExist) colorsElements[0].classList.add('ql-active');
    };

    return (
        <div>
            <ReactQuill
                onChange={() => {
                    checkValidity();
                    addDefaultClass();
                }}
                // placeholder={`Please write something`}
                modules={modules}
                formats={formats}
                theme={'snow'} // pass false to use minimal theme
                ref={quillRefs}
                id="textbox"
            />
            <CustomToolbar />
        </div>
    );
}

export default memo(ShortAnswerTextBox);
