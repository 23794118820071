import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie-player';

import { Divider } from '@mui/material';

import { ActivityStatusTypes } from '../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../interfaces/store.interface';

import ActivityTitleHead from './activityTitleHead/ActivityTitleHead';
import PresenterReply from './PresenterMessages/PresenterReply';
import MultipleChoiceResponse from './MultipleChoice/MultipleChoiceResponse';
import MultipleChoiceInput from './MultipleChoice/MultipleChoiceInput';
import { ActivityTypes } from '../../../../../constants/activity-types.enum';
import ShortAnswerResponse from './ShortAnswer/ShortAnswerResponse';
import InstructionMessage from './PresenterMessages/InstructionMessage';
import ShortAnswerInput from './ShortAnswer/ShortAnswerInput';
import VotingModal from '../Voting';
import AudioRecordInput from './Audio/AudioRecordInput';
import AudioRecordResponse from './Audio/AudioRecordResponse';
import SlideDrawingInput from './SlideDrawing/SlideDrawingInput';
import SlideDrawingResponse from './SlideDrawing/SlideDrawingResponse';
import ImageUploadInput from './ImageUpload/ImageUploadInput';
import ImageUploadResponse from './ImageUpload/ImageUploadResponse';
import VideoUploadResponse from './VideoUpload/VideoUploadResponse';
import VideoUploadInput from './VideoUpload/VideoUploadInput';
import FillBlanksResponse from './FillBlanks/FillBlanksResponse';
import FillBlanksInput from './FillBlanks/FillBlanksInput';
import WordCloudResponse from './WordCloud/WordCloudResponse';
import WordCloudInput from './WordCloud/WordCloudInput';
import ContinueVotingButton from '../../../../Common/ContinueVotingButton';
import QuickPollResponse from './QuickPoll/QuickPollResponse';
import QuickPollInput from './QuickPoll/QuickPollInput';
import CustomAvatar, { AvatarType } from '../../../../Common/CustomAvatar';

import TooLate from '../../../../../assets/animation/too_late.json';

const ActivitySidePanel = () => {
    const activity = useSelector((state: StoreInterface) => state.activity);
    const participant = useSelector((state: StoreInterface) => state.participant);
    const classGroups = useSelector((state: StoreInterface) => state.currentClass.groups);

    const activityProps = activity.activityProps;
    const submittedResponses = activity.submittedResponses;

    const [timerTime, setTimerTime] = useState<any>(0);
    const [isVotingOpen, setIsVotingOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        setIsDarkMode(getTheme());
    }, []);
    const getTheme = () => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches) {
            return true;
        } else {
            return false;
        }
    };

    let responseSection = null;
    switch (activityProps?.activityType) {
        case ActivityTypes.MULTIPLE_CHOICE:
            responseSection = <MultipleChoiceResponse />;
            break;
        case ActivityTypes.WORD_CLOUD:
            responseSection = <WordCloudResponse />;
            break;
        case ActivityTypes.SHORT_ANSWER:
            responseSection = <ShortAnswerResponse />;
            break;
        case ActivityTypes.SLIDE_DRAWING:
            responseSection = <SlideDrawingResponse />;
            break;
        case ActivityTypes.IMAGE_UPLOAD:
            responseSection = <ImageUploadResponse />;
            break;
        case ActivityTypes.FILL_IN_THE_BLANKS:
            responseSection = <FillBlanksResponse />;
            break;
        case ActivityTypes.AUDIO_RECORD:
            responseSection = <AudioRecordResponse isDarkMode={isDarkMode} />;
            break;
        case ActivityTypes.VIDEO_UPLOAD:
            responseSection = <VideoUploadResponse />;
            break;
        case ActivityTypes.QUICK_POLL:
            responseSection = <QuickPollResponse />;
            break;
        default:
            break;
    }

    let inputSection = null;
    switch (activityProps?.activityType) {
        case ActivityTypes.MULTIPLE_CHOICE:
            inputSection = <MultipleChoiceInput />;
            break;
        case ActivityTypes.WORD_CLOUD:
            inputSection = <WordCloudInput />;
            break;
        case ActivityTypes.SHORT_ANSWER:
            inputSection = <ShortAnswerInput />;
            break;
        case ActivityTypes.SLIDE_DRAWING:
            inputSection = <SlideDrawingInput />;
            break;
        case ActivityTypes.IMAGE_UPLOAD:
            inputSection = <ImageUploadInput />;
            break;
        case ActivityTypes.FILL_IN_THE_BLANKS:
            inputSection = <FillBlanksInput />;
            break;
        case ActivityTypes.AUDIO_RECORD:
            inputSection = <AudioRecordInput isDarkMode={isDarkMode} />;
            break;
        case ActivityTypes.VIDEO_UPLOAD:
            inputSection = <VideoUploadInput />;
            break;
        case ActivityTypes.QUICK_POLL:
            inputSection = <QuickPollInput />;
            break;
        default:
            break;
    }

    return (
        <Fragment>
            {activityProps ? (
                <div className="activity_choice">
                    {activity.activityStatus === ActivityStatusTypes.voting ? (
                        <VotingModal
                            open={isVotingOpen}
                            handleClose={() => setIsVotingOpen(false)}
                            timerTime={timerTime}
                        />
                    ) : null}
                    <ActivityTitleHead activityType={activityProps.activityType} />
                    <Divider />
                    <div className="chatbot_sec">
                        <div className="chatbox">
                            {activity.activityStatus === ActivityStatusTypes.submitting &&
                            submittedResponses.length === 0 ? (
                                <InstructionMessage />
                            ) : (
                                <>
                                    <div className="type_ans_box colum_rev">
                                        {submittedResponses.length > 0 && (
                                            <Fragment>
                                                <CustomAvatar
                                                    avatarName={participant.participantName}
                                                    avatarUrl={participant.participantAvatar}
                                                    type={AvatarType.PARTICIPANT}
                                                    savedClassGroups={classGroups}
                                                    groupId={participant.groupId}
                                                />
                                                {responseSection}
                                            </Fragment>
                                        )}
                                    </div>
                                    {submittedResponses.length === 0 && (
                                        <div className="toolate">
                                            <Lottie loop={false} animationData={TooLate} play speed={1} />
                                        </div>
                                    )}
                                    {activity.presenterReply && <PresenterReply />}
                                </>
                            )}
                        </div>
                        {inputSection}
                        {activity.activityStatus === 'voting' ? (
                            <div className="short_ans_form">
                                <div className="answer_area"></div>
                                <div className="sh_btn">
                                    <ContinueVotingButton clickfunction={() => setIsVotingOpen(true)} />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

export default ActivitySidePanel;
