const getImageMeta = (image: string): Promise<number[]> =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve([img.naturalWidth, img.naturalHeight]);
        img.onerror = () => reject(null);
        img.src = image;
    });

export const calculateCanvasDimensions = async (
    backgroundImage: string,
): Promise<[{ width: number; height: number }, 'pc' | 'mobile']> => {
    const bgImgDimension = await getImageMeta(backgroundImage);

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const bgImgWidthRatio = bgImgDimension[0];
    const bgImgHeightRatio = bgImgDimension[1];

    const containerPadding = windowWidth < 768 ? 30 : 40;
    const toolbarWidth = 140;
    const toolbarHeight = 170;

    const availableVerticalWidth = windowWidth - containerPadding;
    const availableVerticalHeight = windowHeight - (containerPadding + toolbarHeight);

    const availableHorizontalWidth = windowWidth - (containerPadding + toolbarWidth);
    const availableHorizontalHeight = windowHeight - containerPadding;

    const scaleForHorizontalWidth = availableHorizontalWidth / bgImgWidthRatio;
    const scaleForHorizontalHeight = availableHorizontalHeight / bgImgHeightRatio;
    const scaleForVerticalWidth = availableVerticalWidth / bgImgWidthRatio;
    const scaleForVerticalHeight = availableVerticalHeight / bgImgHeightRatio;

    const minScaleForHorizontal = Math.min(scaleForHorizontalWidth, scaleForHorizontalHeight);
    const minScaleForVertical = Math.min(scaleForVerticalWidth, scaleForVerticalHeight);

    const maxScaleLimit = 1200 / bgImgWidthRatio;
    const minScaleLimit = 300 / bgImgHeightRatio;

    if (minScaleForVertical > minScaleForHorizontal) {
        const width = bgImgWidthRatio * Math.min(minScaleForVertical, maxScaleLimit);
        const height = bgImgHeightRatio * Math.min(minScaleForVertical, maxScaleLimit);
        return [{ width, height }, 'pc'];
    } else {
        const selectedScale = Math.max(minScaleLimit, Math.min(minScaleForHorizontal, maxScaleLimit));
        const width = bgImgWidthRatio * selectedScale;
        const height = bgImgHeightRatio * selectedScale;
        return [{ width, height }, 'mobile'];
    }
};

// export const calculateCanvasDimensions = async (
//     backgroundImage: string,
// ): Promise<[{ width: number; height: number }, 'pc' | 'mobile']> => {
//     const bgImgDimension = await getImageMeta(backgroundImage);

//     const windowWidth = window.innerWidth;
//     const windowHeight = window.innerHeight;

//     const bgImgWidthRatio = bgImgDimension[0];
//     const bgImgHeightRatio = bgImgDimension[1];

//     const containerPadding = windowWidth > 767 ? 80 : windowWidth > 575 ? 40 : 10;
//     const toolbarWidth = 215;
//     const toolbarHeight = 165;

//     const availableVerticalWidth = windowWidth - containerPadding;
//     const availableVerticalHeight = windowHeight - (containerPadding + toolbarHeight);

//     const requiredVerticalWidth = (availableVerticalHeight * bgImgWidthRatio) / bgImgHeightRatio;
//     const requiredVerticalHeight = (availableVerticalWidth * bgImgHeightRatio) / bgImgWidthRatio;

//     const availableHorizontalWidth = windowWidth - (containerPadding + toolbarWidth);
//     const availableHorizontalHeight = windowHeight - containerPadding;

//     const requiredHorizontalWidth = (availableHorizontalHeight * bgImgWidthRatio) / bgImgHeightRatio;
//     const requiredHorizontalHeight = (availableHorizontalWidth * bgImgHeightRatio) / bgImgWidthRatio;

//     if (requiredVerticalWidth > requiredHorizontalWidth && requiredVerticalWidth < availableVerticalWidth) {
//         return [{ width: requiredVerticalWidth, height: availableVerticalHeight }, 'pc'];
//     }

//     if (requiredHorizontalWidth > requiredVerticalWidth && requiredHorizontalWidth < availableHorizontalWidth) {
//         return [{ width: requiredHorizontalWidth, height: availableHorizontalHeight }, 'mobile'];
//     }

//     if (requiredVerticalWidth > requiredHorizontalWidth && requiredVerticalWidth > availableVerticalWidth) {
//         if (availableVerticalWidth > requiredHorizontalWidth) {
//             return [{ width: availableVerticalWidth, height: requiredVerticalHeight }, 'pc'];
//         }

//         if (requiredHorizontalWidth > availableVerticalWidth) {
//             if (requiredHorizontalWidth < availableHorizontalWidth) {
//                 return [{ width: requiredHorizontalWidth, height: availableHorizontalHeight }, 'mobile'];
//             }

//             if (requiredHorizontalWidth > availableHorizontalWidth) {
//                 return [{ width: availableHorizontalWidth, height: requiredHorizontalHeight }, 'mobile'];
//             }
//         }
//     }

//     if (requiredHorizontalWidth > requiredVerticalWidth && requiredHorizontalWidth > availableHorizontalWidth) {
//         if (availableHorizontalWidth > requiredVerticalWidth) {
//             return [{ width: availableHorizontalWidth, height: requiredHorizontalHeight }, 'mobile'];
//         }

//         if (requiredVerticalWidth > availableHorizontalWidth) {
//             if (requiredVerticalWidth < availableVerticalWidth) {
//                 return [{ width: requiredVerticalWidth, height: availableVerticalHeight }, 'pc'];
//             }

//             if (requiredVerticalWidth > availableVerticalWidth) {
//                 return [{ width: availableVerticalWidth, height: requiredVerticalHeight }, 'pc'];
//             }
//         }
//     }

//     return [{ width: availableHorizontalWidth, height: availableHorizontalHeight }, 'mobile'];
// };
