import { ActionTypes } from '../constants/action-types.enum';

const ExportPdfReducer = (state = null, action: any) => {
    switch (action.type) {
        case ActionTypes.OPEN_EXPORTED_PDF:
            return action.payload;
        case ActionTypes.CLOSE_EXPORTED_PDF:
            return null;
        default:
            return state;
    }
};

export default ExportPdfReducer;
