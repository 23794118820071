import { useEffect, useRef, useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import WaveSurfer from 'wavesurfer.js';
import { toHHMMSS } from '../../../../../../../helpers/activityHelpers';

export default function Waveform({ url, timerTime, isVotingEnable, customHeight, isDarkMode }: any) {
    const waveformRef = useRef<any>(null);
    const wavesurfer = useRef<any>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(0.5);
    const [timeLeft, setTimeLeft] = useState(timerTime);
    const intervalRef = useRef<any>(null);
    const iosRef = useRef<any>(null);
    // const [audio] = useState(new Audio(url));

    const waveColorObject = isVotingEnable
        ? {
              waveColor: '#ffffff94',
              cursorColor: '#fff',
              progressColor: '#ffffff',
          }
        : isDarkMode
        ? {
              waveColor: '#938F99',
              progressColor: '#B7C1FF',
              cursorColor: 'transparent',
          }
        : {
              waveColor: '#00000030',
              progressColor: '#6378ff',
              cursorColor: 'transparent',
          };

    // const formWaveSurferOptions = (ref: any) => ({
    //     container: ref,
    //     backend: 'WebAudio',
    //     progressColor: waveColorObject.progressColor,
    //     waveColor: waveColorObject.waveColor,
    //     cursorColor: waveColorObject.cursorColor,
    //     barWidth: 3,
    //     barRadius: 3,
    //     barGap: 2,
    //     responsive: true,
    //     barMinHeight: 2,
    //     height: customHeight ? customHeight : 70,
    //     //height: 80,
    //     // If true, normalize by the maximum peak instead of 1.0.
    //     normalize: true,
    //     // Use the PeakCache to improve rendering speed of large waveforms.
    //     partialRender: true,
    //     // hideCursor: true,
    //     maxCanvasWidth: 50,
    // });

    const detectiOS = () => {
        return (
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
                navigator.platform,
            ) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    };

    useEffect(() => {
        setIsPlaying(false);
        //const options = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create({
            container: '#waveform',
            backend: 'WebAudio',
            progressColor: waveColorObject.progressColor,
            waveColor: waveColorObject.waveColor,
            cursorColor: waveColorObject.cursorColor,
            barWidth: 3,
            barRadius: 3,
            barGap: 2,
            responsive: true,
            barMinHeight: 2,
            height: customHeight ? customHeight : 70,
            //height: 80,
            // If true, normalize by the maximum peak instead of 1.0.
            normalize: true,
            // Use the PeakCache to improve rendering speed of large waveforms.
            partialRender: true,
            // hideCursor: true,
            maxCanvasWidth: 50,
            cursorWidth: 1,
            removeMediaElementOnDestroy: true,
            closeAudioContext: true,
        });
        wavesurfer.current.load(url);
        wavesurfer.current.on('ready', function () {
            if (wavesurfer.current) {
                wavesurfer.current.setVolume(volume);
                setVolume(volume);
            }
        });
        wavesurfer.current.on('finish', function () {
            setIsPlaying(false);
            wavesurfer.current.pause();
            setTimeLeft(timerTime);
            clearInterval(intervalRef.current);
        });
        wavesurfer.current.on('play', function (start: any, end: any) {});
        wavesurfer.current.on('pause', function (start: any, end: any) {});
        return () => wavesurfer.current.destroy();
    }, [url]);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (isPlaying) setTimeLeft((timeLeft: number) => timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalRef.current);
    }, [timeLeft, isPlaying]);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
        wavesurfer.current.playPause();
    };

    return (
        <div className={`audio_record ${isDarkMode && 'darkMode'}`}>
            <div className="bttn">
                <IconButton aria-label="delete" onClick={handlePlayPause}>
                    {!isPlaying ? <PlayArrowIcon /> : <PauseIcon />}
                </IconButton>
            </div>
            <div className="audio_wav">
                <div id="waveform" ref={waveformRef} />
                <audio id="beep" ref={iosRef} src={url} />
            </div>
            <div className="audio_timer">
                {/* <Typography variant="caption">{timeLeft > 0 ? toHHMMSS(timeLeft) : toHHMMSS(timerTime)}</Typography> */}
                <Typography variant="caption">{toHHMMSS(timerTime)}</Typography>
            </div>
        </div>
    );
}
