import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import styles from './QnaAskQuestion.module.scss';

import httpServices from '../../../../../../../services/httpService';

import { StoreInterface } from '../../../../../../../interfaces/store.interface';
import { ActionTypes } from '../../../../../../../constants/action-types.enum';

import { ROUND_CHECK } from '../../../../../../../Icon';

interface IQnaAskQuestion {
    qnaModalViewHandler: (view: 'view' | 'ask' | undefined) => void;
    qnasLength: number;
    canCloseModal: boolean;
    setCanCloseModal: (value: boolean) => void;
}

export default function QnaAskQuestion({
    qnaModalViewHandler,
    qnasLength,
    canCloseModal,
    setCanCloseModal,
}: IQnaAskQuestion) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const participant = useSelector((state: StoreInterface) => state.participant);

    const [isLoading, setIsLoading] = useState(false);
    const [question, setQuestion] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const submitQuestionHandler = async () => {
        if (!question) return;
        setIsLoading(true);
        setCanCloseModal(false);

        const success = await httpServices.submitQna(
            participant.cpcsRegion,
            participant.presenterEmail,
            participant.participantId,
            question,
        );
        setIsLoading(false);

        if (!success) {
            dispatch({ type: ActionTypes.SHOW_API_ERROR });
        } else setHasSubmitted(true);

        setTimeout(() => {
            qnaModalViewHandler('view');
            setCanCloseModal(true);
        }, 1000);
    };

    return (
        <div className={`${styles.asked_Question}`}>
            <div className={styles.question_profile}>
                <textarea
                    value={question}
                    onChange={(event) => {
                        setQuestion(event.target.value);
                        event.target.style.height = 'inherit';
                        event.target.style.height = `${event.target.scrollHeight}px`;
                        event.target.style.height = `${Math.min(event.target.scrollHeight, 300)}px`;
                    }}
                    maxLength={500}
                    readOnly={isLoading || hasSubmitted}
                    placeholder={`${t('lang_qna.add_a_question')}`}
                    className={styles.textarea}
                />
            </div>

            <div className={`${styles.btn_group} ${qnasLength < 1 && styles.centerAlign}`}>
                {qnasLength > 0 && (
                    <Button
                        onClick={() => canCloseModal && qnaModalViewHandler('view')}
                        className={styles.backBtn}
                        disableElevation
                        variant="contained"
                    >
                        <ArrowBackIcon className={`${styles.icon}`} /> {t('lang_common.back')}
                    </Button>
                )}
                <LoadingButton
                    loading={isLoading}
                    onClick={() => submitQuestionHandler()}
                    loadingPosition="start"
                    className={`${styles.prime_btn} ${isLoading && styles.loading}`}
                    variant="contained"
                >
                    {hasSubmitted && (
                        <i className={`${styles.icon}`}>
                            <ROUND_CHECK />
                        </i>
                    )}
                    {isLoading || hasSubmitted ? '' : t('lang_activity.btn_submit')}
                </LoadingButton>
            </div>
        </div>
    );
}
