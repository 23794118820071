import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import { StoreInterface } from '../../../../../../interfaces/store.interface';

import CustomAvatar, { AvatarType } from '../../../../../Common/CustomAvatar';
import generateActivityInstruction from './generateActivityInstruction';

function InstructionMessage() {
    const { activity, presenter, participant } = useSelector((state: StoreInterface) => state);

    return (
        <>
            {activity.activityProps && (
                <div className="type_ans_box big_avatar">
                    <CustomAvatar
                        avatarName={`${presenter.firstName} ${presenter.lastName}`}
                        avatarUrl={presenter.avatarUrl}
                        type={AvatarType.USER}
                    />
                    <div className="chat_text">
                        <Typography
                            className="notranslate"
                            variant="h5"
                            dangerouslySetInnerHTML={{
                                __html: `${participant.participantName}, ${generateActivityInstruction(
                                    activity.activityProps,
                                )}`,
                            }}
                        ></Typography>
                    </div>
                </div>
            )}
        </>
    );
}

export default InstructionMessage;
