import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import useSound from 'use-sound';
import { StoreInterface } from '../../../interfaces/store.interface';
import { utilConstants } from '../../../constants/utils.constants';
import './ParticipantAppNew.scss';
import { AnimationConfig } from '../../../constants/animation-configs';
import { ActionTypes } from '../../../constants/action-types.enum';
import { calculateParticipantLevelAndBadge, getLevelsArray } from '../../../helpers/userHelper';
import LevelUp from './LevelUp';
import TickLottie from './TickLottie';

export interface StarInterface {
    id: number;
    xPosition: number;
    yPosition: number;
    isCollected: boolean;
}

export default function PointOverlayTickLottie({ screenSize }: any) {
    const dispatch = useDispatch();

    const starsRef = useRef<StarInterface[]>([]);
    const endAnimationTimeout = useRef<any>();
    const levelUpAnimation = useRef<any>();

    const [play] = useSound(utilConstants.MUSIC.POINT_OVERLAY_CLICK);
    const participant = useSelector((state: StoreInterface) => state.participant);
    const presenter = useSelector((state: StoreInterface) => state.presenter);

    const levelsArray = getLevelsArray(presenter);
    const isProUser = presenter.isOnPro || false;

    const [isOverlayOn, setIsOverlayOn] = useState(false);
    const [isLevelUpPlaying, setIsLevelUpPlaying] = useState(false);
    const [currentPoints, setCurrentPoints] = useState(participant.participantPoints);
    const [participantLevel, setParticipantLevel] = useState(0);

    const safeContainerWidth = window.innerWidth - 200;
    const safeContainerHeight = window.innerHeight - 200;

    const randomPositionGenerator = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    useEffect(() => {
        setParticipantLevel(
            calculateParticipantLevelAndBadge(
                participant.participantPoints + participant.participantTotalPoints,
                levelsArray,
            ).level,
        );
    }, [participant.classCode]);

    useEffect(() => {
        if (participant.pointsBeingAdded <= 0) {
            setCurrentPoints(participant.participantPoints);
            !isLevelUpPlaying && setIsOverlayOn(false);
            if (participant.isAddingPoints) dispatch({ type: ActionTypes.POINTS_ANIMATION_COMPLETED });
            return;
        }
        if (participant.participantPoints > currentPoints) {
            const starsToAdd = participant.participantPoints - currentPoints;
            addStars(starsToAdd);
            clearTimeout(endAnimationTimeout.current);
        }
    }, [participant]);

    const addStars = (starsToAdd: number) => {
        setIsOverlayOn(true);
        setCurrentPoints(participant.participantPoints);
        for (let n = 0; n < starsToAdd; n++) {
            const newStar: StarInterface = {
                id: starsRef.current.length,
                xPosition:
                    starsRef.current.length < 1
                        ? safeContainerWidth / 2
                        : randomPositionGenerator(50, safeContainerWidth),
                yPosition:
                    starsRef.current.length < 1
                        ? safeContainerHeight / 2
                        : randomPositionGenerator(50, safeContainerHeight),
                isCollected: false,
            };
            starsRef.current.push(newStar);
        }
    };

    const checkAndHandleAllStarsCollected = () => {
        const isAllCollected = starsRef.current
            .map((star) => star.isCollected)
            .every((isCollected) => isCollected === true);

        if (isAllCollected) {
            starsRef.current = [];

            endAnimationTimeout.current = setTimeout(() => {
                if (isProUser) {
                    const newLevel = calculateParticipantLevelAndBadge(
                        participant.participantPoints + participant.participantTotalPoints,
                        levelsArray,
                    ).level;
                    if (newLevel > participantLevel) {
                        setIsLevelUpPlaying(true);
                        setParticipantLevel(newLevel);
                        setTimeout(() => {
                            levelUpAnimation.current.playAnimation(true);
                        }, 500);
                    }
                }
                setTimeout(() => {
                    dispatch({ type: ActionTypes.POINTS_ANIMATION_COMPLETED });
                }, AnimationConfig.HIDE_OVERLAY_TIMEOUT);
            }, AnimationConfig.TICK_LOTTIE_ENDING_TIMEOUT);
        } else {
            clearTimeout(endAnimationTimeout.current);
        }
    };

    return (
        <div className={`pointOverlay ${!isOverlayOn && 'hide'}`}>
            <AnimatePresence>
                {isOverlayOn && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: [1, 0] }}
                        transition={{ duration: 0.2 }}
                        className="modal"
                    >
                        <div className={`lottieGroup`}>
                            {starsRef.current.map(
                                (star) =>
                                    !star.isCollected && (
                                        <TickLottie
                                            index={star.id}
                                            key={star.id}
                                            xPosition={star.xPosition}
                                            yPosition={star.yPosition}
                                            starsRef={starsRef}
                                            checkAndHandleAllStarsCollected={checkAndHandleAllStarsCollected}
                                        />
                                    ),
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
            <LevelUp
                ref={levelUpAnimation}
                setIsOverlayOn={setIsOverlayOn}
                screenSize={screenSize}
                level={participantLevel}
                setIsLevelUpPlaying={setIsLevelUpPlaying}
            />
        </div>
    );
}
