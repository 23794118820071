import { Button, Typography } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import Constants from '../../../../../../constants';
import StopIcon from '@mui/icons-material/Stop';
import Waveform from './components/Waveform';
import { useDispatch, useSelector } from 'react-redux';
import SoundWaveJson from '../../../../../../assets/animation/soundwave.json';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { activityActions } from '../../../../../../actions/activity.actions';
import Lottie from 'react-lottie-player';
import LoaddingButton from '../../../../../Common/LoadingButton';
import ResponseToSubmitDto from '../../../../../../dtos/response-to-submit.dto';
import { ActivityPropsInterface, ActivityStatusTypes } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import { getIosVersion, isIosDevice, toHHMMSS } from '../../../../../../helpers/activityHelpers';
import httpServices from '../../../../../../services/httpService';
import { dataURItoBlob } from '../../../../../../helpers/utils';
import { participantActions } from '../../../../../../actions/participant.actions';
import { logger } from '../../../../../../services/logger';
import { Capacitor } from '@capacitor/core';
import { GenericResponse, RecordingData, VoiceRecorder } from 'capacitor-voice-recorder';
type AudioRecordInputType = {
    isDarkMode: boolean;
};
function AudioRecordInput({ isDarkMode }: AudioRecordInputType) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const participant = useSelector((state: StoreInterface) => state.participant);
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const submittedResponses = activity.submittedResponses;
    const maxDuration = 600;
    const responseId = 'resp-' + v4();
    const [errorMessage, setErrorMessage] = useState('');
    const [timerTime, setTimerTime] = useState(0);
    const [timeUpMessage, setTimeUpMessage] = useState('');
    const [isUploadingAudio, setIsUploadingAudio] = useState(false);
    const [audioData, setAudioData] = useState<any>();
    const [isRecording, setIsRecording] = useState(false);
    const blobs = useRef<Blob[]>([]);
    const intervalRef = useRef<any>(null);
    const wavesurfer: any = useRef<WaveSurfer | null>(null);
    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const stream = useRef<MediaStream | null>(null);
    const canvasRef: any = useRef(null);
    const [useWave, setUseWave] = useState(true);

    useEffect(() => {
        resetAudioRecord();
    }, [activityProps]);

    useEffect(() => {
        var isIOS = isIosDevice();
        if (isIOS) {
            var iphone_version = getIosVersion();
            if (iphone_version[0] < 15) {
                setUseWave(false);
            }
        }
    }, []);

    const detectBrowser = () => {
        const _iOSDevice = isIosDevice();
        if (_iOSDevice) return '.wav,.aif,.aiff,.flac,.alac,.aac,.ogg,.mp3,.m4a';
        else return 'audio/*';
    };

    useEffect(() => {
        if (isRecording) {
            intervalRef.current = setInterval(() => {
                setTimerTime((t) => t + 1);
            }, 1000);
            return () => clearInterval(intervalRef.current);
        }
    }, [isRecording]);

    useEffect(() => {
        if (timerTime === Infinity) {
            resetAudioRecord();
            clearInterval(intervalRef.current);
            setErrorMessage(t('lang_activity.err_audio_format_not_supported'));
            return;
        } else if (timerTime >= maxDuration) {
            setIsRecording(!isRecording);
            clearInterval(intervalRef.current);
            stopRecording();
            setTimeUpMessage(
                t('lang_activity.txt_audio_max_duration_reached', { maxMinutes: Math.floor(maxDuration / 60) }),
            );
        }
    }, [timerTime]);

    const onAudioChange = async (event: any) => {
        setErrorMessage('');
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            if (!file.type.match('audio.*')) {
                setErrorMessage(t('lang_activity.err_select_audio_file_only'));
                return false;
            }
            const audioSize = (file.size / 1024 / 1024).toFixed(2);
            const maxAudioSize = 10;
            if (parseFloat(audioSize) > maxAudioSize) {
                setErrorMessage(t('lang_activity.err_audio_too_large', { maxAudioSize }));
                return false;
            }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setAudioData(reader.result);
                const result: any = reader.result;
                const media = new Audio(result);
                media.onloadedmetadata = () => {
                    const duration: number = media.duration;
                    setTimerTime(duration);
                };
            };
        }
    };

    const visualize = () => {
        if (!stream.current) return;

        const audioCtx = new AudioContext();
        const canvas = canvasRef.current;
        const canvasCtx = canvasRef.current.getContext('2d');

        const source = audioCtx.createMediaStreamSource(stream.current);

        const analyser = audioCtx.createAnalyser();
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        source.connect(analyser);

        const draw = () => {
            const WIDTH = canvas.width;
            const HEIGHT = canvas.height;

            requestAnimationFrame(draw);

            analyser.getByteTimeDomainData(dataArray);

            canvasCtx.fillStyle = '#f2f3f7';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = '#d96367';

            canvasCtx.beginPath();

            let sliceWidth = (WIDTH * 4.0) / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i = i + 4) {
                let v = dataArray[i] / 128.0;
                let y = (v * HEIGHT) / 2;
                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }
                x += sliceWidth;
            }
            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
        };

        draw();
    };

    const showRecordedAudio = () => {
        if (!blobs.current.length) return;
        const mimeType = blobs.current ? blobs.current[0].type : '';
        const blob = new Blob(blobs.current, {
            type: mimeType,
        });
        // const url = window.URL.createObjectURL(blob);
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            var base64String = reader.result;
            setAudioData(base64String);
        };
    };

    const startRecording = async () => {
        if (Capacitor.getPlatform() === 'web' && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            setErrorMessage('');
            setIsRecording(true);
            setTimerTime(0);
            blobs.current.length = 0;
            if (wavesurfer.current) {
                wavesurfer.current.empty();
            }

            navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                })
                .then((audioStream) => {
                    mediaRecorder.current = new MediaRecorder(audioStream);
                    if (useWave) stream.current = audioStream;

                    mediaRecorder.current.ondataavailable = (e: any) => {
                        blobs.current.push(e.data);
                    };

                    mediaRecorder.current.onstop = showRecordedAudio;
                    // Let's receive 1 second blobs
                    mediaRecorder.current.start();
                    // Start the visualizer
                    visualize();
                })
                .catch((error) => {
                    stopRecording();
                    resetAudioRecord();
                    logger.error(error);
                    setErrorMessage(t('lang_activity.err_audio_record_not_supported_by_your_browser'));
                });
        } else if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
            VoiceRecorder.canDeviceVoiceRecord().then((result: GenericResponse) => {
                if (result.value) {
                    VoiceRecorder.requestAudioRecordingPermission().then((result: GenericResponse) => {
                        if (result.value) {
                            VoiceRecorder.hasAudioRecordingPermission().then((result: GenericResponse) => {
                                if (result.value) {
                                    setErrorMessage('');
                                    setIsRecording(true);
                                    setTimerTime(0);
                                    blobs.current.length = 0;
                                    if (wavesurfer.current) {
                                        wavesurfer.current.empty();
                                    }
                                    VoiceRecorder.startRecording()
                                        .then((result: GenericResponse) => {
                                            setUseWave(false);
                                        })
                                        .catch((error) => {
                                            logger.error('startRecording error', error);
                                        });
                                }
                            });
                        }
                    });
                }
            });
        } else {
            return setErrorMessage(t('lang_activity.err_audio_record_not_supported_by_your_browser'));
        }
    };

    const stopRecording = () => {
        setIsRecording(false);
        // Let's stop capture and recording
        if (Capacitor.getPlatform() === 'web' && mediaRecorder.current && mediaRecorder.current.state === 'recording') {
            mediaRecorder.current.stop();
            if (stream.current) {
                stream.current.getTracks().forEach((track) => track.stop());
            }
        } else {
            VoiceRecorder.stopRecording()
                .then((result: RecordingData) => {
                    const audiodatas = result.value;
                    const audio = `data:${audiodatas.mimeType};base64,${audiodatas.recordDataBase64}`;
                    setAudioData(audio);
                })
                .catch((error) => {
                    logger.log('stopRecording error ', error);
                });
        }
    };

    const resetAudioRecord = () => {
        // clearBlobUrl();
        setAudioData('');
        setTimerTime(0);
        setErrorMessage('');
        setTimeUpMessage('');
        setIsRecording(false);
    };

    const audioUpload = async (e: any) => {
        e.preventDefault();
        setIsUploadingAudio(true);
        const blob = dataURItoBlob(audioData);
        const recordUrl = await httpServices.uploadToStorage(blob, `${responseId}.mp3`, participant.cpcsRegion, 'user');
        if (!recordUrl) {
            setIsUploadingAudio(false);
            dispatch(participantActions.showApiError());
            return;
        }
        const responseToSubmit: ResponseToSubmitDto = {
            activityId: activityProps.activityId,
            activityType: activityProps.activityType,
            responseId,
            responseData: recordUrl,
        };
        dispatch(activityActions.submitResponse(responseToSubmit));
        setIsUploadingAudio(false);
        resetAudioRecord();
    };
    return (
        <>
            {activity.activityStatus === ActivityStatusTypes.submitting && submittedResponses.length === 0 && (
                <Fragment>
                    {errorMessage ? (
                        <div className="error_sec marginb_4">
                            <Typography variant="caption">{errorMessage}</Typography>
                        </div>
                    ) : null}
                    {!audioData ? (
                        <div className="audio_upload_buttons">
                            <div className="image_upload_grid">
                                {!isRecording ? (
                                    <Fragment>
                                        <div className="box_6">
                                            <Button className="box_image">
                                                <label htmlFor="icon-button-file" className="padding0 margin0">
                                                    <input
                                                        accept={detectBrowser()}
                                                        id="icon-button-file"
                                                        type="file"
                                                        onChange={onAudioChange}
                                                    />
                                                    <div className="image_upload">
                                                        <img src={Constants.IMAGE_URLS.audiosearch} alt="msgicon" />
                                                        <Typography>{t(`lang_activity.btn_choose_audio`)}</Typography>
                                                    </div>
                                                </label>
                                            </Button>
                                        </div>
                                        <div className="box_6">
                                            <Button
                                                className="box_image"
                                                onClick={() => {
                                                    startRecording();
                                                }}
                                            >
                                                <img src={Constants.IMAGE_URLS.mic} alt="msgicon" />
                                                <Typography>{t(`lang_activity.btn_record_audio`)}</Typography>
                                            </Button>
                                        </div>
                                    </Fragment>
                                ) : (
                                    <div className="box_6">
                                        <div className="box_image overflow_hidden" onClick={stopRecording}>
                                            <div className="soundwave">
                                                {useWave ? (
                                                    <canvas ref={canvasRef} width="1200px"></canvas>
                                                ) : (
                                                    <Lottie
                                                        loop
                                                        animationData={SoundWaveJson}
                                                        play
                                                        style={{
                                                            width: '100%',
                                                            height: 'auto',
                                                        }}
                                                    />
                                                )}
                                            </div>

                                            <div className="audio_button">
                                                <StopIcon className="stopIcon" />
                                            </div>
                                            {isRecording && (
                                                <div className="audio_player">
                                                    <Fragment>
                                                        <Typography variant="caption">{toHHMMSS(timerTime)}</Typography>
                                                    </Fragment>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="short_ans_form">
                            {timeUpMessage ? (
                                <div className="error_sec">
                                    <Typography variant="caption">{timeUpMessage}</Typography>
                                </div>
                            ) : null}
                            <div className="wave_section">
                                <Waveform url={audioData} timerTime={timerTime} isDarkMode={isDarkMode} />
                            </div>

                            <div className="sh_btn images_crop">
                                <Button variant="contained" className="cancel" onClick={resetAudioRecord}>
                                    {t(`lang_activity.btn_discard`)}
                                </Button>
                                {!isUploadingAudio ? (
                                    <Button variant="contained" onClick={(e: any) => audioUpload(e)}>
                                        {t(`lang_activity.btn_submit`)}
                                    </Button>
                                ) : (
                                    <LoaddingButton />
                                )}
                            </div>
                        </div>
                    )}
                </Fragment>
            )}
        </>
    );
}

export default AudioRecordInput;
