import { Fragment, useEffect, useRef, useState } from 'react';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useSelector } from 'react-redux';
import { StoreInterface } from '../../interfaces/store.interface';
import AvatarEditModal from '../layout/Join/modal/AvatarEditModal';
import ImageUploadError from '../layout/ImageUploadError';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Avatar } from '@mui/material';
import { getBase64 } from '../../helpers/utils';

const ProfileImage = ({ handleAvatarConfirmed, selectedAvatar }: any) => {
    const { t } = useTranslation();
    const participant = useSelector((state: StoreInterface) => state.participant);
    const avatarUrl = participant.participantAvatar;
    const [isImageError, setIsImageError] = useState(false);
    const [isAvatarEditModalOpen, setIsAvatarEditModalOpen] = useState(false);
    const userDisplayName = `${participant.participantName}`;
    const [customAvatarImage, setCustomAvatarImage] = useState<string | null | ArrayBuffer>('');
    const avatarfileref: any = useRef('null');

    useEffect(() => {
        tryLoadImageDataFromUrl(avatarUrl);
    }, [avatarUrl]);

    useEffect(() => {
        tryLoadImageDataFromUrl(selectedAvatar.imagePath);
    }, [selectedAvatar]);

    const tryLoadImageDataFromUrl = (imageUrl: string) => {
        const imageDataFrom = imageUrl ? imageUrl : null;
        if (imageDataFrom)
            getBase64(imageDataFrom).then((base64: any) => {
                setCustomAvatarImage(base64);
            });
        else {
            setCustomAvatarImage(null);
        }
    };

    const onImageChange = async (event: any) => {
        if (event.target.files && event.target.files[0]) {
            avatarfileref.current.disabled = true;
            let reader = new FileReader();
            let file = event.target.files[0];
            const isFileImage = file && file['type'].split('/')[0] === 'image';
            if (!isFileImage) {
                setIsImageError(!isImageError);
                return;
            }
            const options = {
                maxSizeMB: 2,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };
            const compressedFile: any = await imageCompression(file, options);
            reader.onloadend = () => {
                setCustomAvatarImage(reader.result);
                setIsAvatarEditModalOpen(true);
            };
            reader.readAsDataURL(compressedFile);
        }
    };

    const chooseAnotherImage = () => {
        avatarfileref.current.value = '';
        avatarfileref.current.disabled = false;
        avatarfileref.current.click();
    };

    return (
        <Fragment>
            <div className="profile_upload">
                <div className="upload_profile_images">
                    <input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        ref={avatarfileref}
                        disabled={true}
                        onChange={onImageChange}
                        onClick={(event: any) => {
                            event.target.value = null;
                        }}
                    />
                    {selectedAvatar.imagePath ? (
                        <>
                            <label htmlFor="icon-button-file-saved-class">
                                <Avatar
                                    alt={userDisplayName.toUpperCase()}
                                    src={selectedAvatar.imagePath}
                                    variant="circular"
                                />
                            </label>
                            <div className="changes_profile_images" onClick={() => setIsAvatarEditModalOpen(true)}>
                                <label htmlFor="icon-button-file">
                                    <div className="camera_pop">
                                        <label htmlFor="icon-button-file">
                                            <EditRoundedIcon />
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </>
                    ) : (
                        <label
                            className="camerabtn"
                            htmlFor="icon-button-file-saved-class"
                            onClick={chooseAnotherImage}
                        >
                            <div className="camera_pop">
                                <CameraAltIcon />
                            </div>
                        </label>
                    )}
                </div>
            </div>
            <AvatarEditModal
                isAvatarEditModalOpen={isAvatarEditModalOpen}
                setIsAvatarEditModalOpen={setIsAvatarEditModalOpen}
                customAvatarImage={customAvatarImage}
                onAvatarConfirmed={handleAvatarConfirmed}
                chooseAnotherImage={chooseAnotherImage}
            />
            <ImageUploadError
                isModalOpen={isImageError}
                cancelImage={() => setIsImageError(!isImageError)}
                textError={t(`lang_common.err_choose_image_only`)}
            />
        </Fragment>
    );
};

export default ProfileImage;
