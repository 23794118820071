import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Button, Modal, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { exportPdfActions } from '../../../../../../actions/export-pdf.actions';

import { StoreInterface } from '../../../../../../interfaces/store.interface';

import CustomAvatar, { AvatarType } from '../../../../../Common/CustomAvatar';

const ExportPdfPopUp = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const store = useSelector((state: StoreInterface) => state);
    const presenter = store.presenter;

    return (
        <Modal
            open={!!store.exportedPdf}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fragment>
                <Box className="correctAnsModal pdfmodal">
                    <div className="close_modal" onClick={() => dispatch(exportPdfActions.closeExportedPdf())}>
                        <CloseRoundedIcon />
                    </div>
                    <div className="instraction_head">
                        <div className="type_ans_box">
                            <CustomAvatar
                                avatarName={`${presenter.firstName} ${presenter.lastName}`}
                                avatarUrl={presenter.avatarUrl}
                                type={AvatarType.USER}
                            />
                            <Typography variant="caption">{t('lang_modal.txt_pdf_shared')}</Typography>
                        </div>
                    </div>
                    <div className="foot_btn">
                        <Button variant="contained" onClick={() => window.open(store.exportedPdf)}>
                            {t('lang_modal.btn_open_pdf')}
                        </Button>
                    </div>
                </Box>
            </Fragment>
        </Modal>
    );
};

export default ExportPdfPopUp;
