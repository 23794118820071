import { useState, forwardRef, useImperativeHandle } from 'react';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';

import sparkleLottie1 from '../../../assets/animation/firecrackers1.json';
import sparkleLottie2 from '../../../assets/animation/firecrackers2.json';

const LevelUp = forwardRef(({ setIsOverlayOn, screenSize, level, setIsLevelUpPlaying }: any, ref) => {
    const { t } = useTranslation();

    const [screenMidPoint, setScreenMidPoint] = useState(screenSize.current?.offsetHeight / 2 || 0);
    const moveBadgeAnimation_1 = useAnimation();
    const moveBadgeAnimation_2 = useAnimation();
    const textAnimation_1 = useAnimation();
    const textAnimation_2 = useAnimation();
    const butoonAnimation = useAnimation();

    const [isAnimating, setIsAnimating] = useState(false);
    const [showSparkle, setShowSparkle] = useState(false);
    const [pointPhase, setPointPhase] = useState(1);

    useImperativeHandle(ref, () => ({
        playAnimation,
    }));

    const playAnimation = (value: boolean) => {
        setIsAnimating(value);

        if (!value) {
            setPointPhase(1);
            setTimeout(() => {
                setShowSparkle(false);
            }, 200);
            setTimeout(() => {
                setIsOverlayOn(false);
                setIsLevelUpPlaying(false);
            }, 750);
        }
    };

    const animationHandler = () => {
        moveBadgeAnimation_1.start({
            opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
            x: [0, 0, 0, 0, 0, 0, 0, 48, 48, 48, 48, 48, 0, 0, 0],
            transition: {
                duration: 1.5,
            },
        });
        moveBadgeAnimation_2.start({
            opacity: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1],
            x: [0, 0, 0, 0, 0, 0, 0, -48, -48, -48, -48, -48, 0, 0, 0],
            scale: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.25, 1.25, 1.25],
            transition: {
                duration: 1.5,
            },
        });
        textAnimation_1.start({
            opacity: [1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
            y: [
                screenMidPoint - 15,
                screenMidPoint - 15,
                screenMidPoint - 15,
                screenMidPoint - 15,
                screenMidPoint - 15,
                screenMidPoint - 15,
                screenMidPoint - 15,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
            ],
            transition: {
                duration: 1.5,
            },
        });
        textAnimation_2.start({
            opacity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1],
            y: [
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint + 35,
                screenMidPoint,
                screenMidPoint,
                screenMidPoint,
            ],
            transition: {
                duration: 2,
            },
        });

        setTimeout(() => {
            setShowSparkle(true);
        }, 2200);

        butoonAnimation.start({
            opacity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 1, 1, 1, 1],
            y: [
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 110,
                screenMidPoint + 85,
                screenMidPoint + 85,
                screenMidPoint + 85,
                screenMidPoint + 85,
                screenMidPoint + 85,
            ],
            transition: {
                duration: 2.5,
            },
        });
    };

    const firstBadgeCompleted = () => {
        if (isAnimating && pointPhase === 1) {
            animationHandler();
            setPointPhase(2);
        }
    };

    return (
        <div className="levelUpAnimation">
            <AnimatePresence>
                {isAnimating && (
                    <>
                        <motion.img
                            initial={{ opacity: 0, x: 0, y: screenMidPoint }}
                            whileInView={{
                                opacity: 1,
                                y: screenMidPoint - 80,
                                z: 0,
                                transition: {
                                    type: 'spring',
                                    damping: 12,
                                    mass: 0.5,
                                    stiffness: 400,
                                },
                            }}
                            animate={moveBadgeAnimation_1}
                            onAnimationComplete={firstBadgeCompleted}
                            src={`/assets/levels/level_${level - 1}.svg`}
                            className="levelBadge"
                            alt="Level Up"
                        />

                        <motion.img
                            initial={{ opacity: 0, x: 0, y: screenMidPoint }}
                            whileInView={{
                                opacity: 0,
                                y: screenMidPoint - 80,
                                z: 1,
                                transition: {
                                    type: 'spring',
                                    damping: 12,
                                    mass: 0.5,
                                    stiffness: 400,
                                },
                            }}
                            animate={moveBadgeAnimation_2}
                            exit={{
                                opacity: 0,
                                y: screenMidPoint - 130,
                                transition: {
                                    duration: 0.3,
                                },
                            }}
                            onAnimationComplete={firstBadgeCompleted}
                            src={`/assets/levels/level_${level}.svg`}
                            className="levelBadge"
                            alt="Level Up"
                        />

                        <motion.p
                            initial={{ opacity: 0, x: 0, y: screenMidPoint + 35 }}
                            whileInView={{
                                opacity: [0, 1],
                                y: screenMidPoint - 15,
                                transition: {
                                    duration: 0.2,
                                },
                            }}
                            animate={textAnimation_1}
                            className="levelText"
                        >
                            {t('lang_participant_main.txt_level_num', { levelNum: level - 1 })}
                        </motion.p>

                        <motion.p
                            initial={{ opacity: 0, x: 0, y: screenMidPoint + 35 }}
                            whileInView={{
                                transition: {
                                    duration: 0.2,
                                },
                            }}
                            animate={textAnimation_2}
                            exit={{
                                opacity: [1, 1, 1, 1, 1, 1, 0, 0],
                                y: [
                                    screenMidPoint,
                                    screenMidPoint,
                                    screenMidPoint,
                                    screenMidPoint,
                                    screenMidPoint,
                                    screenMidPoint,
                                    screenMidPoint - 35,
                                    screenMidPoint - 35,
                                ],
                                transition: {
                                    duration: 0.8,
                                },
                            }}
                            className="levelText"
                        >
                            {t('lang_participant_main.txt_level_num', { levelNum: level })}
                        </motion.p>

                        <motion.button
                            initial={{ opacity: 0, x: 0, y: screenMidPoint + 110 }}
                            animate={butoonAnimation}
                            exit={{
                                opacity: [1, 1, 1, 0, 0, 0],
                                y: [
                                    screenMidPoint + 85,
                                    screenMidPoint + 85,
                                    screenMidPoint + 85,
                                    screenMidPoint + 75,
                                    screenMidPoint + 75,
                                    screenMidPoint + 75,
                                ],
                                transition: {
                                    duration: 0.6,
                                },
                            }}
                            onClick={() => playAnimation(false)}
                            className="closeBtn"
                        >
                            OK
                        </motion.button>

                        {showSparkle && (
                            <motion.div
                                initial={{ opacity: 1 }}
                                exit={{
                                    opacity: 0,
                                    transition: {
                                        duration: 0.2,
                                    },
                                }}
                                className="sparkleContainer"
                            >
                                <div className="positionCheck">
                                    <div className="sparkle">
                                        <Lottie
                                            loop={showSparkle && true}
                                            animationData={sparkleLottie1}
                                            play
                                            speed={0.75}
                                        />
                                    </div>
                                    <div className="sparkle">
                                        <Lottie
                                            loop={showSparkle && true}
                                            animationData={sparkleLottie2}
                                            play
                                            speed={0.75}
                                        />
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </>
                )}
            </AnimatePresence>
        </div>
    );
});

export default LevelUp;
