import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './helpers/store';
import './Index.scss';
import './Style.scss';
import './Rtl.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ErrorBoundary from './ErrorBoundary';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        fallbackLng: 'en',
        debug: false,
        detection: {
            caches: ['localStorage'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
        react: {
            useSuspense: false,
        },
    });

Sentry.init({
    release: '1.2.4',
    // dsn: 'https://0a5a6aefde374910b5325b8ab8f7bbcb@o1095904.ingest.sentry.io/6413538',
    dsn: 'https://487cba1919fc4daeaefd03bc784ca33c@o4504586508763136.ingest.sentry.io/4504587132862464',
    integrations: [new BrowserTracing(), new Sentry.Integrations.Breadcrumbs({ console: false }), new Sentry.Replay()],
    tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.05 : 1.0, //lower the value in production
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
    normalizeDepth: 10,
    beforeSend: (event) => {
        // if (window.location.hostname === 'localhost') {
        //     return null;
        // }
        return event;
    },
    autoSessionTracking: true, // for health monitoring
});

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </BrowserRouter>
        </React.StrictMode>
    </Provider>,

    document.getElementById('root'),
);
reportWebVitals();
