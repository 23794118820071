import { useState } from 'react';
import './ImageUpload.scss';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import SearchedImageInterface from '../../../../../../interfaces/searched-image.interface';

function ImageGrid({
    images,
    setSelectedOnlineImageUrl,
}: {
    images: SearchedImageInterface[];
    setSelectedOnlineImageUrl: (arg: string) => void;
}) {
    const [activeIndex, setActiveIndex] = useState<number>();
    const [imageLoadLimit, setImageLoadLimit] = useState(20);
    let slicedImagesArray = images.slice(0, imageLoadLimit);

    const loadMoreHandler = (e: any) => {
        if (e.target.scrollHeight - e.target.scrollTop < 300) {
            imageLoadLimit < 100 && setImageLoadLimit(imageLoadLimit + 20);
        }
    };

    const getThumbnailSource = (item: SearchedImageInterface) => {
        if (item.original.split('?')[0].endsWith('.gif')) {
            return item.original;
        } else {
            return item.thumbnail;
        }
    };

    const setSelectedUrl = async (item: SearchedImageInterface) => {
        if (item.original.split('?')[0].endsWith('.gif')) {
            setSelectedOnlineImageUrl(item.original);
        } else {
            setSelectedOnlineImageUrl(item.thumbnail);
            const isValidImage = await isImgUrl(item.original);
            // console.log('isValidImage', isValidImage);
            if (isValidImage) setSelectedOnlineImageUrl(item.original);
        }
    };

    const isImgUrl = async (url: string) => {
        const img = new Image();
        img.src = url;
        return new Promise((resolve) => {
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
        });
    };

    return (
        <div className="images_list">
            <ImageList sx={{ width: '100%', height: 280 }} cols={4} rowHeight={130} gap={6} onScroll={loadMoreHandler}>
                {slicedImagesArray.map((item, index) => (
                    <ImageListItem
                        key={index}
                        onClick={async () => {
                            setActiveIndex(index);
                            await setSelectedUrl(item);
                        }}
                        className={activeIndex === index ? 'active' : ''}
                    >
                        <img src={getThumbnailSource(item)} alt={item.title} loading="lazy" id={`imageTag${index}`} />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
}

export default ImageGrid;
