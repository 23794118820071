import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Box, Modal } from '@mui/material';

import { CurrentView } from '../../constants/current-view.enum';
import { StoreInterface } from '../../interfaces/store.interface';
import { participantActions } from '../../actions/participant.actions';
import { utilConstants } from '../../constants/utils.constants';

export default function ApiError() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentView = useSelector((state: StoreInterface) => state.currentView);

    return (
        <Modal
            open={currentView === CurrentView.SHOW_API_ERROR}
            onClose={() => dispatch(participantActions.dismissApiError())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="camera_modal"
        >
            <Box className="apiErrorModal_box">
                <div className="apiError">
                    <div className="errorContent">
                        <img src={utilConstants.IMAGE.API_ERROR} alt="api error" />
                        <Typography>{t('lang_error_boundary.txt_api_error')}</Typography>
                        <Button variant="outlined" onClick={() => dispatch(participantActions.dismissApiError())}>
                            {t('lang_common.btn_ok')}
                        </Button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
