import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

function ImageUploadResponse() {
    const activity = useSelector((state: StoreInterface) => state.activity);
    const [imageUrl, setImageUrl] = React.useState('');
    const [imageCaption, setImageCaption] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const openImageModal = (response: any) => {
        setImageUrl(JSON.parse(response.responseData)[0]);
        setImageCaption(JSON.parse(response.responseData)[1]);
        setOpen(true);
    };

    return (
        <>
            <div className="chat_text_group">
                {activity.submittedResponses.map((response, index) => (
                    <div className="chat_text update_images chatImageUpload" key={index}>
                        <img
                            src={JSON.parse(response.responseData)[0]}
                            alt="logo"
                            onClick={() => openImageModal(response)}
                        />
                        {JSON.parse(response.responseData)[1] && (
                            <Typography variant="h5" className="mali notranslate">
                                {JSON.parse(response.responseData)[1]}
                            </Typography>
                        )}
                    </div>
                ))}
            </div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="lightbox_modal"
            >
                <Box className="modal_main_container">
                    <div className="image_light_box">
                        <div className="big__content">
                            <img src={imageUrl} alt="logo" />
                            {imageCaption && (
                                <div className="images_content">
                                    <Typography variant="body1" className="mali notranslate">
                                        {imageCaption}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default ImageUploadResponse;
