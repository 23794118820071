import { useSelector } from 'react-redux';
import ParticipantInfo from './ParticipantInfo';
import ActivitySidePanel from './ActivitySidePanel';
import { StoreInterface } from '../../../../../interfaces/store.interface';

const SidePanel = () => {
    const activity = useSelector((state: StoreInterface) => state.activity);

    if (activity.activityProps) {
        return <ActivitySidePanel />;
    }
    return <ParticipantInfo />;
};

export default SidePanel;
