// Button Star Animation
export const AnimationConfig = {
    MOVESTAR_AFTER_AVATARBOX_SHOWN: 600,
    MOVE_STAR_GAP: 200,
    AVATAR_BOX_RETRACT_TIMEOUT: 2500,
    HIDE_OVERLAY_TIMEOUT: 100,

    TICK_LOTTIE_DURATION: 800,
    TICK_LOTTIE_ENDING_TIMEOUT: 100,
};
