import { ActionTypes } from '../constants/action-types.enum';
import LeaderboardParticipantInterface from '../interfaces/leaderboard-participant.interface';

const LeaderboardReducer = (state = [], action: { type: string; payload: any }): LeaderboardParticipantInterface[] => {
    switch (action.type) {
        case ActionTypes.JOINED_CLASS:
            const leaderboard = action.payload.leaderboard;
            if (!leaderboard) return [];
            else return JSON.parse(leaderboard);
        case ActionTypes.LOAD_LEADERBOARD:
            return action.payload;
        case ActionTypes.DISMISS_LEADERBOARD:
        case ActionTypes.LEFT_CLASS:
            return [];
        default:
            return state;
    }
};

export default LeaderboardReducer;
