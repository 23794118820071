import axios from 'axios';

import { logger } from './logger';
import { sentryLogApiError } from './sentryService';
import { uploadToAzureStorage } from './azureStorageService';

import SubmitResponseDto from '../dtos/submit-response.dto';
import { SavedParticipantForJoin } from '../interfaces/saved-participant-for-join.interface';
import PresenterInterface from '../interfaces/presenter.interface';
import SearchedImageInterface from '../interfaces/searched-image.interface';

class httpServices {
    static async getPresenterEmailAndRegion(
        classCode: string,
    ): Promise<{ presenterEmail: string; cpcsRegion: string } | number | null> {
        try {
            const response = await axios.get(
                process.env.REACT_APP_APITWO + '/classcode/region/byclasscode?classcode=' + classCode,
            );
            logger.warn('getRegionByClassCode>>>>', response.data);
            const { presenterEmail, cpcsRegion } = response.data;
            return { presenterEmail, cpcsRegion };
        } catch (error: any) {
            // check if class code is in cp1
            const codeInCp1 = await this.checkClassCodeInCp1(classCode);
            if (codeInCp1) return 200;
            if (error.response && error.response.status === 404) return 404;
            logger.error('getRegionByClassCode() error', error.response || error);
            if (error.response) {
                sentryLogApiError(error, 'getRegionByClassCode', {
                    query: { classCode },
                });
            }
            return null;
        }
    }

    static async checkClassCodeInCp1(classCode: string): Promise<boolean> {
        try {
            await axios.get(process.env.REACT_APP_CP1_API + '/findclass?classcode=' + classCode);
            return true;
        } catch (error) {
            return false;
        }
    }

    static async getSavedParticipantsForJoin(
        region: string,
        presenterEmail: string,
    ): Promise<SavedParticipantForJoin[]> {
        try {
            let url = '';
            if (region === 'localhost') url = process.env.REACT_APP_CLASSSESSIONAPP_LOCAL || '';
            else url = `https://${region}.classpoint.app`;

            const response = await axios.get(`${url}/liveclasses/saved-participants?email=${presenterEmail}`);
            return response.data;
        } catch (error: any) {
            logger.error('getSavedParticipantsForJoin() error: ', error.response || error);
            if (error.response) {
                sentryLogApiError(error, 'getSavedParticipantsForJoin');
            }
            return [];
        }
    }

    static async validateJoin(
        region: string,
        presenterEmail: string,
        classCode: string,
        participantId: string,
        participantUsername: string,
    ) {
        try {
            let url = '';
            if (region === 'localhost') url = process.env.REACT_APP_CLASSSESSIONAPP_LOCAL || '';
            else url = `https://${region}.classpoint.app`;
            await axios.post(`${url}/liveclasses/validate-join`, null, {
                params: { presenterEmail, classCode, participantId, participantUsername },
            });
            return '';
        } catch (error: any) {
            if (error.response && error.response.status === 400) return error.response.data;
            logger.error('validateJoin() error: ', error.response || error);
            sentryLogApiError(error, 'validateJoin');
            return 'error';
        }
    }

    static async uploadToStorage(
        file: any,
        fileName: string,
        cpcsRegion: string,
        containerName: string,
    ): Promise<string> {
        const urlFromAzureStorage = await uploadToAzureStorage(file, fileName, cpcsRegion, containerName);
        if (urlFromAzureStorage) return urlFromAzureStorage;

        const form = new FormData();
        form.append('blobFile', file, fileName);
        try {
            const url = `${process.env.REACT_APP_FILEAPP}/add/cpfile/${containerName}`;
            const response = await axios.post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data.fileUrl;
        } catch (error: any) {
            logger.error('uploadToStorage() error: ', error.response || error);
            if (error.response) {
                sentryLogApiError(error, 'uploadToStorage', {
                    body: form,
                });
            }
            return '';
        }
    }

    static async uploadUrlToStorage(fileUrl: string): Promise<string> {
        const custom_file_upload_url = `${process.env.REACT_APP_FILEAPP}/add-by-url/cpfile/user`;
        try {
            const response = await axios.post(custom_file_upload_url, {
                sourceUrl: fileUrl,
            });
            return response.data.fileUrl;
        } catch (error: any) {
            logger.error('uploadUrlToStorage() error: ', error.response || error);
            if (error.response) {
                sentryLogApiError(error, 'uploadUrlToStorage', {
                    body: { fileUrl },
                });
            }
            return '';
        }
    }

    static async getPresenterInfo(email: string): Promise<PresenterInterface | null> {
        if (!email) return null;
        try {
            const response = await axios.get(process.env.REACT_APP_API + '/users/dto/participant-app?email=' + email);
            return response.data;
        } catch (error: any) {
            logger.error('getPresenterInfo() error: ', error.response || error);
            if (error.response) {
                sentryLogApiError(error, 'getPresenterInfo', {
                    query: { email },
                });
            }
            return null;
        }
    }

    static async searchImageByKeyword(keyword: string) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_APITWO}/search/images`, { keyword });
            const images: SearchedImageInterface[] = response.data;
            return images;
        } catch (error: any) {
            logger.error('searchImageByKeyword(): ', error.response || error);
            if (error.response) {
                sentryLogApiError(error, 'searchImageByKeyword', {
                    query: { keyword },
                });
            }
            return [];
        }
    }

    static async getUnsplashImages(): Promise<string[]> {
        try {
            const page = Math.ceil(Math.random() * 100);
            const response = await axios.get(
                `https://api.unsplash.com/search/photos?page=${page}&orientation=landscape&query=nature&client_id=ioCPdn47ToiyQz7xjZTKc5kn-KfLrwqeMcTtkmF_QkU`,
            );

            const imageUrls = response.data.results.map((element: any) => element.urls.regular);
            return imageUrls;
        } catch (error: any) {
            logger.error('getUnsplashImages():', error.response || error);
            if (error.response) {
                sentryLogApiError(error, 'getUnsplashImages');
            }
            return [];
        }
    }

    static async submitResponseThroughApi(cpcsRegion: string, payload: SubmitResponseDto) {
        // No trycatch here so parent can catch it
        const baseUrl =
            cpcsRegion === 'localhost'
                ? process.env.REACT_APP_CLASSSESSIONAPP_LOCAL
                : `https://${cpcsRegion}.classpoint.app`;
        const url = baseUrl + '/liveclasses/submit-response';
        await axios.post(url, payload);
    }

    static async submitQna(
        cpcsRegion: string,
        email: string,
        participantId: string,
        question: string,
    ): Promise<boolean> {
        try {
            const baseUrl =
                cpcsRegion === 'localhost'
                    ? process.env.REACT_APP_CLASSSESSIONAPP_LOCAL
                    : `https://${cpcsRegion}.classpoint.app`;
            const url = `${baseUrl}/liveclasses/qna?email=${email}`;
            await axios.post(url, { participantId, question });
            return true;
        } catch (error: any) {
            logger.error('submitQna():', error.response || error);
            if (error.response) sentryLogApiError(error, 'submitQna');
            return false;
        }
    }

    static async voteQna(
        cpcsRegion: string,
        email: string,
        participantId: string,
        qnaId: number,
        isVote: boolean,
    ): Promise<boolean> {
        try {
            const baseUrl =
                cpcsRegion === 'localhost'
                    ? process.env.REACT_APP_CLASSSESSIONAPP_LOCAL
                    : `https://${cpcsRegion}.classpoint.app`;
            const url = `${baseUrl}/liveclasses/qna/vote?email=${email}&participantId=${participantId}&qnaId=${qnaId}&isVote=${isVote}`;
            await axios.patch(url);
            return true;
        } catch (error: any) {
            logger.error('voteQna():', error.response || error);
            if (error.response) sentryLogApiError(error, 'voteQna');
            return false;
        }
    }

    static async deleteQna(cpcsRegion: string, email: string, id: number): Promise<boolean> {
        const baseUrl =
            cpcsRegion === 'localhost'
                ? process.env.REACT_APP_CLASSSESSIONAPP_LOCAL
                : `https://${cpcsRegion}.classpoint.app`;
        try {
            await axios.delete(`${baseUrl}/liveclasses/qna?email=${email}&id=${id}`);
            return true;
        } catch (error: any) {
            logger.error('deleteQna()', error.response || error);
            sentryLogApiError(error, 'deleteQna');
            return false;
        }
    }
}

export default httpServices;
