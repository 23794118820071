export const InterfaceLanguages = [
    {
        code: 'ar',
        name: 'العربية',
        nameShort: 'العربية',
        nameInEnglish: 'Arabic',
        dir: 'rtl',
        country_flag: '/assets/language/flag.svg',
    },
    {
        code: 'zh',
        name: '简体中文',
        nameShort: '简',
        nameInEnglish: 'Chinese (Simplified)',
        country_flag: '/assets/language/Chinese(Sim).svg',
    },
    {
        code: 'tw',
        name: '繁體中文',
        nameShort: '繁',
        nameInEnglish: 'Chinese (Traditional)',
        country_flag: '/assets/language/Chinese(Trad).svg',
    },
    {
        code: 'en',
        name: 'English',
        nameShort: 'EN',
        nameInEnglish: 'English',
        country_flag: '/assets/language/English.svg',
    },
    {
        code: 'fil',
        name: 'Filipino',
        nameShort: 'PH',
        nameInEnglish: 'Filipino',
        country_flag: '/assets/language/Filipino.svg',
    },
    {
        code: 'fr',
        name: 'Français',
        nameShort: 'FR',
        nameInEnglish: 'French',
        country_flag: '/assets/language/Franch.svg',
    },
    {
        code: 'de',
        name: 'Deutsch',
        nameShort: 'DE',
        nameInEnglish: 'German',
        country_flag: '/assets/language/German.svg',
    },
    {
        code: 'hi',
        name: 'हिन्दी',
        nameShort: 'हिन्दी',
        nameInEnglish: 'Hindi',
        country_flag: '/assets/language/India.svg',
    },
    {
        code: 'id',
        name: 'Bahasa Indonesia',
        nameShort: 'ID',
        nameInEnglish: 'Indonesian',
        country_flag: '/assets/language/Indonesian.svg',
    },
    {
        code: 'ja',
        name: '日本語',
        nameShort: 'JP',
        nameInEnglish: 'Japanese',
        country_flag: '/assets/language/Japanese.svg',
    },
    {
        code: 'ms',
        name: 'Bahasa Melayu',
        nameShort: 'MY',
        nameInEnglish: 'Malay',
        country_flag: '/assets/language/Malay.svg',
    },
    {
        code: 'pt',
        name: 'Português',
        nameShort: 'PT',
        nameInEnglish: 'Portuguese',
        country_flag: '/assets/language/Portuguese.svg',
    },
    {
        code: 'ru',
        name: 'Русский',
        nameShort: 'RU',
        nameInEnglish: 'Russian',
        country_flag: '/assets/language/Russian.svg',
    },
    {
        code: 'es',
        name: 'Español',
        nameShort: 'ES',
        nameInEnglish: 'Spanish',
        country_flag: '/assets/language/Spanish.svg',
    },
    {
        code: 'th',
        name: 'ภาษาไทย',
        nameShort: 'ไทย',
        nameInEnglish: 'Thai',
        country_flag: '/assets/language/Thai.svg',
    },
    {
        code: 'vi',
        name: 'Tiếng Việt',
        nameShort: 'VIE',
        nameInEnglish: 'Vietnamese',
        country_flag: '/assets/language/Vietnamese.svg',
    },
];
