// https://meticulous.ai/blog/getting-started-with-react-logging/

/** Signature of a logging function */
export interface LogFn {
    (message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
export interface Logger {
    log: LogFn;
    warn: LogFn;
    error: LogFn;
}

/** Log levels */
export type LogLevel = 'log' | 'warn' | 'error';

// Console Message Styles
export const consoleStyles = 'color: black';
export const warningStyles = 'color: black';
export const errorStyles = 'color: black';

const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
    readonly log: LogFn;
    readonly warn: LogFn;
    readonly error: LogFn;

    constructor(options?: { level?: LogLevel }) {
        const { level } = options || {};

        this.error = console.error.bind(console);
        this.warn = console.warn.bind(console);
        this.log = console.log.bind(console);

        if (level === 'error') {
            this.warn = NO_OP;
            this.log = NO_OP;
            return;
        } else if (level === 'warn') {
            this.log = NO_OP;
            return;
        }
    }
}

export const logger = new ConsoleLogger({ level: process.env.REACT_APP_ENV === 'production' ? 'error' : 'log' });
