import { Fragment } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';

const LoaddingButton = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                <span className="btntext">{t(`lang_activity.btn_submitting`)}</span>
            </LoadingButton>
        </Fragment>
    );
};

export default LoaddingButton;
