import { sentryLogApiError } from './../services/sentryService';
import { SubmittedResponseInterface } from './../interfaces/activity.interface';
import { ParticipantInterface } from './../interfaces/participant.interface';
import { ActivityInterface, ActivityStatusTypes } from '../interfaces/activity.interface';
import { activityInitialState } from '../reducers/activity.reducer';
import httpServices from '../services/httpService';
import { ActionTypes } from '../constants/action-types.enum';
import { store } from './../helpers/store';
import SubmitResponseDto from '../dtos/submit-response.dto';
import ResponseToSubmitDto from '../dtos/response-to-submit.dto';
import CurrentClassInterface from '../interfaces/current-class.interface';
import { findPresenterReplyPool } from '../helpers/languageHelpers';
import { logger } from '../services/logger';
import { sentryLogSignalrError } from '../services/sentryService';

function startActivity(data: any) {
    return async (dispatch: (arg0: { type: string; payload: Partial<ActivityInterface> }) => void) => {
        const payload: ActivityInterface = {
            ...activityInitialState,
            activityStatus: ActivityStatusTypes.submitting,
            activityProps: data,
        };
        dispatch({ type: ActionTypes.START_ACTIVTY, payload });
    };
}

function syncActivityWhenJoin(currClass: any) {
    return async (dispatch: (arg0: { type: string; payload: Partial<ActivityInterface> }) => void) => {
        let payload: Partial<ActivityInterface> = {};
        if (currClass.activityModel) {
            const activityProps = JSON.parse(JSON.stringify(currClass.activityModel));
            delete activityProps.yourSubmittedResponses;
            payload.activityProps = activityProps;

            const activityStatus = currClass.activityModel.activityStatus;
            payload.activityStatus = activityStatus;

            const submittedResponses: SubmittedResponseInterface[] =
                currClass.activityModel.yourSubmittedResponses.map((r: any) => ({
                    activityId: r.activityId,
                    activityType: r.activityType,
                    responseId: r.responseId,
                    responseData: r.responseData,
                })) || [];
            payload.submittedResponses = submittedResponses;

            const presenterReplyMessagesPool = findPresenterReplyPool();
            if (activityStatus === ActivityStatusTypes.submitting) {
                if (submittedResponses.length === 0) payload.presenterReply = '';
                else {
                    const canAddMore = submittedResponses.length < (activityProps.numOfSubmissionsAllowed || 0);
                    payload.presenterReply = canAddMore
                        ? presenterReplyMessagesPool.submittedAddMore[
                              Math.floor(Math.random() * presenterReplyMessagesPool.submittedAddMore.length)
                          ]
                        : presenterReplyMessagesPool.singleResponseSubmitted[
                              Math.floor(Math.random() * presenterReplyMessagesPool.singleResponseSubmitted.length)
                          ];
                }
            } else if (activityStatus === ActivityStatusTypes.submitted) {
                if (submittedResponses.length === 0)
                    payload.presenterReply =
                        presenterReplyMessagesPool.closedNotSubmitted[
                            Math.floor(Math.random() * presenterReplyMessagesPool.closedNotSubmitted.length)
                        ];
                else if ((activityProps.numOfSubmissionsAllowed || 0) > 1) {
                    if (submittedResponses.length > 1)
                        payload.presenterReply =
                            presenterReplyMessagesPool.closedMultipleSubmitted[
                                Math.floor(Math.random() * presenterReplyMessagesPool.closedMultipleSubmitted.length)
                            ];
                    else
                        payload.presenterReply =
                            presenterReplyMessagesPool.singleResponseSubmitted[
                                Math.floor(Math.random() * presenterReplyMessagesPool.singleResponseSubmitted.length)
                            ];
                }
            }
        } else {
            payload = { ...activityInitialState };

            // No voting feature right now
            // if (currClass.votingDto) {
            //     const votingDto = currClass.votingDto;
            //     payload.activityProps = {
            //         email: currClass.presenter.email,
            //         activitySlideUrl: '',
            //         activityId: votingDto.activityId,
            //         activityType: votingDto.activityType,
            //     };
            //     payload.activityStatus = ActivityStatusTypes.voting;
            //     payload.votingNumber = votingDto.numOfVotesToGive;
            //     payload.votingResponses = votingDto.responsesList;
            // }
        }
        dispatch({ type: ActionTypes.JOINED_CLASS_SYNC_ACTIVITY, payload });
    };
}

function submitResponse(responseToSubmit: ResponseToSubmitDto) {
    return async (dispatch: (arg0: { type: string; payload?: Partial<ActivityInterface> }) => void) => {
        const activity: ActivityInterface = store.getState().activity;
        const participant: ParticipantInterface = store.getState().participant;
        const updatedSubmittedResponses = [...activity.submittedResponses, responseToSubmit];
        const canAddMore = updatedSubmittedResponses.length < (activity.activityProps?.numOfSubmissionsAllowed || 0);

        const presenterReplyMessagesPool = findPresenterReplyPool();
        const submittedMessage = canAddMore
            ? presenterReplyMessagesPool.submittedAddMore[
                  Math.floor(Math.random() * presenterReplyMessagesPool.submittedAddMore.length)
              ]
            : presenterReplyMessagesPool.singleResponseSubmitted[
                  Math.floor(Math.random() * presenterReplyMessagesPool.singleResponseSubmitted.length)
              ];

        const payload = {
            submittedResponses: updatedSubmittedResponses,
            presenterReply: submittedMessage,
        };

        const fullResponseToSubmit: SubmitResponseDto = {
            presenterEmail: participant.presenterEmail,
            participantId: participant.participantId,
            participantName: participant.participantName,
            participantAvatar: participant.participantAvatar,
            ...responseToSubmit,
        };
        logger.log('fullResponseToSubmit', fullResponseToSubmit);

        try {
            const connection = store.getState().connection;
            await connection.invoke('SubmitResponse', fullResponseToSubmit);
            dispatch(responseSubmitted(payload));
        } catch (error) {
            try {
                await httpServices.submitResponseThroughApi(participant.cpcsRegion, fullResponseToSubmit);
                dispatch(responseSubmitted(payload));
            } catch (error: any) {
                logger.error('submitResponseUsingApi() error: ', error.response || error);
                sentryLogApiError(error, 'submitResponseThroughApi', {
                    query: { cpcsRegion: participant.cpcsRegion },
                    body: { fullResponseToSubmit },
                });
                dispatch({ type: ActionTypes.SHOW_API_ERROR });
            }
        }
    };
}

function closeSubmission() {
    return async (dispatch: (arg0: { type: string; payload: Partial<ActivityInterface> }) => void) => {
        const activity: ActivityInterface = store.getState().activity;
        const presenterReplyMessagesPool = findPresenterReplyPool();

        const payload: Partial<ActivityInterface> = {
            activityStatus: ActivityStatusTypes.submitted,
        };

        if (activity.submittedResponses.length === 0)
            payload.presenterReply =
                presenterReplyMessagesPool.closedNotSubmitted[
                    Math.floor(Math.random() * presenterReplyMessagesPool.closedNotSubmitted.length)
                ];
        else if ((activity.activityProps?.numOfSubmissionsAllowed || 0) > 1) {
            if (activity.submittedResponses.length > 1)
                payload.presenterReply =
                    presenterReplyMessagesPool.closedMultipleSubmitted[
                        Math.floor(Math.random() * presenterReplyMessagesPool.closedMultipleSubmitted.length)
                    ];
            else
                payload.presenterReply =
                    presenterReplyMessagesPool.singleResponseSubmitted[
                        Math.floor(Math.random() * presenterReplyMessagesPool.singleResponseSubmitted.length)
                    ];
        }
        dispatch({
            type: ActionTypes.CLOSE_SUBMISSION,
            payload,
        });
    };
}

function endActivity() {
    return async (dispatch: (arg0: { type: string }) => void) => {
        dispatch({
            type: ActionTypes.END_ACTIVITY,
        });
    };
}

function shownCorrectAnswer() {
    return async (dispatch: (arg0: { type: string }) => void) => {
        const activity: ActivityInterface = store.getState().activity;
        if (!activity.activityProps) return;
        dispatch({
            type: ActionTypes.SHOW_CORRECT_ANSWER,
        });
    };
}

function votingStarted(data: any) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const activityProps = store.getState().activity.activityProps;
        let payload: any = {
            activityStatus: ActivityStatusTypes.voting,
            votingNumber: data.numOfVotesToGive,
            votingResponses: data.responsesList,
        };
        if (!activityProps)
            payload = {
                ...payload,
                activityProps: {
                    activityId: data.activityId,
                    activityType: data.activityType,
                },
            };
        dispatch({
            type: ActionTypes.VOTING_STARTED,
            payload,
        });
    };
}

function deleteResponse(data: any) {
    return async (dispatch: (arg0: { type: string; payload: Partial<ActivityInterface> }) => void) => {
        const activity = store.getState().activity;
        const updatedSubmittedResponses = [...activity.submittedResponses].filter(
            (a) => a.responseId !== data.responseId,
        );
        const presenterReplyMessagesPool = findPresenterReplyPool();
        const responseDeletedMessage =
            presenterReplyMessagesPool.responseDeleted[
                (Math.random() * presenterReplyMessagesPool.responseDeleted.length) | 0
            ];
        const payload = {
            submittedResponses: updatedSubmittedResponses,
            presenterReply: responseDeletedMessage,
        };
        dispatch({
            type: ActionTypes.RESPONSE_DELETED,
            payload,
        });
    };
}

function voteResponse(responseId: string, isVote: boolean) {
    return async (dispatch: (arg0: { type: string; payload: Partial<ActivityInterface> }) => void) => {
        const activity = store.getState().activity;
        const participant = store.getState().participant;
        const currentClass = store.getState().currentClass as CurrentClassInterface;

        const updatedResponsesToVote = [...activity.votingResponses];
        const targetResponse = updatedResponsesToVote.find((r) => r.responseId === responseId);
        if (isVote) targetResponse.voterParticipantIds.push(participant.participantId);
        else
            targetResponse.voterParticipantIds = targetResponse.voterParticipantIds.filter(
                (r: any) => r.responseId !== responseId,
            );
        dispatch({
            type: ActionTypes.SUBMIT_VOTE,
            payload: {
                votingResponses: updatedResponsesToVote,
            },
        });

        const payload = {
            email: currentClass.presenter.email,
            responseId,
            isVote: isVote,
            voterParticipantId: participant.participantId,
        };

        try {
            const connection = store.getState().connection;
            await connection.invoke('VoteResponse', payload);
        } catch (error) {
            logger.error('voteResponse error', error);
            sentryLogSignalrError(error, 'voteResponse', payload);
        }
    };
}

function numOfVotesUpdated(data: any) {
    return async (dispatch: (arg0: { type: string; payload: Partial<ActivityInterface> }) => void) => {
        const activity = store.getState().activity;
        const updatedResponsesToVote = [...activity.votingResponses];
        const targetResponse = updatedResponsesToVote.find((r) => r.responseId === data.responseId);
        targetResponse.voterParticipantIds = data.voterParticipantIds;
        dispatch({
            type: ActionTypes.VOTING_RESPONSES_UPDATED,
            payload: {
                votingResponses: updatedResponsesToVote,
            },
        });
    };
}

function votingStopped() {
    return async (dispatch: (arg0: { type: string; payload: Partial<ActivityInterface> }) => void) => {
        const activity = store.getState().activity;
        const activityStatus = activity.submittedResponses.length > 0 ? ActivityStatusTypes.submitted : null;
        const activityProps = activity.submittedResponses.length > 0 ? activity.activityProps : null;
        dispatch({
            type: ActionTypes.VOTING_STOPPED,
            payload: {
                activityStatus,
                activityProps,
                presenterReply: '',
                votingNumber: 0,
                votingResponses: [],
            },
        });
    };
}

const responseSubmitted = (payload: Partial<ActivityInterface>) => ({
    type: ActionTypes.RESPONSE_SUBMITTED,
    payload,
});

function hideCorrectAnswer() {
    return async (dispatch: (arg0: { type: string }) => void) => {
        dispatch({
            type: ActionTypes.HIDE_CORRECT_ANSWER,
        });
    };
}

export const activityActions = {
    startActivity,
    syncActivityWhenJoin,
    submitResponse,
    closeSubmission,
    endActivity,
    shownCorrectAnswer,
    votingStarted,
    deleteResponse,
    voteResponse,
    numOfVotesUpdated,
    votingStopped,
    hideCorrectAnswer,
};
