import { logger } from '../services/logger';

import { QnaInterface } from '../interfaces/qna.interface';
import { utilConstants } from '../constants/utils.constants';

export const getFirstLettersFromName = (name: string) => {
    const firstLetters = name
        .split(' ')
        .slice(0, 2)
        .map((word) => word[0])
        .join('');
    return firstLetters;
};

export const stringToNumber = (inputString: string): number => {
    let outputNumber = 0;

    for (let i = 0; i < inputString.length; i++) {
        let code = inputString.charCodeAt(i);
        outputNumber += code;
    }
    return outputNumber;
};

export const getColorForString = (inputString: string) => {
    const colorIndex = stringToNumber(inputString) % utilConstants.AVATAR_COLORS.length;
    return utilConstants.AVATAR_COLORS[colorIndex];
};

export const generateAsterisksFromName = (name: string) => {
    let asterisksLength = stringToNumber(name) % 6;
    return Array(asterisksLength + 4)
        .fill('*')
        .join('');
};

export const getValidatedImageUrl = async (url: string | null): Promise<string> => {
    try {
        if (!url) return '';
        const res = await fetch(url);
        const buff = await res.blob();
        if (buff.type.startsWith('image/')) return url;
        else return '';
    } catch (error) {
        return '';
    }
};

export const dataURItoBlob = (dataURI: any) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
};

export const fileurlToFile = (url: any) =>
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                }),
        );

export const getBase64FromFile = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const getBase64 = async (fileurl: string) => {
    const uid = new Date().getTime().toString(36);
    try {
        const data = await fetch(fileurl + '?' + uid);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
            reader.onerror = () => {
                resolve(null);
            };
        });
    } catch (error) {
        return null;
    }
};

export const getValidImageUrls = async (imageUrls: string[]) => {
    return new Promise((resolve, reject) => {
        const validImage: string[] = [];
        const promises = new Promise((secresolve, secreject) => {
            imageUrls.forEach(async (imageUrl: string) => {
                try {
                    const result = await fetch(imageUrl, { method: 'HEAD' }); // Now this will wait till it finished
                    if (result.ok) {
                        validImage.push(imageUrl);
                        secresolve(true);
                    } else secreject(false);
                } catch (e) {
                    logger.error('e', e);
                    // secreject(e);
                }
            });
        });
        Promise.all([promises]).then((values) => {
            resolve(validImage); // [32, 123, "foo"]
        });
    });
};

export const selectRandomElementsFromArray = (array: any[], count: number) => {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
};

export const qnaSortByVotesThenByCreated = (qnas: QnaInterface[]) => {
    return qnas.sort((a, b) => {
        if (b.votes === a.votes) {
            return new Date(a.created).getTime() - new Date(b.created).getTime();
        }
        return b.votes - a.votes;
    });
};
