import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgress, Typography } from '@mui/material';

import Constants from '../../../../../constants';

export default function LiveSlideshowOff() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="presenter_img audienceimage">
                <div className="text">
                    <img src={Constants.IMAGE_URLS.slideViewer_disabled} alt="" width="100%" />
                    <Typography variant="h6">{t(`lang_slide_viewer.txt_live_slideshow_is_off`)}</Typography>
                </div>
            </div>
            <div className="loader_main">
                <LinearProgress color="warning" variant="determinate" value={0} />
            </div>
        </Fragment>
    );
}
