import { Typography, styled, Button, Box, Modal } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { activityActions } from '../../../../../../actions/activity.actions';
import Constants from '../../../../../../constants';
import ResponseToSubmitDto from '../../../../../../dtos/response-to-submit.dto';
import { ActivityPropsInterface, ActivityStatusTypes } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import LoadingVideoModal from './LoadingVideoModal';
import VideoCaptureModal from './VideoCaptureModal';
import VideoPreviewModal from './VideoPreviewModal';
import { MediaFile, VideoCapturePlusOptions, VideoCapturePlus } from '@ionic-native/video-capture-plus';

import { File, DirectoryEntry } from '@ionic-native/file';
import { Capacitor } from '@capacitor/core';
const Input = styled('input')({
    display: 'none',
});

function VideoUploadInput() {
    const dispatch = useDispatch();
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const submittedResponses = activity.submittedResponses;
    const responseIdBase = 'resp-' + v4();
    const { t } = useTranslation();

    const [isLoadingVideoModalOpen, setIsLoadingVideoModalOpen] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isCaptureModalOpen, setIsCaptureModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [caption, setCaption] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [fileData, setFileData] = useState<any>();
    const [videoData, setVideoData] = useState<any>();
    const [isCapturing, setIsCapturing] = useState(false);
    const [isCaptureDone, setIsCaptureDone] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const inputfileref = useRef<any>(null);
    const inputfilerefMob = useRef<any>(null);

    // const { VideoRecorder } = Plugins;

    // useEffect(() => {
    //     if (Capacitor.getPlatform() === 'ios'){

    //         const config: VideoRecorderPreviewFrame = {
    //             id: 'video-record',
    //             stackPosition: 'front', // 'front' overlays your app', 'back' places behind your app.
    //             width: 'fill',
    //             height: 'fill',
    //             x: 0,
    //             y: 0,
    //             borderRadius: 0
    //         };
    //          VideoRecorder.initialize({
    //             camera: VideoRecorderCamera.FRONT, // Can use BACK
    //             previewFrames: [config]
    //         });

    //     }
    // }, []);

    useEffect(() => {
        setErrorMessage('');
    }, [activityProps]);

    useEffect(() => {
        if (activity.activityStatus === ActivityStatusTypes.submitted) {
            setIsPreviewModalOpen(false);
            setIsCaptureModalOpen(false);
        }
    }, [activity.activityStatus]);

    const onVideoPicked = async (event: any) => {
        setIsLoadingVideoModalOpen(true);
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            const videoSize = Math.ceil(file.size / 1024 / 1024);
            if (videoSize > 200) {
                setErrorMessage(t('lang_activity.err_video_too_large', { videoSize }));
            } else {
                setErrorMessage('');
                setFileData(file);
            }
        }
    };

    const videoUploadSubmit = async (e: any) => {
        if (activityProps.isCaptionRequired && !caption.trim())
            return setErrorMessage(t('lang_activity.err_caption_required'));

        setIsSubmitting(true);
        const responseData = [videoUrl, caption];
        const responseToSubmit: ResponseToSubmitDto = {
            activityId: activityProps.activityId,
            activityType: activityProps.activityType,
            responseId: responseIdBase,
            responseData: JSON.stringify(responseData),
        };
        dispatch(activityActions.submitResponse(responseToSubmit));
        setIsSubmitting(false);
        setCaption('');
        discardVideo();
    };

    const doMediaCapture = async () => {
        let options: VideoCapturePlusOptions = {
            limit: 1,
            duration: 30,
        };
        let capture: any = await VideoCapturePlus.captureVideo(options);
        let media = capture[0] as MediaFile;
        // get just the directory without name
        let path = media.fullPath.substring(0, media.fullPath.lastIndexOf('/'));

        let resolvedPath: DirectoryEntry = await File.resolveDirectoryUrl('file://' + path);
        return File.readAsArrayBuffer(resolvedPath.nativeURL, media.name).then(
            (buffer: any) => {
                // get the buffer and make a blob to be saved
                let imgBlob: any = new Blob([buffer], {
                    type: media.type,
                });
                setFileData(imgBlob);
                setIsLoadingVideoModalOpen(true);
            },
            (error: any) => console.log(error),
        );
    };

    const discardVideo = () => {
        setIsLoadingVideoModalOpen(false);
        setVideoUrl('');
        if (inputfileref.current) inputfileref.current.value = null;
        if (inputfilerefMob.current) inputfilerefMob.current.value = null;
    };
    return (
        <React.Fragment>
            <div className="Video_upload">
                {isLoadingVideoModalOpen && (
                    <Fragment>
                        <Modal
                            open={true}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className="correctAnsModal video_pre_modal">
                                <LoadingVideoModal
                                    onClose={() => discardVideo()}
                                    errorMessage={errorMessage}
                                    setIsLoadingVideoModalOpen={setIsLoadingVideoModalOpen}
                                    setIsPreviewModalOpen={setIsPreviewModalOpen}
                                    fileData={fileData}
                                    setErrorMessage={setErrorMessage}
                                    setVideoUrl={setVideoUrl}
                                    inputfileref={inputfileref}
                                    inputfilerefMob={inputfilerefMob}
                                />
                            </Box>
                        </Modal>
                    </Fragment>
                )}

                {videoUrl && (
                    <VideoPreviewModal
                        open={isPreviewModalOpen}
                        onClose={() => setIsPreviewModalOpen(false)}
                        videoUrl={videoUrl}
                        setIsPreviewModalOpen={setIsPreviewModalOpen}
                        onDiscard={discardVideo}
                        videoUploadSubmit={videoUploadSubmit}
                        isSubmitting={isSubmitting}
                        isCaptionRequired={activityProps.isCaptionRequired}
                        caption={caption}
                        setCaption={setCaption}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                    />
                )}
                {isCaptureModalOpen && (
                    <VideoCaptureModal
                        openCapture={isCaptureModalOpen}
                        handleCloseCapture={() => setIsCaptureModalOpen(false)}
                        setOpenCapture={setIsCaptureModalOpen}
                        setVideoData={setVideoData}
                        videoData={videoData}
                        setIsCapturing={setIsCapturing}
                        setIsCaptureDone={setIsCaptureDone}
                        isCapturing={isCapturing}
                        isCaptureDone={isCaptureDone}
                        setRecordedChunks={setRecordedChunks}
                        recordedChunks={recordedChunks}
                    />
                )}

                <React.Fragment>
                    <div className={submittedResponses.length === 0 ? 'type_ans_warp' : 'chatbot_sec'}>
                        {activity.activityStatus === ActivityStatusTypes.submitting &&
                            submittedResponses.length === 0 && (
                                <Fragment>
                                    {!videoUrl ? (
                                        <div className="upload_buttons">
                                            <div className="upload_grid">
                                                <Fragment>
                                                    <div className="box_6">
                                                        <Button className="box_image">
                                                            <label htmlFor="icon-button-file">
                                                                <Input
                                                                    accept="video/mp4,video/m4v,video/mov"
                                                                    id="icon-button-file"
                                                                    type="file"
                                                                    ref={inputfileref}
                                                                    onChange={onVideoPicked}
                                                                />
                                                                <div className="image_upload">
                                                                    <img
                                                                        src={Constants.IMAGE_URLS.videosearch}
                                                                        alt="msgicon"
                                                                    />
                                                                    <Typography>
                                                                        {t(`lang_activity.btn_choose_video`)}
                                                                    </Typography>
                                                                </div>
                                                            </label>
                                                        </Button>
                                                    </div>
                                                    {!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                                                        navigator.userAgent,
                                                    ) ? (
                                                        <div className="box_6">
                                                            <Button
                                                                className="box_image"
                                                                onClick={() => setIsCaptureModalOpen(true)}
                                                            >
                                                                <img src={Constants.IMAGE_URLS.video} alt="msgicon" />
                                                                <Typography>{t(`lang_activity.btn_camera`)}</Typography>
                                                            </Button>
                                                        </div>
                                                    ) : (
                                                        <div className="box_6">
                                                            <Button className="box_image">
                                                                <label htmlFor="icon-record-button-file">
                                                                    {Capacitor.getPlatform() === 'ios' ? (
                                                                        <div className="image_upload">
                                                                            <img
                                                                                src={Constants.IMAGE_URLS.video}
                                                                                alt="msgicon"
                                                                                onClick={() => doMediaCapture()}
                                                                            />
                                                                            <Typography>
                                                                                {t(`lang_activity.btn_camera`)}
                                                                            </Typography>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="image_upload">
                                                                            <Input
                                                                                accept="video/*"
                                                                                // accept="video/mp4,video/m4v,video/mov;capture=camcorder"
                                                                                id="icon-record-button-file"
                                                                                type="file"
                                                                                ref={inputfilerefMob}
                                                                                onChange={onVideoPicked}
                                                                                capture="environment"
                                                                            />
                                                                            <div className="image_upload">
                                                                                <img
                                                                                    src={Constants.IMAGE_URLS.video}
                                                                                    alt="msgicon"
                                                                                />
                                                                                <Typography>
                                                                                    {t(`lang_activity.btn_camera`)}
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </label>
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="sh_btn">
                                            <Button variant="contained" onClick={() => setIsPreviewModalOpen(true)}>
                                                {t(`lang_activity.btn_open_video`)}
                                            </Button>
                                        </div>
                                    )}
                                </Fragment>
                            )}
                    </div>
                </React.Fragment>
            </div>
        </React.Fragment>
    );
}

export default VideoUploadInput;
