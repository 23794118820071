import { Box, Modal } from '@mui/material';
import React, { useState, Fragment } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ReactPlayer from 'react-player';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';

const VotingVideoUploadModal = ({
    choosedResponse,
    choosedResponseIndex,
    showPreviousNote,
    showNextNote,
    handleClose,
    open,
    handleVote,
}: any) => {
    const [hideNames, sethideNames] = useState<Boolean>(false);
    const [favoriteCount, setFavoriteCount] = useState<any>(5);
    const activity = useSelector((state: any) => state.activity);
    const participant = useSelector((state: any) => state.participant);
    const votingResponses: any = activity.votingResponses;
    const voterParticipantIds: any = choosedResponse.voterParticipantIds
        ? choosedResponse.voterParticipantIds
        : [];
    const isVotedByMe = voterParticipantIds.includes(participant.participantId);
    return (
        <Fragment>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="video_upload_modal "
            >
                <Box className="short_ans_box">
                    <div className="close_modal" onClick={handleClose}>
                        <CloseRoundedIcon />
                    </div>
                    <div className="sky-slider">
                        <div className="sky-slider-controls">
                            <ul>
                                <li className="sky-slider__prev">
                                    <button
                                        onClick={() =>
                                            showPreviousNote(
                                                choosedResponseIndex,
                                            )
                                        }
                                    >
                                        <svg
                                            className="chevron-right"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 306 306"
                                        >
                                            <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153 " />
                                        </svg>
                                    </button>
                                </li>

                                <li className="sky-slider__next">
                                    <button
                                        onClick={() =>
                                            showNextNote(choosedResponseIndex)
                                        }
                                    >
                                        <svg
                                            className="chevron-right"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 306 306"
                                        >
                                            <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153 " />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <ul className="sky-slider__img-container">
                            <li className="active">
                                <div className="big_sh_box">
                                    <div className="big_sh_content">
                                        <ReactPlayer
                                            className="react-player video_upload"
                                            width="100%"
                                            controls={true}
                                            url={choosedResponse.responseData}
                                        />
                                    </div>
                                    {/* <div className="images_content">
                                        <Typography variant="body1">
                                            {choosedResponse.imageCaption}
                                        </Typography>
                                    </div> */}
                                    <div className="sh_footer">
                                        <div className="name_box">
                                            <Typography variant="body1">
                                                {hideNames
                                                    ? 'Anonymous'
                                                    : choosedResponse.participantName}
                                            </Typography>
                                        </div>
                                        {!isVotedByMe && (
                                            <div className="like_count">
                                                <FavoriteBorderIcon
                                                    onClick={() =>
                                                        handleVote(
                                                            choosedResponse.responseId,
                                                            true,
                                                        )
                                                    }
                                                />
                                                <Typography variant="caption">
                                                    {voterParticipantIds.length}
                                                </Typography>
                                            </div>
                                        )}
                                        {isVotedByMe && (
                                            <div className="like_count">
                                                <FavoriteIcon
                                                    className="red stroke"
                                                    onClick={() =>
                                                        handleVote(
                                                            choosedResponse.responseId,
                                                            false,
                                                        )
                                                    }
                                                />
                                                <Typography variant="caption">
                                                    {voterParticipantIds.length}
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default VotingVideoUploadModal;
