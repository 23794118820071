import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import { StoreInterface } from '../../../../../interfaces/store.interface';
import NotInSlideshow from './NotInSlideshow';
import LiveSlideshowOff from './LiveSlideshowOff';
import FileSaver from 'file-saver';
import SlideViewerFooter from './SlideViewerFooter';

const SlideViewer = () => {
    const store = useSelector((state: StoreInterface) => state);
    const { currentClass, activity, presenter } = store;
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        if (!currentClass.currentSlideshow.isAudienceSlideViewerEnabled) setPercentage(0);
        else if (!activity.activityStatus || percentage === 0)
            // no activity, or first render. this is to make sure progress bar doesn't move when activity is going on
            setPercentage(
                (currentClass.currentSlideshow.currentSlideIndex / currentClass.currentSlideshow.totalSlideCount) * 100,
            );
    }, [currentClass, activity.activityStatus]);

    const shouldDisplaySlide = () => {
        const isAudienceSlideViewerEnabled = currentClass.currentSlideshow.isAudienceSlideViewerEnabled;
        const isRunningActivity =
            activity.activityProps !== null && activity.activityProps?.activityType !== 'Quick Poll';
        return isAudienceSlideViewerEnabled || isRunningActivity;
    };

    const handleSaveSlide = () => {
        FileSaver.saveAs(
            activity.activityProps?.activitySlideUrl || currentClass.currentSlideshow.imageUrl,
            'Slide.png',
        );
    };

    return (
        <>
            <div className="left_card">
                <div className="presenter_view">
                    <div className="presenter_inner">
                        {currentClass.isInSlideshow && currentClass.currentSlideshow !== null ? (
                            <>
                                {shouldDisplaySlide() ? (
                                    <Fragment>
                                        <div className="presenter_img">
                                            <div className="text">
                                                <img
                                                    src={
                                                        activity.activityProps?.activitySlideUrl ||
                                                        currentClass.currentSlideshow.imageUrl
                                                    }
                                                    alt=""
                                                    width="100%"
                                                />
                                            </div>
                                        </div>
                                        <div className="loader_main">
                                            <LinearProgress color="warning" variant="determinate" value={percentage} />
                                        </div>
                                    </Fragment>
                                ) : (
                                    <LiveSlideshowOff />
                                )}
                            </>
                        ) : (
                            <NotInSlideshow />
                        )}
                    </div>
                </div>
                <SlideViewerFooter
                    presenter={presenter}
                    shouldDisplayIcons={
                        currentClass.isInSlideshow && currentClass.currentSlideshow !== null && shouldDisplaySlide()
                    }
                    onSaveSlide={handleSaveSlide}
                />
            </div>
        </>
    );
};

export default SlideViewer;
