import { Box, Modal, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

function ImageUploadError({ isModalOpen, cancelImage, textError }: any) {
    return (
        <Modal
            open={isModalOpen}
            onClose={() => cancelImage()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="error_modal"
        >
            <Box className="inner_box">
                <div className="media error_media">
                    <div className="media-icon" onClick={() => cancelImage()}>
                        <CancelIcon />
                    </div>
                    <div className="media_content">
                        <Typography variant="body1">{textError}</Typography>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default ImageUploadError;
