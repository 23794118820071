import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

import { joinClass } from './actions/signalr.actions';
import { localStorageService } from './services/localStorageService';
import { mapParticipantJoinInput } from './helpers/userHelper';
import { StoreInterface } from './interfaces/store.interface';
import { ActionTypes } from './constants/action-types.enum';

import Pages from './Router/Pages';
import PointOverlayTickLottie from './components/Common/pointOverlay/PointOverlayTickLottie';

function App() {
    const dispatch = useDispatch();
    const { ready } = useTranslation();
    const screenSize = useRef<any>();

    const store = useSelector((state: StoreInterface) => state);
    const participant = store.participant;
    const currentClass = store.currentClass;

    const gtmId = process.env.REACT_APP_GTM_ID || '';
    const tagManagerArgs: TagManagerArgs = {
        gtmId,
    };

    useEffect(() => {
        var root = document.getElementsByTagName('html')[0];
        const lang = participant.language;
        const rtlValue = localStorage.getItem('rtl');
        if (lang === 'ar' || rtlValue === 'true') {
            root.setAttribute('class', 'rtl');
            root.setAttribute('dir', 'rtl');
        } else {
            root.setAttribute('class', 'ltr');
            root.setAttribute('dir', 'ltr');
        }
    }, [participant]);

    useEffect(() => {
        localStorageService.setStoreData(store);
    }, [store]);

    useEffect(() => {
        if (participant.presenterEmail) Sentry.setUser({ email: participant.presenterEmail });
        else Sentry.setUser({ ip_address: '{{auto}}' });
    }, [participant]);

    useEffect(() => {
        TagManager.initialize(tagManagerArgs);

        if (
            participant.cpcsRegion &&
            participant.presenterEmail &&
            participant.participantId &&
            participant.participantName &&
            participant.participantUsername &&
            currentClass
        ) {
            const participantJoinInput = mapParticipantJoinInput(participant, currentClass?.classSessionId || '');
            dispatch(joinClass(participantJoinInput));
        } else {
            dispatch({ type: ActionTypes.LEFT_CLASS });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentClass) document.title = `ClassPoint App | ${currentClass.classCode}`;
        else document.title = 'ClassPoint App | Join class';
    }, [currentClass]);

    if (ready)
        return (
            <div ref={screenSize} className="App">
                <Pages />
                <PointOverlayTickLottie screenSize={screenSize} />
            </div>
        );
    else return null;
}

export default App;
