import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Avatar } from '@mui/material';

import { getColorForString, getFirstLettersFromName } from '../../helpers/utils';
import { getSavedClassGroupProps } from '../../helpers/savedClassGroupHelper';

import { GroupInterface } from '../../interfaces/current-class.interface';
import { StoreInterface } from '../../interfaces/store.interface';
import { utilConstants } from '../../constants/utils.constants';

export enum AvatarType {
    USER,
    PARTICIPANT,
    SAVEDCLASS,
}

const CustomAvatar = ({
    avatarName,
    avatarUrl,
    type,
    savedClassGroups,
    groupId,
    style,
    className,
}: {
    avatarName: string;
    avatarUrl: string;
    type: AvatarType;
    savedClassGroups?: GroupInterface[];
    groupId?: number;
    style?: object;
    className?: string;
}) => {
    const isUserOnPremium = useSelector((state: StoreInterface) => state.presenter.isOnPremium);

    const [isImageValid, setIsImageValid] = useState(true);

    const determineBackgroundColor = (avatarName: string) => {
        if (type === AvatarType.PARTICIPANT) {
            if (!savedClassGroups?.length || !groupId?.toString()) {
                return utilConstants.DEFAULT_PARTICIPANT_COLOR;
            }

            // check if user is premium
            if (!isUserOnPremium) return utilConstants.DEFAULT_PARTICIPANT_COLOR;

            return getSavedClassGroupProps(groupId, savedClassGroups);
        } else {
            return getColorForString(avatarName);
        }
    };

    return avatarUrl && isImageValid ? (
        <Avatar
            src={avatarUrl}
            onError={() => setIsImageValid(false)}
            alt={avatarName?.toUpperCase() || ''}
            style={style}
            className={className}
            variant="circular"
        />
    ) : avatarName ? (
        <Avatar
            children={getFirstLettersFromName(avatarName.toUpperCase())}
            sx={{ background: determineBackgroundColor(avatarName) }}
            style={style}
            className={className}
            variant="circular"
        />
    ) : null;
};

export default CustomAvatar;
