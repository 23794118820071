import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import { Modal, Box, Typography, Button } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import LoadingButton from '@mui/lab/LoadingButton';
import ImageCroper from './ImageCroper';
import httpServices from '../../../../services/httpService';
import { dataURItoBlob, getBase64FromFile } from '../../../../helpers/utils';
import { participantActions } from '../../../../actions/participant.actions';
import { StoreInterface } from '../../../../interfaces/store.interface';
import SaveIcon from '@mui/icons-material/Save';

export default function AvatarEditModal({
    isAvatarEditModalOpen,
    setIsAvatarEditModalOpen,
    customAvatarImage,
    onAvatarConfirmed,
    chooseAnotherImage,
}: any) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const participant = useSelector((state: StoreInterface) => state.participant);
    const [cropData, setCropData] = useState<any>('');
    const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
    const [canPanZoom, setCanPanZoom] = useState(true);

    const handleConfirmCustomAvatar = async () => {
        setIsUploadingAvatar(true);
        let cropDataFile: any = dataURItoBlob(cropData);
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 192,
            useWebWorker: true,
        };
        const compressedFile: any = await imageCompression(cropDataFile, options);
        compressedFile.name = `${v4()}.jpg`;
        const compressedFileData: any = await getBase64FromFile(compressedFile);
        let blob = dataURItoBlob(compressedFileData);
        const imageUrl = await httpServices.uploadToStorage(blob, `${v4()}.jpg`, participant.cpcsRegion, 'user');
        if (!imageUrl) {
            setIsUploadingAvatar(false);
            dispatch(participantActions.showApiError());
            return;
        }
        onAvatarConfirmed(imageUrl);
        setIsAvatarEditModalOpen(false);
        setIsUploadingAvatar(false);
    };

    const handleResetCustomAvatar = () => {
        onAvatarConfirmed('');
        setIsAvatarEditModalOpen(false);
        setIsUploadingAvatar(false);
    };

    return (
        <Modal
            open={isAvatarEditModalOpen}
            onClose={() => setIsAvatarEditModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="camera_modal"
        >
            <Box className="camera_modal_box">
                <div className="camera_modal_inner">
                    <div className="close_modal" onClick={() => setIsAvatarEditModalOpen(false)}>
                        <CloseRoundedIcon />
                    </div>
                    <div className="short_ans_head">
                        <AccountCircleOutlinedIcon />
                        <Typography variant="h4">{t(`lang_join.txt_edit_avatar`)}</Typography>
                    </div>
                    <div className="croping_box">
                        <ImageCroper imageUrl={customAvatarImage} setCropData={setCropData} canPanZoom={canPanZoom} />
                    </div>
                    <div className="btn_camera">
                        <Button variant="text" className="default" onClick={chooseAnotherImage}>
                            {t(`lang_join.btn_change`)}
                        </Button>

                        {!isUploadingAvatar ? (
                            <Button variant="contained" onClick={handleConfirmCustomAvatar}>
                                {t(`lang_join.btn_save`)}
                            </Button>
                        ) : (
                            <LoadingButton startIcon={<SaveIcon />} loading loadingPosition="start" variant="outlined">
                                {/* {t(`lang_join.btn_uploading`)}  */}
                            </LoadingButton>
                        )}
                        <Button variant="text" className="reset" onClick={handleResetCustomAvatar}>
                            {t(`lang_join.btn_reset`)}
                        </Button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
