import { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConnectFromOtherTab from '../components/layout/ConnectFromOtherTab/ConnectFromOtherTab';
import ClassRoom from './../components/layout/ClassRoom';
import Join from '../components/layout/Join';
import { CurrentView } from '../constants/current-view.enum';
import { StoreInterface } from '../interfaces/store.interface';
import ApiError from '../components/layout/ApiError';

const Pages = () => {
    const store = useSelector((state: StoreInterface) => state);

    return (
        <Fragment>
            <Switch>
                <Route path="/:urlClassCode?">
                    <Redirect to="/" />
                    {store.currentView === CurrentView.CONNECTED_FROM_ANOTHER_TAB ? (
                        <ConnectFromOtherTab />
                    ) : store.currentClass ? (
                        <ClassRoom />
                    ) : (
                        <Join />
                    )}
                </Route>
            </Switch>
            <ApiError />
        </Fragment>
    );
};

export default Pages;
