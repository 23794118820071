import { GroupInterface } from '../interfaces/current-class.interface';
import { utilConstants } from '../constants/utils.constants';

export const getSavedClassGroupProps = (
    groupId: number | undefined,
    savedClassGroups: GroupInterface[] | undefined,
): string => {
    const group = savedClassGroups?.find((group) => group.groupId === groupId);
    return group ? decodeGroupColorHash(group.groupColor) : utilConstants.DEFAULT_PARTICIPANT_COLOR;
};

export const decodeGroupColorHash = (color: string): string => {
    const colorMap = {
        // '#FF5722': '#000000',
    };

    if (colorMap[color as keyof typeof colorMap]) return colorMap[color as keyof typeof colorMap];
    return `${color}`;
};
