const Constants = {
    JOIN_LAYOUT_BG: '#3140A0',
    APP_LAYOUT_BG: '#fff',
    HEADER_BG: '#6378ff',
    PRIMARY_BUTTON: '#6378ff',
    IMAGE_URLS: {
        brand_logo: 'assets/logo/classpointlogo.svg',
        header_logo: 'assets/logo/classpointlogo.svg',
        cct_logo2: 'assets/badge/CCTBadge.svg',
        cce_logo: 'assets/badge/CCEBadge.svg',
        csc_logo: 'assets/badge/CSCBadge.svg',

        waiting_img: 'assets/images/presentation-waiting.gif',
        canvas_bg: 'assets/images/drawing-sample.jpg',
        slideViewer_disabled: 'assets/images/audience-slideviewer-disabled.png',
        // img_expired: 'assets/images/img_expired.png',
        // clapping_hands: 'assets/logo/clapping_hands.png',
        // loudly_crying_face: 'assets/logo/loudly_crying_face.png',
        // grinning_squinting_face: 'assets/logo/grinning_squinting_face.png',
        // sparkling_heart: 'assets/logo/sparkling_heart.png',
        // thumbs_up: 'assets/logo/thumbs_up.png',
        // waving_hand: 'assets/logo/waving_hand.png',
        classfull: 'assets/logo/classfull.svg',
        guestNotAllowed: 'assets/logo/guestNotAllowed.svg',
        chaticon: 'assets/logo/message.svg',
        imageModal: 'assets/logo/image-modal.svg',
        multichoice: 'assets/logo/multichoice.svg',
        Browsefile: 'assets/images/Browsefile.svg',
        ImageUpload: 'assets/images/ImageUpload.svg',
        browserSearch: 'assets/images/browsersearch.svg',
        // browserSearchMagnify: 'assets/images/browsersearchmagnify.png',
        google: 'assets/images/powerbygoogle.svg',
        // cropLeft: 'assets/logo/crop-left.png',
        // cropRight: 'assets/logo/crop-right.png',
        frequency: 'assets/logo/frequency.svg',
        mic: 'assets/logo/mic.svg',
        audiosearch: 'assets/images/audiosearch.svg',
        audioPlay: 'assets/logo/audioPlay.svg',
        audioStop: 'assets/logo/audioStop.svg',
        waveAnimation: 'assets/animation/wave.json',
        waveanimate: 'assets/images/waveanimate.gif',
        video: 'assets/images/video.svg',
        videosearch: 'assets/images/videosearch.svg',
        drawing: 'assets/logo/drawing.svg',
        slideDrawingIcon: 'assets/logo/slideDrawingIcon.svg',
        // Vector1: 'assets/scatch/Vector1.png',
        // Vector2: 'assets/scatch/Vector2.png',
        // Vector3: 'assets/scatch/Vector3.png',
        // Vector4: 'assets/scatch/Vector4.png',
        // Vector5: 'assets/scatch/Vector5.png',
        // Vector6: 'assets/scatch/Vector6.png',
        // Vector7: 'assets/scatch/Vector7.png',
        fillblanks: 'assets/logo/fillblanks.svg',
        // star1: 'assets/images/star1.png',
        // star2: 'assets/images/star2.png',
        // star3: 'assets/images/star3.png',
        videoUpload: 'assets/logo/video_upload.svg',
        imageUpload: 'assets/logo/image-modal.svg',
        wordCloud: 'assets/logo/word-cloud.svg',
        ic_sagree: './assets/logo/ic_s-agree.svg',
        ic_sdisagree: './assets/logo/ic_s-disagree.svg',
        ic_agree: './assets/logo/ic_agree.svg',
        ic_disagree: './assets/logo/ic_disagree.svg',
        ic_neutral: './assets/logo/ic_neutral.svg',
        ic_false: './assets/logo/ic_false.svg',
        ic_true: './assets/logo/ic_true.svg',
        ic_unsure: '/assets/logo/ic_unsure.svg',
        ic_no: './assets/logo/ic_no.svg',
        ic_yes: '/assets/logo/ic_yes.svg',
        qp_image: '/assets/logo/quickpoll.png',
        badge: 'assets/images/badge.png',
        logout: '/assets/images/exit.svg',
        iconStar: '/assets/logo/iconStar.svg',
    },
    COLOR_NAMES_ARRAY: ['green', 'pink', 'blue', 'yellow', 'purple', 'orange', 'deepgreen', 'deeporange'],
    COLOR_value_ARRAY: ['#06D6A0', '#EF476F', '#2AA4E3', '#FFBA00', '#7D29C0', '#FF871E', '#049F76', '#F25240'],
    SHORT_ANSWER_COLOR_ARRAY: ['#FFF4BA', '#FFD6D6', '#E6FDCD', '#D3EDFF', '#E7DAFF'],
    HOST_URLS: {
        client: 'http://localhost:3000',
    },
    IMAGE_SEARCH_API_KEY: 'bed5bc300493986da65c559905fc19badfef555430c481783e0524e98c704de8',
};
export const HOST_NAME = 'http://23.22.25.72:86/api/v1/';

export const URI = {
    login: `${HOST_NAME}user/login`, // For Login Api
    logout: `${HOST_NAME}user/logout`,
    socketName: `http://23.22.25.72:85`,
    port: `http://23.22.25.72`,
    photo: `https://d2vwjot9ja3cm1.cloudfront.net/photos/`,
};

export default Constants;
