import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import FillBlanksShowCorrectAnswerModal from './FillBlanksShowCorrectAnswerModal';

function FillBlanksResponse() {
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const fbCorrectAnswers = activityProps.fbCorrectAnswers as string[];

    return (
        <React.Fragment>
            {activity.submittedResponses.length > 0 && (
                <div className="chat_text_group">
                    <div className="chat_text">
                        <div className="fillin_answer">
                            <ul>
                                {activity.submittedResponses.length > 0 &&
                                    JSON.parse(activity.submittedResponses[0].responseData).map(
                                        (response: any, index: number) => (
                                            <li key={index}>
                                                <div className="fiilform">
                                                    <div className="number">
                                                        <Typography variant="body1">{index + 1}</Typography>
                                                    </div>
                                                    <div className="field">
                                                        <Typography variant="body1" className="notranslate">
                                                            {response}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </li>
                                        ),
                                    )}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            {fbCorrectAnswers.length > 0 && <FillBlanksShowCorrectAnswerModal />}
        </React.Fragment>
    );
}

export default FillBlanksResponse;
