import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppBar, Box, Toolbar, Typography, Badge, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import TranslateIcon from '@mui/icons-material/Translate';

import './Header.scss';

import { StoreInterface } from '../../../../interfaces/store.interface';
import Constants from '../../../../constants';

import ChangeLanguageModal from './ChangeLanguageModal';

import { Leaveicon } from '../../../../Icon';

const Header = ({ onLeave }: any) => {
    const { t } = useTranslation();

    const store = useSelector((state: StoreInterface) => state);
    const currentClass = store.currentClass;
    const customBranding = store.presenter.customBranding;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openChangeLanguage, setOpenChangeLanguage] = useState(false);

    const openHeaderMenu = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickChangeLanguage = () => {
        handleClose();
        setOpenChangeLanguage(true);
    };

    const leaveClass = () => {
        handleClose();
        onLeave();
    };

    return (
        <>
            <AppBar position="fixed" className="clasroom_header">
                <Toolbar className="container">
                    <Box className="box_align " sx={{ display: 'flex', width: '100%' }}>
                        <Box className="logo_box">
                            {customBranding?.logoImageUrl ? (
                                <a
                                    href={customBranding.logoImageLink || '/'}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="header_logo"
                                >
                                    <img src={customBranding.logoImageUrl} alt="brand" />
                                </a>
                            ) : (
                                <Link to={'/'} className="header_logo">
                                    <img src={Constants.IMAGE_URLS.header_logo} alt="brand" />
                                    <Typography variant="caption">ClassPoint.app</Typography>
                                </Link>
                            )}
                        </Box>
                        <Box sx={{ flexGrow: 1, textAlign: 'center' }} className="header_classcode">
                            <Typography variant="h6">{t(`lang_common.txt_class_code`)}:</Typography>
                            <div className="codenumber">
                                {currentClass.classCode.split('').map((elem: any, index: any) => (
                                    <Typography key={index} variant="caption">
                                        {elem}
                                    </Typography>
                                ))}
                            </div>
                        </Box>
                        <Box sx={{}} className="hambar_box">
                            <Badge
                                className="user_badge dx_User_badge"
                                id="basic-button"
                                aria-controls={openHeaderMenu ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openHeaderMenu ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <MenuIcon />
                            </Badge>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openHeaderMenu}
                                className="nav_menu"
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={handleClickChangeLanguage}>
                                    <TranslateIcon />
                                    {t(`lang_participant_main.menu_change_language`)}
                                </MenuItem>
                                <MenuItem className="leaveclass" onClick={() => leaveClass()}>
                                    <Leaveicon />
                                    {t(`lang_participant_main.menu_leave_class`)}
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <ChangeLanguageModal
                openChangeLanguage={openChangeLanguage}
                closeChangeLanguage={() => setOpenChangeLanguage(false)}
            />
        </>
    );
};

export default Header;
