import { Button, Typography } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ActivityPropsInterface, ActivityStatusTypes } from '../../../../../../interfaces/activity.interface';
import { styled } from '@mui/material/styles';
import imageCompression from 'browser-image-compression';
import Constants from '../../../../../../constants';
import ImageUploadModal from './ImageUploadModal';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import ImageUploadError from '../../../../ImageUploadError';

export enum ModalType {
    Cropper,
    Preview,
    Search,
    List,
}

const Input = styled('input')({
    display: 'none',
});

function ImageUploadInput() {
    const { t } = useTranslation();

    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const numOfSubmissionsAllowed = activityProps.numOfSubmissionsAllowed as number;
    const submittedResponses = activity.submittedResponses;
    const [isShowAddNewButton, setIsShowAddNewButton] = useState(submittedResponses.length > 0);
    const inputfileref: any = useRef('null');
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isImageError, setIsImageError] = React.useState(false);

    const [modalType, setModalType] = React.useState<ModalType>();
    const [imageData, setImageData] = useState<any>();
    const [imgData, setImgData] = useState<any>();
    const [imageExtension, setImageExtension] = useState('');

    useEffect(() => {
        if (activity.activityStatus === ActivityStatusTypes.submitted) setIsModalOpen(false);
    }, [activity]);

    const handleImageChosen = async (event: any) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            let file = event.target.files[0];
            const isFileImage = file && file['type'].split('/')[0] === 'image';
            if (!isFileImage) {
                setIsImageError(!isImageError);
                return;
            }

            setImageExtension(file.name.split('.').pop());
            if (file.type === 'image/png' || file.type === 'image/jpeg') {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1000,
                    useWebWorker: true,
                };
                const compressedFile: any = await imageCompression(file, options);
                reader.onloadend = () => {
                    setImageData(reader.result);
                    setModalType(ModalType.Cropper);
                    setIsModalOpen(true);
                };
                reader.readAsDataURL(compressedFile);
            } else {
                setModalType(ModalType.Preview);
                reader.onloadend = () => {
                    setImageData(reader.result);
                    setIsModalOpen(true);
                };
                reader.readAsDataURL(file);
                return;
            }
        }
    };

    return (
        <>
            {modalType !== undefined && (
                <ImageUploadModal
                    isModalOpen={isModalOpen}
                    onModalClose={() => setIsModalOpen(false)}
                    modalType={modalType}
                    setModalType={setModalType}
                    imageData={imageData}
                    setImageData={setImageData}
                    imgData={imgData}
                    setImgData={setImgData}
                    inputfileref={inputfileref}
                    imageExtension={imageExtension}
                    setIsShowAddNewButton={setIsShowAddNewButton}
                />
            )}
            <ImageUploadError
                isModalOpen={isImageError}
                cancelImage={() => setIsImageError(!isImageError)}
                textError={t(`lang_common.err_choose_image_only`)}
            />
            {activity.activityStatus === ActivityStatusTypes.submitting &&
                submittedResponses.length < numOfSubmissionsAllowed && (
                    <div className="upload_buttons">
                        <div className="upload_grid">
                            {submittedResponses.length > 0 && isShowAddNewButton ? (
                                <div className="sh_btn">
                                    <Button variant="contained" onClick={() => setIsShowAddNewButton(false)}>
                                        {t(`lang_activity.btn_add_new`)}
                                    </Button>
                                </div>
                            ) : (
                                <Fragment>
                                    <div className="box_6">
                                        <Button className="box_image">
                                            <label htmlFor="icon-button-file">
                                                <Input
                                                    accept="image/png,image/jpeg,image/gif"
                                                    id="icon-button-file"
                                                    type="file"
                                                    ref={inputfileref}
                                                    onChange={handleImageChosen}
                                                />
                                                <img src={Constants.IMAGE_URLS.Browsefile} alt="msgicon" />
                                                <Typography>{t(`lang_activity.btn_choose_image`)}</Typography>
                                            </label>
                                        </Button>
                                    </div>

                                    <div className="box_6">
                                        <Button
                                            className="box_image"
                                            onClick={() => {
                                                setModalType(ModalType.Search);
                                                setIsModalOpen(true);
                                            }}
                                        >
                                            <img src={Constants.IMAGE_URLS.ImageUpload} alt="msgicon" />
                                            <Typography>{t(`lang_activity.btn_search_online`)} </Typography>
                                        </Button>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                )}
        </>
    );
}

export default ImageUploadInput;
