import React, { Fragment, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { TextField } from '@mui/material';
import ImageGrid from './ImageGrid';
import './ImageUpload.scss';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import Constants from '../../../../../../constants';
import httpServices from '../../../../../../services/httpService';
import { ModalType } from './ImageUploadInput';
import SearchIcon from '@mui/icons-material/Search';
import magnifying_glass from '../../../../../../assets/animation/magnifying_glass.json';
import Close from '@mui/icons-material/Close';
import SearchedImageInterface from '../../../../../../interfaces/searched-image.interface';

interface ImageSearchInterface {
    setSelectedOnlineImageUrl: (arg: string) => void;
    setModalType: (arg: ModalType) => void;
    isSearching: boolean;
    setIsSearching: (arg: boolean) => void;
}

function ImageSearch({ setSelectedOnlineImageUrl, setModalType, isSearching, setIsSearching }: ImageSearchInterface) {
    const { t } = useTranslation();
    const [images, setImages] = useState<SearchedImageInterface[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchError, setSearchError] = useState(false);

    const searchImage = async (e: any) => {
        e.preventDefault();
        if (!searchText.trim()) return;
        setSearchError(false);
        setImages([]);
        setIsSearching(true);
        const results = await httpServices.searchImageByKeyword(searchText.trim());
        setIsSearching(false);
        if (results.length === 0) setSearchError(true);
        setImages(results);
        setModalType(ModalType.List);
    };

    return (
        <Fragment>
            <div className="searchfield">
                <form onSubmit={searchImage}>
                    <TextField
                        fullWidth
                        placeholder={t(`lang_activity.txt_placeholder_search_image`)}
                        id="fullWidth"
                        autoComplete="off"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon onClick={searchImage} />
                                    {/* {searchText ? (
                                        <Close onClick={() => setSearchText('')} />
                                    ) : (
                                        <SearchIcon onClick={searchImage} />
                                    )} */}
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
            </div>
            <div className="searching_img">
                {images.length > 0 ? (
                    <ImageGrid images={images} setSelectedOnlineImageUrl={setSelectedOnlineImageUrl} />
                ) : (
                    <Fragment>
                        <div className="searchimages_group">
                            {searchError ? (
                                <div className="search_error">
                                    <p>Search service is busy. Please try again later. </p>
                                </div>
                            ) : (
                                <>
                                    <img src={Constants.IMAGE_URLS.browserSearch} alt="msgicon" />
                                    <div className="search_lotti">
                                        <div className="lottieContainer">
                                            {!isSearching && (
                                                <Lottie
                                                    className="staticLottie"
                                                    animationData={magnifying_glass}
                                                    goTo={100}
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                    }}
                                                />
                                            )}
                                            <Lottie
                                                className="dynamicLottie"
                                                loop
                                                animationData={magnifying_glass}
                                                play={isSearching}
                                                speed={5}
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="power_by">
                            <img src={Constants.IMAGE_URLS.google} alt="msgicon" />
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}

export default ImageSearch;
