import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LoadingButton from '@mui/lab/LoadingButton';

import Constants from '../../../constants';
import Footer from '../Footer';
import { CurrentView } from '../../../constants/current-view.enum';
import { StoreInterface } from '../../../interfaces/store.interface';

const JoinStepOne = ({
    classCode,
    classCodeErrorMessage,
    submitStepOne,
    handleKeypress,
    setClassCode,
    setClassCodeErrorMessage,
    isSubmitting,
    setIsSubmitting,
}: any) => {
    const { t } = useTranslation();

    const logo = Constants.IMAGE_URLS.brand_logo;
    const currentView = useSelector((state: StoreInterface) => state.currentView);

    useEffect(() => {
        if (currentView === CurrentView.SHOW_API_ERROR) setIsSubmitting(false);
    }, [currentView]);

    return (
        <div className="join_card_wrap">
            <div className="sign_logo">
                <img src={logo} alt="brand" height={60} />
                <h2>{t(`lang_common.app_title`)}</h2>
            </div>
            <div className="login_form">
                <TextField
                    id="standard-basic"
                    placeholder={t(`lang_common.txt_class_code`)}
                    autoComplete="none"
                    variant="standard"
                    value={classCode}
                    inputProps={{ maxLength: 8 }}
                    // onKeyDown={(e) => {
                    //     const re = /[0-9a-zA-Z_]+/g;
                    //     if (!re.test(e.key)) {
                    //         e.preventDefault();
                    //     }
                    // }}
                    onKeyPress={(e) => handleKeypress(e)}
                    onChange={(e) => {
                        setClassCode(
                            e.target.value
                                .trim()
                                .toUpperCase()
                                .replace(/[^\w\s]/gi, ''),
                        );
                        setClassCodeErrorMessage('');
                    }}
                    error={classCodeErrorMessage !== ''}
                    helperText={classCodeErrorMessage}
                    autoFocus
                />
                <div className="login_btn">
                    {isSubmitting ? (
                        <LoadingButton
                            loading
                            disabled
                            color="primary"
                            className="loading_btn"
                            component="button"
                        ></LoadingButton>
                    ) : (
                        <IconButton
                            color="primary"
                            component="button"
                            onClick={() => {
                                submitStepOne();
                            }}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default JoinStepOne;
