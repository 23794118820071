export const AzureStorages = [
    {
        isDev: true,
        region: '11',
        isTemp: true,
        storageAccountName: 'cpblobtest11',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-05T19:19:37Z&st=2022-10-05T11:19:37Z&spr=https,http&sig=sF%2FSdaR9LFtlk9U4Oh44xho3QzLEgaU35njeX5N2ay4%3D',
    },
    {
        isDev: true,
        region: '11',
        isTemp: false,
        storageAccountName: 'cpfiletest11',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-06T03:39:04Z&st=2022-10-05T19:39:04Z&spr=https,http&sig=15GQjXWU0sCmct%2FmcBbZ8Iy39lMhCNgt%2FKZVwipix2g%3D',
    },
    {
        isDev: true,
        region: '41',
        isTemp: true,
        storageAccountName: 'cpblobtest41',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-07T22:30:25Z&st=2022-10-07T14:30:25Z&spr=https,http&sig=2fgQMxezqjbICPA3gQW0Q%2FmSpQ9RaGf5pJjJ2wedfDg%3D',
    },
    {
        isDev: true,
        region: '41',
        isTemp: false,
        storageAccountName: 'cpfiletest41',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-07T22:29:30Z&st=2022-10-07T14:29:30Z&spr=https,http&sig=B%2Fc3GgD1AKjOnfc1HTJrXvk%2FQrLa9fYSKkXJIu3OZwg%3D',
    },
    {
        isDev: false,
        region: '11',
        isTemp: true,
        storageAccountName: 'cpblob11',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-31T18:56:42Z&st=2022-10-31T10:56:42Z&spr=https&sig=yIz40%2FZSUuK4e9aNSOulvfrublVLHnZBgXRDHek4ZcU%3D',
    },
    {
        isDev: false,
        region: '11',
        isTemp: false,
        storageAccountName: 'cpfile11',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-31T19:00:45Z&st=2022-10-31T11:00:45Z&spr=https&sig=maScT%2BEXNeVJ%2BTHPq4U25KwncDk%2FoVlTYGfHxxHX9pU%3D',
    },
    {
        isDev: false,
        region: '21',
        isTemp: true,
        storageAccountName: 'cpblob21',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-31T18:58:42Z&st=2022-10-31T10:58:42Z&spr=https&sig=XjTHzjfXFVe17UxxKncB5w5mAs5ltJ8cCEJbbpLegAg%3D',
    },
    {
        isDev: false,
        region: '21',
        isTemp: false,
        storageAccountName: 'cpfile21',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-31T19:01:14Z&st=2022-10-31T11:01:14Z&spr=https&sig=82udZC9RK%2F1%2BMqu7tAkwnqZQYeFGdOy1jiXLmEicrVw%3D',
    },
    {
        isDev: false,
        region: '31',
        isTemp: true,
        storageAccountName: 'cpblob31',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-31T18:59:54Z&st=2022-10-31T10:59:54Z&spr=https&sig=kzGN8bufdNl7REpBcA3oYw1avbY2uD6PneKaoNBY1Z0%3D',
    },
    {
        isDev: false,
        region: '31',
        isTemp: false,
        storageAccountName: 'cpfile31',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-31T19:01:40Z&st=2022-10-31T11:01:40Z&spr=https&sig=uCd9wvOsd1uuPHgQOzq1HW3Lfij%2BRtRozmuNplRNkjU%3D',
    },
    {
        isDev: false,
        region: '41',
        isTemp: true,
        storageAccountName: 'cpblob41',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-31T19:00:21Z&st=2022-10-31T11:00:21Z&spr=https&sig=PjkqtjY8bHGIcUyVLHeC%2FvLnffXSQrS7XQ6Qy8f0z0o%3D',
    },
    {
        isDev: false,
        region: '41',
        isTemp: false,
        storageAccountName: 'cpfile41',
        sasToken:
            'sv=2021-06-08&ss=b&srt=o&sp=w&se=9999-10-31T19:02:03Z&st=2022-10-31T11:02:03Z&spr=https&sig=bg6uD%2BGp5bSvSwNMEvJ%2FKmXRgNGwiHgwu%2BUHArt7Frw%3D',
    },
];
