export enum ActionTypes {
    LOAD_CACHED_PARTICIPANT = 'LOAD_CACHED_PARTICIPANT',
    CLASS_CODE_VALIDATED = 'CLASS_CODE_VALIDATED',
    SAVED_PARTICIPANTS_LOADED = 'SAVED_PARTICIPANTS_LOADED',
    UPDATE_CLASS_CODE = 'UPDATE_CLASS_CODE',
    UPDATE_USERNAME_AND_AVATAR = 'UPDATE_USERNAME_AND_AVATAR',
    SIGNALR_CONNECTED = 'SIGNALR_CONNECTED',
    JOINED_CLASS = 'JOINED_CLASS',
    JOINED_CLASS_SYNC_ACTIVITY = 'JOINED_CLASS_SYNC_ACTIVITY',
    CLASS_LOCKED = 'CLASS_LOCKED',
    CLASS_FULL = 'CLASS_FULL',
    NOT_ALLOW_GUESTS = 'NOT_ALLOW_GUESTS',
    NAME_TAKEN = 'NAME_TAKEN',
    NEW_SLIDESHOW = 'NEW_SLIDESHOW',
    SLIDE_CHANGED = 'SLIDE_CHANGED',
    SLIDESHOW_ENDED = 'SLIDESHOW_ENDED',
    LEFT_CLASS = 'LEFT_CLASS',
    UPDATE_PRESENTER_INFO = 'UPDATE_PRESENTER_INFO',
    CONNECTED_FROM_ANOTHER_TAB = 'CONNECTED_FROM_ANOTHER_TAB',
    BACK_TO_JOIN_STEP_TWO = 'BACK_TO_JOIN_STEP_TWO',
    START_ACTIVTY = 'START_ACTIVTY',
    RESPONSE_SUBMITTED = 'RESPONSE_SUBMITTED',
    CLOSE_SUBMISSION = 'CLOSE_SUBMISSION',
    END_ACTIVITY = 'END_ACTIVITY',
    SELECT_ASSISTANT = 'SELECT_ASSISTANT',
    SHOW_CORRECT_ANSWER = 'SHOW_CORRECT_ANSWER',
    VOTING_STARTED = 'VOTING_STARTED',
    STARRED_RESPONSE = 'STARRED_RESPONSE',
    RESPONSE_DELETED = 'RESPONSE_DELETED',
    SUBMIT_VOTE = 'SUBMIT_VOTE',
    VOTING_RESPONSES_UPDATED = 'VOTING_RESPONSES_UPDATED',
    VOTING_STOPPED = 'VOTING_STOPPED',
    LOAD_LEADERBOARD = 'LOAD_LEADERBOARD',
    DISMISS_LEADERBOARD = 'DISMISS_LEADERBOARD',
    GOT_POINTS = 'GOT_POINTS',
    POINTS_ANIMATION_COMPLETED = 'POINTS_ANIMATION_COMPLETED',
    PARTICIPANT_UPDATED_BY_CLASSSESSION_APP = 'PARTICIPANT_UPDATED_BY_CLASSSESSION_APP',
    HIDE_CORRECT_ANSWER = 'HIDE_CORRECT_ANSWER',
    OPEN_EXPORTED_PDF = 'OPEN_EXPORTED_PDF',
    CLOSE_EXPORTED_PDF = 'CLOSE_EXPORTED_PDF',
    SHOW_API_ERROR = 'SHOW_API_ERROR',
    DISMISS_API_ERROR = 'DISMISS_API_ERROR',
    CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
    QNA_DATA_UPDATED = 'QNA_DATA_UPDATED',
}
