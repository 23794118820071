import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { ActivityTypes } from '../../../../../../constants/activity-types.enum';
import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';

function WordCloudResponse() {
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;

    return (
        <React.Fragment>
            <div className="chat_text_group">
                {activityProps.activityType === ActivityTypes.WORD_CLOUD &&
                    activity.submittedResponses.map((response, index: number) => (
                        <div className="chat_text word_cloud" key={index}>
                            <Typography variant="h5" className="notranslate">
                                {response.responseData}
                            </Typography>
                        </div>
                    ))}
            </div>
        </React.Fragment>
    );
}

export default WordCloudResponse;
