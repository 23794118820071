import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Button, Modal } from '@mui/material';

import styles from './QnaModal.module.scss';

import { qnaSortByVotesThenByCreated } from '../../../../../../../helpers/utils';

import { StoreInterface } from '../../../../../../../interfaces/store.interface';
import { QnaInterface } from '../../../../../../../interfaces/qna.interface';

import SingleQuestion from '../singleQuestion/SingleQuestion';
import QnaHeader from '../QnaHeader/QnaHeader';
import QnaAskQuestion from '../QnaAskQuestion/QnaAskQuestion';

interface QnaModalInterface {
    qnaModalView: 'view' | 'ask' | undefined;
    qnaModalViewHandler: (view: 'view' | 'ask' | undefined) => void;
}

const QnaModal = ({ qnaModalView, qnaModalViewHandler }: QnaModalInterface) => {
    const { t } = useTranslation();
    const qnaData = useSelector((state: StoreInterface) => state.qnaData);

    const [qnas, setQnas] = useState(qnaData.qnas);
    const [canCloseModal, setCanCloseModal] = useState(true);

    const refreshQnaState = () => {
        const sortedQuestions = qnaSortByVotesThenByCreated(qnas);
        setQnas(sortedQuestions);
    };

    const updateQuestions = () => {
        const questionIds = qnas.map((qna) => qna.id);
        const existingQuestion: QnaInterface[] = [];
        const newQuestions: QnaInterface[] = [];

        questionIds.forEach((id) => qnaData.qnas.find((qna) => qna.id === id && existingQuestion.push(qna)));
        qnaData.qnas.forEach((qna) => !questionIds.includes(qna.id) && newQuestions.push(qna));

        setQnas([...existingQuestion, ...newQuestions]);
    };

    useEffect(() => {
        updateQuestions();
    }, [qnaData.qnas]);

    useEffect(() => {
        refreshQnaState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qnaModalView]);

    return (
        <Modal open={qnaModalView ? true : false} onClose={() => {}} className={styles.modal_qna}>
            <Box className={styles.modal_content}>
                <QnaHeader qnaModalViewHandler={qnaModalViewHandler} canCloseModal={canCloseModal} />

                <div className={`${styles.modal_body}`}>
                    {qnas.length && qnaModalView === 'view' ? (
                        <>
                            <div className={styles.question_list}>
                                {qnas.map((qna) => (
                                    <SingleQuestion key={qna.id} data={qna} />
                                ))}
                            </div>
                            <div className={styles.btn_group}>
                                <Button
                                    onClick={() => qnaModalViewHandler('ask')}
                                    className={styles.prime_btn}
                                    variant="contained"
                                >
                                    {t('lang_qna.ask_question')}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <QnaAskQuestion
                            qnasLength={qnas.length}
                            qnaModalViewHandler={qnaModalViewHandler}
                            canCloseModal={canCloseModal}
                            setCanCloseModal={setCanCloseModal}
                        />
                    )}
                </div>
            </Box>
        </Modal>
    );
};

export default QnaModal;
