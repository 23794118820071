import { localStorageService } from '../services/localStorageService';
import { ActionTypes } from '../constants/action-types.enum';
import CurrentClassInterface from '../interfaces/current-class.interface';

const CurrentClassReducer = (
    state = getInitialState(),
    action: { type: string; payload: any },
): CurrentClassInterface | null => {
    switch (action.type) {
        case ActionTypes.JOINED_CLASS:
        case ActionTypes.NEW_SLIDESHOW:
        case ActionTypes.SLIDE_CHANGED:
        case ActionTypes.SLIDESHOW_ENDED:
        case ActionTypes.QNA_DATA_UPDATED:
            return action.payload;
        case ActionTypes.LEFT_CLASS:
            return null;
        default:
            return state;
    }
};

export default CurrentClassReducer;

const getInitialState = () => {
    const cachedStore = localStorageService.getStoreData();
    if (cachedStore && cachedStore.currentClass) return cachedStore.currentClass;
    return null;
};
