import { ActivityPropsInterface } from '../interfaces/activity.interface';
import { generatePresenterInstructionMessages } from '../components/layout/ClassRoom/Main/SidePanel/PresenterMessages/PresenterInstructionMessages';
import { PresenterReplyMessages } from '../components/layout/ClassRoom/Main/SidePanel/PresenterMessages/PresenterReplyMessages';
import { store } from './store';

//LANGUAGE CODES
// https://www.andiamo.co.uk/resources/iso-language-codes/
// https://www.alchemysoftware.com/livedocs/ezscript/Topics/Catalyst/Language.htm

export enum InterfaceLangs {
    ar = 'ar',
    zh = 'zh',
    de = 'de',
    en = 'en',
    es = 'es',
    fr = 'fr',
    hi = 'hi',
    ja = 'ja',
    id = 'id',
    ms = 'ms',
    fil = 'fil',
    pt = 'pt',
    ru = 'ru',
    th = 'th',
    tw = 'tw',
    vi = 'vi',
}

export const findPresenterInstructionPool = (activityProps: ActivityPropsInterface) => {
    const currentLang = store.getState().participant.language as InterfaceLangs;
    return generatePresenterInstructionMessages(activityProps)[currentLang];
};

export const findPresenterReplyPool = () => {
    const currentLang = store.getState().participant.language as InterfaceLangs;
    return PresenterReplyMessages[currentLang];
};

export const determineDefaultLanguage = () => {
    const i18nextLang = localStorage.getItem('i18nextLng') || 'en';
    const prefixLang = i18nextLang.split('-')[0]; // zh-HK => zh
    const interfaceLangs = Object.values(InterfaceLangs) as string[];
    if (interfaceLangs.includes(prefixLang)) return prefixLang;
    else return 'en';
};
