import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Divider, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './Voting.scss';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { activityActions } from '../../../../../actions/activity.actions';
import { useDispatch, useSelector } from 'react-redux';
import VotingShortAnswer from './VotingShortAnswer';
import VotingImageUpload from './VotingImageUpload';
import VotingSlideDrawing from './VotingSlideDrawing';
import VotingVideoUpload from './VotingVideoUpload';
import VotingAudio from './VotingAudio';
import _ from 'underscore';
import { ActivityTypes } from '../../../../../constants/activity-types.enum';

const VotingModal = ({ open, handleClose, timerTime }: any) => {
    const participant = useSelector((state: any) => state.participant);
    const activity = useSelector((state: any) => state.activity);
    const [numOfVotesLeft, setNumOfVotesLeft] = useState(0);

    useEffect(() => {
        setNumOfVotesLeft(activity.votingNumber - calculateInitialVotesLeft());
    }, []);

    const calculateInitialVotesLeft = () => {
        // if we don't do this then once page is refreshed, votes left will be wrong
        const voterParticipantIds2dArray = activity.votingResponses.map((r: any) => r.voterParticipantIds);
        const voterParticipantIdsProcessed = _.flatten(voterParticipantIds2dArray);
        const votesGiven = voterParticipantIdsProcessed.filter((p: any) => p === participant.participantId).length;
        return votesGiven;
    };

    const dispatch = useDispatch();

    const handleVote = (responseId: string, isVote: boolean) => {
        isVote
            ? setNumOfVotesLeft((numOfVotesLeft) => numOfVotesLeft - 1)
            : setNumOfVotesLeft((numOfVotesLeft) => numOfVotesLeft + 1);

        dispatch(activityActions.voteResponse(responseId, isVote));
    };

    return (
        <Fragment>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="voting_modal"
            >
                <Box className="short_modalinner">
                    <div className="card_head">
                        <div className="card_head_left">
                            <Typography variant="h4">
                                <FavoriteBorderIcon />
                                Let’s vote!
                            </Typography>
                        </div>
                        <div className="card_head_right">
                            <Typography variant="h5">Votes left:</Typography>
                            <Typography variant="h6">
                                <FavoriteIcon className={numOfVotesLeft > 0 ? 'red' : ''} /> x {numOfVotesLeft}
                            </Typography>
                        </div>
                        <div className="close_modal" onClick={handleClose}>
                            <CloseRoundedIcon />
                        </div>
                    </div>

                    {activity.activityProps.activityType === ActivityTypes.SHORT_ANSWER && (
                        <VotingShortAnswer numOfVotesLeft={numOfVotesLeft} handleVote={handleVote} />
                    )}

                    {activity.activityProps.activityType === ActivityTypes.SLIDE_DRAWING && (
                        <VotingSlideDrawing numOfVotesLeft={numOfVotesLeft} handleVote={handleVote} />
                    )}

                    {activity.activityProps.activityType === ActivityTypes.IMAGE_UPLOAD && (
                        <VotingImageUpload numOfVotesLeft={numOfVotesLeft} handleVote={handleVote} />
                    )}

                    {activity.activityProps.activityType === ActivityTypes.AUDIO_RECORD && (
                        <VotingAudio numOfVotesLeft={numOfVotesLeft} handleVote={handleVote} timerTime={timerTime} />
                    )}

                    {activity.activityProps.activityType === ActivityTypes.VIDEO_UPLOAD && (
                        <VotingVideoUpload numOfVotesLeft={numOfVotesLeft} handleVote={handleVote} />
                    )}
                </Box>
            </Modal>
        </Fragment>
    );
};

export default VotingModal;
