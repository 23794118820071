const getStoreData = () => {
    return JSON.parse(localStorage.getItem('store')!);
};

const setStoreData = (value: any) => {
    localStorage.setItem('store', JSON.stringify(value));
};

export const localStorageService = {
    getStoreData,
    setStoreData,
};
