import React, { useEffect, useRef, useState } from 'react';
import { Cropper, ReactCropperElement } from 'react-cropper';

function ImageCroper({ imageUrl, setCropData, canPanZoom }: any) {
    const imageRef = useRef<ReactCropperElement>(null);
    const [cropper, setCropper] = useState<any>();
    const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => setIsImageLoaded(true);
    }, []);

    const hasAlpha = (context: any, canvas: any) => {
        var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
            hasAlphaPixels = false;
        for (var i = 3, n = data.length; i < n; i += 4) {
            if (data[i] && data[i] < 255) {
                hasAlphaPixels = true;
                break;
            }
        }
        return hasAlphaPixels;
    };

    function debounce(timeout = 300) {
        let timer: any;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                onCrop();
            }, timeout);
        };
    }

    const onCrop = () => {
        const imageElement: any = imageRef?.current;
        const cropper: any = imageElement?.cropper;
        const canvas1: any = document.createElement('canvas');
        const canvas: any = cropper.getCroppedCanvas();
        let ctx: any = canvas.getContext('2d');

        let isImageTransparent = hasAlpha(ctx, canvas);
        let dataURL = '';
        if (!isImageTransparent) {
            dataURL = canvas.toDataURL();
            setCropData(cropper.getCroppedCanvas().toDataURL());
        } else {
            canvas1.width = canvas.width;
            canvas1.height = canvas.height;
            let ctx1: any = canvas1.getContext('2d');
            ctx1.fillStyle = 'black';
            ctx1.fillRect(0, 0, canvas.width, canvas.height);

            let canvas3: any = document.createElement('canvas');
            let ctx3 = canvas3.getContext('2d');
            canvas3.width = canvas.width;
            canvas3.height = canvas.height;
            ctx3.drawImage(canvas1, 0, 0);
            ctx3.drawImage(canvas, 0, 0);
            dataURL = canvas3.toDataURL();
            setCropData(dataURL);
        }
    };

    return (
        <React.Fragment>
            {isImageLoaded && (
                <Cropper
                    style={{ height: '100%', width: '100%' }}
                    ref={imageRef}
                    aspectRatio={4 / 4}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={imageUrl}
                    viewMode={1}
                    // rotatable={true}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    crop={() => debounce()()}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    guides={false}
                    movable={canPanZoom}
                    zoomable={true}
                    zoomOnTouch={true}
                    rotatable={false}
                    scalable={false}
                    cropBoxMovable={!canPanZoom}
                    cropBoxResizable={!canPanZoom}
                    dragMode={canPanZoom ? 'move' : 'crop'}
                />
            )}
        </React.Fragment>
    );
}

export default ImageCroper;
