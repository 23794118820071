import * as Sentry from '@sentry/react';

export enum SentryTypes {
    API_ERROR = 'API_ERROR',
    SIGNALR_ERROR = 'SIGNALR_ERROR',
    COMPONENT_ERROR = 'COMPONENT_ERROR',
}

export const sentryLogApiError = (
    error: any,
    transactionName: string,
    context?: { query?: any; params?: any; body?: any },
) => {
    Sentry.configureScope((scope) => scope.setTransactionName(transactionName));
    Sentry.setTags({
        category: SentryTypes.API_ERROR,
        statusCode: error.response?.status || undefined,
    });
    Sentry.setContext('query', context?.query);
    Sentry.setContext('params', context?.params);
    Sentry.setContext('body', context?.body);
    if (error.response) Sentry.setContext('responseData', error.response.data);
    Sentry.captureException(error);
};

export const sentryLogSignalrError = (error: any, transactionName: string, payload: any) => {
    Sentry.configureScope((scope) => scope.setTransactionName(transactionName));
    Sentry.setTags({
        category: SentryTypes.SIGNALR_ERROR,
    });
    Sentry.setContext('payload', payload);
    Sentry.captureException(error);
};

export const sentryLogComponentError = (error: any, transactionName: string, payload: any) => {
    Sentry.configureScope((scope) => scope.setTransactionName(transactionName));
    Sentry.setTags({
        category: SentryTypes.COMPONENT_ERROR,
    });
    Sentry.setContext('payload', payload);
    Sentry.captureException(error);
};
