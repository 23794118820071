import { ParticipantInterface } from './../interfaces/participant.interface';
import { DefaultLevelsArray } from '../constants/participant-levels.constants';
import PresenterInterface from '../interfaces/presenter.interface';
import { ParticipantJoinInputInterface } from '../dtos/participant-join-input.dto';

export const calculateParticipantLevelAndBadge = (participantSumPoints: number, levelsArray: number[]) => {
    if (!levelsArray) levelsArray = DefaultLevelsArray;
    for (let index = 0; index < levelsArray.length; index++) {
        if (participantSumPoints < levelsArray[index]) {
            return {
                level: index,
                badgeUrl: `/assets/levels/level_${index}.svg`,
            };
        }
    }
    return {
        level: levelsArray.length,
        badgeUrl: `/assets/levels/level_${levelsArray.length}.svg`,
    };
};

export const getLevelsArray = (presenter: PresenterInterface): number[] => {
    const levelsArray = presenter.customizedLevels
        ? presenter.customizedLevels.split(',').map((level) => parseInt(level))
        : DefaultLevelsArray;
    return levelsArray;
};

export const percentCalculator = (point: number, levelsArray: number[]) => {
    if (point === 0 || point === undefined || point === null) {
        return 0;
    } else if (point > 0 && point < levelsArray[1]) {
        return (point / levelsArray[1]) * 100;
    } else if (point >= levelsArray[levelsArray.length - 1]) {
        return 100;
    } else {
        for (let index = 1; index < levelsArray.length; index++) {
            if (point >= levelsArray[index - 1] && point < levelsArray[index]) {
                return ((point - levelsArray[index - 1]) / (levelsArray[index] - levelsArray[index - 1])) * 100;
            }
        }
    }
};

export const mapParticipantJoinInput = (
    participant: ParticipantInterface,
    classSessionId: string,
): ParticipantJoinInputInterface => {
    return {
        participantId: participant.participantId,
        participantUsername: participant.participantUsername,
        participantName: participant.participantName,
        participantAvatar: participant.participantAvatar,
        presenterEmail: participant.presenterEmail,
        cpcsRegion: participant.cpcsRegion,
        classSessionId,
    };
};
