import { useSelector } from 'react-redux';

import { StoreInterface } from '../../../../../../interfaces/store.interface';

import CustomAvatar, { AvatarType } from '../../../../../Common/CustomAvatar';
import Delayed from '../delayed/Delayed';

function PresenterReply() {
    const { activity, presenter } = useSelector((state: StoreInterface) => state);

    return (
        <>
            <Delayed>
                <div className="type_ans_box">
                    <CustomAvatar
                        avatarName={`${presenter.firstName} ${presenter.lastName}`}
                        avatarUrl={presenter.avatarUrl}
                        type={AvatarType.USER}
                    />
                    <div className="chat_text notranslate">{activity.presenterReply}</div>
                </div>
            </Delayed>
        </>
    );
}

export default PresenterReply;
