import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Button, Modal, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { checkIfFillBlanksIsAllCorrect, checkIfSingleBlankIsCorrect } from '../../../../../../helpers/activityHelpers';

import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import { CurrentView } from '../../../../../../constants/current-view.enum';

import CustomAvatar, { AvatarType } from '../../../../../Common/CustomAvatar';

function FillBlanksShowCorrectAnswerModal() {
    const { t } = useTranslation();
    const { activity, presenter, currentView } = useSelector((state: StoreInterface) => state);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const fbCorrectAnswers = activityProps.fbCorrectAnswers as string[];
    const fbNumOfBlanks = activityProps.fbNumOfBlanks as number;
    const responseData = activity.submittedResponses[0].responseData;
    const submittedAnswers: string[] = JSON.parse(responseData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    useEffect(() => {
        if (currentView === CurrentView.SHOW_CORRECT_ANSWER && !hasOpened) {
            setIsModalOpen(true);
            setHasOpened(true);
        } else setIsModalOpen(false);
    }, [currentView]);

    const generateCorrectIncorrectMessage = () => {
        if (checkIfFillBlanksIsAllCorrect(fbCorrectAnswers, responseData)) {
            return fbNumOfBlanks > 1
                ? t('lang_activity.txt_answers_are_correct')
                : t('lang_activity.txt_answer_is_correct');
        } else
            return fbNumOfBlanks > 1
                ? t('lang_activity.txt_answers_are_incorrect')
                : t('lang_activity.txt_answer_is_incorrect');
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fragment>
                <Box className="correctAnsModal">
                    <div className="instraction_head">
                        <div className="type_ans_box">
                            <CustomAvatar
                                avatarName={`${presenter.firstName} ${presenter.lastName}`}
                                avatarUrl={presenter.avatarUrl}
                                type={AvatarType.USER}
                            />
                            <div className="chat_text">
                                <Typography variant="h4">{generateCorrectIncorrectMessage()}</Typography>
                            </div>
                        </div>
                    </div>
                    <div className="correctAnsContent">
                        <ul>
                            {submittedAnswers.map((answer, index) => {
                                return (
                                    <li key={index}>
                                        <div
                                            className={`corectbox ${
                                                checkIfSingleBlankIsCorrect(fbCorrectAnswers[index], answer)
                                                    ? `right`
                                                    : `wrong`
                                            }`}
                                        >
                                            {checkIfSingleBlankIsCorrect(fbCorrectAnswers[index], answer) ? (
                                                <DoneIcon />
                                            ) : (
                                                <CloseIcon />
                                            )}
                                        </div>
                                        <Typography variant="body1">{answer}</Typography>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="foot_btn">
                        <Button variant="contained" onClick={() => setIsModalOpen(false)}>
                            {t('lang_common.btn_ok')}
                        </Button>
                    </div>
                </Box>
            </Fragment>
        </Modal>
    );
}

export default FillBlanksShowCorrectAnswerModal;
