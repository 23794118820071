import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import { StoreInterface } from '../../../../../../interfaces/store.interface';

function ShortAnswerResponse() {
    const activity = useSelector((state: StoreInterface) => state.activity);

    return (
        <div className="chat_text_group">
            {activity.submittedResponses.map((response, index) => (
                <div className="chat_text mali" key={index}>
                    <Typography variant="h5" className="notranslate">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: response.responseData,
                            }}
                        ></div>
                    </Typography>
                </div>
            ))}
        </div>
    );
}

export default ShortAnswerResponse;
