import { Fragment, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { leaveClass, leaveClassKeepingState } from '../../../actions/signalr.actions';
import { participantActions } from '../../../actions/participant.actions';

import { StoreInterface } from '../../../interfaces/store.interface';

import Header from './Header';
import Main from './Main';
import Footer from '../Footer';
import Leaderboard from './Main/Leaderboard/Leaderboard';
import Qna from './Main/Qna/Qna';

const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

const ClassRoom = () => {
    const store = useSelector((state: StoreInterface) => state);
    const codeInQuery = useQuery().get('code');

    const customBrand = store.presenter.customBranding;
    if (codeInQuery) localStorage.setItem('cachedCodeInQuery', codeInQuery);

    const handleBeforeUnload = (event: any) => {
        leaveClassKeepingState();
    };

    const backGroundChange = () => {
        if (!customBrand) return;
        if (customBrand.backgroundImageUrl) return { backgroundImage: `url("${customBrand.backgroundImageUrl}")` };
        if (customBrand.backgroundColor) return { background: customBrand.backgroundColor };
    };

    useEffect(() => {
        const cachedCodeInQuery = localStorage.getItem('cachedCodeInQuery');
        if (
            cachedCodeInQuery &&
            store.connection &&
            cachedCodeInQuery.trim() !== store.participant.classCode &&
            store.presenter
        ) {
            participantActions.leaveCurrentAndUpdateClassCode(cachedCodeInQuery.trim().toUpperCase());
            localStorage.removeItem('cachedCodeInQuery');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.presenter]);

    useEffect(() => {
        // this will be fired when refreshing the page too
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <Fragment>
            <div className="classroom" style={backGroundChange()}>
                {store.leaderboard && <Leaderboard />}
                <Header onLeave={leaveClass} />
                <Main />
                <Footer />
                {store.presenter?.enableQna && <Qna />}
            </div>
        </Fragment>
    );
};

export default ClassRoom;
