import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ActivityPropsInterface, ActivityStatusTypes } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import QuickPollModal from './QuickPollModal';

function QuickPollInput() {
    const { t } = useTranslation();

    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const submittedResponses = activity.submittedResponses;
    const [isQuickPollModalOpen, setIsQuickPollModalOpen] = useState(activity.submittedResponses.length === 0);

    useEffect(() => {
        if (activity.submittedResponses.length === 0) setIsQuickPollModalOpen(true);
    }, [activityProps.activityId]);

    useEffect(() => {
        if (activity.activityStatus === ActivityStatusTypes.submitted) setIsQuickPollModalOpen(false);
    }, [activity.activityStatus]);

    return (
        <>
            {activity.activityStatus === ActivityStatusTypes.submitting && submittedResponses.length === 0 && (
                <>
                    <div className="multichoice_form">
                        <div className="sh_btn">
                            <Button variant="contained" onClick={() => setIsQuickPollModalOpen(true)}>
                                {t(`lang_activity.btn_open_quick_poll`)}
                            </Button>
                        </div>
                    </div>
                </>
            )}
            <QuickPollModal open={isQuickPollModalOpen} handleClose={() => setIsQuickPollModalOpen(false)} />
        </>
    );
}

export default QuickPollInput;
