import { ActionTypes } from '../constants/action-types.enum';
import { CurrentView } from '../constants/current-view.enum';
import { localStorageService } from '../services/localStorageService';

const CurrentViewReducer = (state = getInitialState(), action: { type: string; payload: any }): CurrentView => {
    switch (action.type) {
        case ActionTypes.BACK_TO_JOIN_STEP_TWO:
        case ActionTypes.JOINED_CLASS:
        case ActionTypes.NEW_SLIDESHOW:
        case ActionTypes.LEFT_CLASS:
        case ActionTypes.START_ACTIVTY:
        case ActionTypes.HIDE_CORRECT_ANSWER:
        case ActionTypes.DISMISS_API_ERROR:
            return CurrentView.NORMAL;
        case ActionTypes.NOT_ALLOW_GUESTS:
            return CurrentView.NOT_ALLOW_GUESTS;
        case ActionTypes.NAME_TAKEN:
            return CurrentView.NAME_TAKEN;
        case ActionTypes.CLASS_LOCKED:
            return CurrentView.CLASS_LOCKED;
        case ActionTypes.CLASS_FULL:
            return CurrentView.CLASS_FULL;
        case ActionTypes.CONNECTED_FROM_ANOTHER_TAB:
            return CurrentView.CONNECTED_FROM_ANOTHER_TAB;
        case ActionTypes.SHOW_CORRECT_ANSWER:
            return CurrentView.SHOW_CORRECT_ANSWER;
        case ActionTypes.SHOW_API_ERROR:
            return CurrentView.SHOW_API_ERROR;
        default:
            return state;
    }
};
const getInitialState = () => {
    // const cachedStore = localStorageService.getStoreData();
    // if (cachedStore && cachedStore.currentView) return cachedStore.currentView;

    return CurrentView.NORMAL;
};

export default CurrentViewReducer;
