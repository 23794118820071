import { ActionTypes } from '../constants/action-types.enum';

const ConnectionReducer = (state = null, action: any) => {
    switch (action.type) {
        case ActionTypes.SIGNALR_CONNECTED:
            return action.payload;
        case ActionTypes.LEFT_CLASS:
        case ActionTypes.NOT_ALLOW_GUESTS:
        case ActionTypes.NAME_TAKEN:
        case ActionTypes.CLASS_LOCKED:
        case ActionTypes.CLASS_FULL:
            return null;
        default:
            return state;
    }
};

export default ConnectionReducer;
