import { determineDefaultLanguage } from './../helpers/languageHelpers';
import { ActionTypes } from '../constants/action-types.enum';
import { v4 as uuidv4 } from 'uuid';
import { localStorageService } from '../services/localStorageService';
import { ParticipantInterface } from '../interfaces/participant.interface';

const ParticipantReducer = (state = getInitialState(), action: any): ParticipantInterface => {
    switch (action.type) {
        case ActionTypes.LOAD_CACHED_PARTICIPANT:
            return action.payload;
        case ActionTypes.CLASS_CODE_VALIDATED:
        case ActionTypes.UPDATE_USERNAME_AND_AVATAR:
        case ActionTypes.UPDATE_CLASS_CODE:
            return {
                ...state,
                ...action.payload,
            };
        case ActionTypes.SAVED_PARTICIPANTS_LOADED:
            return {
                ...state,
                savedParticipantsForJoin: action.payload,
            };
        case ActionTypes.JOINED_CLASS:
            return {
                ...state,
                participantId: action.payload.participantId,
                participantName: action.payload.participantName,
                participantUsername: action.payload.participantUsername,
                isFromSavedClass: action.payload.isFromSavedClass,
                participantPoints: action.payload.participantPoints,
                participantTotalPoints: action.payload.participantTotalPoints,
                groupId: action.payload.groupId,
                savedParticipantsForJoin: [],
                presenterEmail: action.payload.presenter.email,
            };
        case ActionTypes.GOT_POINTS:
            return {
                ...state,
                participantPoints: state.participantPoints + action.payload,
                pointsBeingAdded: action.payload,
                isAddingPoints: true,
            };
        case ActionTypes.POINTS_ANIMATION_COMPLETED:
            return { ...state, pointsBeingAdded: 0, isAddingPoints: false };
        case ActionTypes.PARTICIPANT_UPDATED_BY_CLASSSESSION_APP:
            return {
                ...state,
                participantName: action.payload.participantName,
                participantUsername: action.payload.participantUsername,
                participantAvatar: action.payload.participantAvatar,
                participantTotalPoints: action.payload.participantTotalPoints,
                groupId: action.payload.groupId,
            };
        case ActionTypes.CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        case ActionTypes.LEFT_CLASS:
            return {
                ...state,
                classCode: '',
                presenterEmail: '',
                cpcsRegion: '',
                savedParticipantsForJoin: [],
                participantId: '',
                participantPoints: 0,
                participantTotalPoints: 0,
                pointsBeingAdded: 0,
                language: determineDefaultLanguage(),
                groupId: undefined,
            };
        default:
            return state;
    }
};

export default ParticipantReducer;

const getInitialState = (): ParticipantInterface => {
    const cachedStore = localStorageService.getStoreData();
    if (cachedStore && cachedStore.participant && cachedStore.participant.deviceId)
        return { ...defaultParticipant, ...cachedStore.participant };
    else return defaultParticipant;
};

const defaultParticipant: ParticipantInterface = {
    deviceId: 'participant-' + uuidv4(),
    classCode: '',
    presenterEmail: '',
    cpcsRegion: '',
    savedParticipantsForJoin: [],
    participantId: '',
    participantUsername: '',
    participantName: '',
    participantAvatar: '',
    participantPoints: 0,
    participantTotalPoints: 0,
    pointsBeingAdded: 0,
    isFromSavedClass: false,
    groupId: undefined,
    language: determineDefaultLanguage(),
    isAddingPoints: false,
};
