export enum CurrentView {
    NORMAL,
    PRESENTER_PROFILE,
    NOT_ALLOW_GUESTS,
    NAME_TAKEN,
    CLASS_LOCKED,
    CLASS_FULL,
    ACTIVITY,
    VOTING,
    LEADERBOARD,
    PICKNAMES,
    CONNECTED_FROM_ANOTHER_TAB,
    SHOW_CORRECT_ANSWER,
    SHOW_API_ERROR,
}
