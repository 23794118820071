import { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Lottie from 'react-lottie-player';

import StarAnimation from '../../../assets/animation/starAnimation.json';
import { AnimationConfig } from '../../../constants/animation-configs';

export const TickLottie = ({
    index,
    xPosition,
    yPosition,
    starsRef,
    checkAndHandleAllStarsCollected,
    scale,
}: {
    index: number;
    xPosition: number;
    yPosition: number;
    starsRef: any;
    checkAndHandleAllStarsCollected: any;
    scale?: string;
}) => {
    const exitAnimation = useAnimation();
    const [playLottie, setPlayLottie] = useState(false);

    const collectedPoints = starsRef.current.filter((data: any) => data.isCollected === true);
    const gapFromLastCollected = index - collectedPoints.length;

    setTimeout(() => {
        setPlayLottie(true);
    }, gapFromLastCollected * 200);

    const onAnimationComplete = () => {
        setTimeout(() => {
            exitAnimation.start({
                scale: 0.5,
                opacity: [1, 0],
                transition: { duration: 0.5 },
            });
            starsRef.current[index].isCollected = true;
            if (index === starsRef.current.length - 1) {
                checkAndHandleAllStarsCollected();
            }
        }, AnimationConfig.TICK_LOTTIE_DURATION);
    };

    return (
        <motion.span
            className={`lottieAnimationGlobal ${index === 0 && 'center'}`}
            initial={{
                x: xPosition,
                y: yPosition,
                scale: 0.3,
                opacity: 0,
            }}
            whileInView={{
                scale: 1,
                opacity: 1,
                transition: {
                    type: 'spring',
                    stiffness: 500,
                    mass: 2.8,
                    damping: 40,
                },
            }}
            animate={exitAnimation}
        >
            <Lottie
                animationData={StarAnimation}
                play={playLottie}
                loop={false}
                speed={2}
                style={{ scale: scale ? scale : '1' }}
                onComplete={onAnimationComplete}
            />
        </motion.span>
    );
};

export default TickLottie;
