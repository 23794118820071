import { Avatar, Box, Divider, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import './VotingShortAnswer.scss';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Masonry from '@mui/lab/Masonry';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { activityActions } from '../../../../../actions/activity.actions';
import VotingShortAnswerModal from './VotingShortAnswerModal';
import Constants from '../../../../../constants';
import _ from 'underscore';

const VotingShortAnswer = ({ numOfVotesLeft, handleVote }: any) => {
    const activity = useSelector((state: any) => state.activity);
    const participant = useSelector((state: any) => state.participant);

    const [open, setOpen] = React.useState(false);
    const [choosedResponse, setChoosedResponse] = useState<any>({});
    const [choosedResponseIndex, setChoosedResponseIndex] = useState<any>();

    const votingResponses = activity.votingResponses
        ? activity.votingResponses
        : [];

    const colorArray = Constants.SHORT_ANSWER_COLOR_ARRAY;

    votingResponses.forEach((responseItem: any, index: number) => {
        responseItem.backgroundColor = colorArray[index];
    });

    const dispatch = useDispatch();

    const handleClickedImage = (data: any, dataIndex: number) => {
        setChoosedResponse(data);
        setChoosedResponseIndex(dataIndex);
        setOpen(true);
    };

    const showNextNote = (currentIndex: number) => {
        if (currentIndex >= votingResponses.length - 1) {
            setChoosedResponseIndex(currentIndex);
            setChoosedResponse(votingResponses[currentIndex]);
        } else {
            setChoosedResponseIndex(currentIndex + 1);
            setChoosedResponse(votingResponses[currentIndex + 1]);
        }
    };

    const showPreviousNote = (currentIndex: number) => {
        if (currentIndex <= 0) {
            setChoosedResponseIndex(0);
            setChoosedResponse(votingResponses[0]);
        } else {
            setChoosedResponseIndex(currentIndex - 1);
            setChoosedResponse(votingResponses[currentIndex - 1]);
        }
    };

    const handleClose = () => setOpen(false);

    return (
        <Fragment>
            {/* <div className="card_head">
                <div className="card_head_left">
                    <Typography variant="h4">
                        <FavoriteBorderIcon />
                        Let’s vote!
                    </Typography>
                </div>
                <div className="card_head_right">
                    <Typography variant="h5">Votes to give:</Typography>
                    <Typography variant="h6">
                        <FavoriteIcon
                            className={numOfVotesLeft > 0 ? 'red' : ''}
                        />{' '}
                        x {numOfVotesLeft}
                    </Typography>
                </div>
            </div> */}
            <div className="shortAnswer_card">
                <div className="short_ans_wrap">
                    <div>
                        <div className="inner_short_answer">
                            {activity.votingResponses.map(
                                (response: any, index: number) => {
                                    const isVotedByMe =
                                        response.voterParticipantIds.includes(
                                            participant.participantId,
                                        );
                                    return (
                                        <Stack
                                            key={index}
                                            className="main_content"
                                        >
                                            <div
                                                className="sh_box"
                                                style={{
                                                    order: index,
                                                    background:
                                                        response.backgroundColor,
                                                }}
                                            >
                                                <div
                                                    className="content_box"
                                                    // onClick={() =>
                                                    //     handleClickedImage(
                                                    //         response,
                                                    //         index,
                                                    //     )
                                                    // }
                                                >
                                                    <Typography variant="body1">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: response.responseData,
                                                            }}
                                                        ></div>
                                                    </Typography>
                                                </div>
                                                <div className="sh_footer">
                                                    <div className="profile">
                                                        <Avatar
                                                            alt={
                                                                participant.participantName
                                                            }
                                                            src={
                                                                participant.participantAvatar
                                                            }
                                                        />
                                                        <Typography variant="body1">
                                                            {
                                                                response.participantName
                                                            }
                                                        </Typography>
                                                    </div>
                                                    {!isVotedByMe && (
                                                        <div className="like_count">
                                                            <FavoriteBorderIcon
                                                                onClick={() => {
                                                                    if (
                                                                        numOfVotesLeft >
                                                                        0
                                                                    )
                                                                        handleVote(
                                                                            response.responseId,
                                                                            true,
                                                                        );
                                                                }}
                                                            />
                                                            <Typography variant="caption">
                                                                {
                                                                    response
                                                                        .voterParticipantIds
                                                                        .length
                                                                }
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {isVotedByMe && (
                                                        <div className="like_count">
                                                            <FavoriteIcon
                                                                className="red stroke"
                                                                onClick={() =>
                                                                    handleVote(
                                                                        response.responseId,
                                                                        false,
                                                                    )
                                                                }
                                                            />
                                                            <Typography variant="caption">
                                                                {
                                                                    response
                                                                        .voterParticipantIds
                                                                        .length
                                                                }
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Stack>
                                    );
                                },
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <VotingShortAnswerModal
                choosedResponse={choosedResponse}
                choosedResponseIndex={choosedResponseIndex}
                showPreviousNote={showPreviousNote}
                showNextNote={showNextNote}
                handleClose={handleClose}
                open={open}
                handleVote={handleVote}
            />
        </Fragment>
    );
};

export default VotingShortAnswer;
