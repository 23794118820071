import { Square, Circle, Line } from '../components/layout/ClassRoom/Main/SidePanel/SlideDrawing/components/drawIcon';

export const utilConstants = {
    FOOTER_LINK: `https://classpoint.io`,

    IMAGE: {
        ERROR_BOUNDARY: '/assets/images/errorCode.svg',
        API_ERROR: '/assets/images/apiError.svg',
        POINT_OVERLAY_STAR: '/assets/images/iconStar.svg',
    },

    IMAGE_URLS: {
        multipleWinnerAvatar: '/assets/leaderboard/multipleWinnerAvatar.svg',
        noAvatar: '/assets/leaderboard/noAvatar.svg',
        quizSummaryIconStar: '/assets/logo/iconStar.svg',
        crown: '/assets/logo/crown.svg',
    },

    MUSIC: {
        POINT_OVERLAY_CLICK: '/assets/music/retro_game.mp3',
    },

    AVATAR_COLORS: ['#f6a404', '#0c63be', '#dc3545', '#1f9436', '#d7441c', '#800080', '#b40199'],

    PENCIL_COLOR: [
        { color: '#000000' },
        { color: '#ffffff' },
        { color: '#f20000' },
        { color: '#0085ff' },
        { color: '#0cc452' },
    ],

    SHAPE_COLOR: [
        // {color: '#000000'},
        { color: '#f20000' },
        { color: '#0085ff' },
    ],

    HIGHLIGHT_COLOR: [
        { color: 'rgba(255, 236, 61)', highlightColor: 'rgba(255, 236, 61,0.3)' },
        { color: 'rgba(255, 110, 79)', highlightColor: 'rgba(255, 110, 79, 0.3)' },
        { color: 'rgba(88, 175, 255)', highlightColor: 'rgba(88, 175, 255, 0.3)' },
        { color: 'rgba(193, 92, 255)', highlightColor: 'rgba(193, 92, 255, 0.3)' },
        { color: 'rgba(255, 109, 185)', highlightColor: 'rgba(255, 109, 185, 0.3)' },
    ],

    SHAPE_RIGHT_TOOL: [
        { toolName: 'rectangle', icon: Square },
        { toolName: 'circle', icon: Circle },
        { toolName: 'line', icon: Line },
    ],

    ERASOR_SIZE_TOOL: [
        { toolName: 'sm-erasor', color: '#E6E1E5' },
        { toolName: 'md-erasor', color: '#E6E1E5' },
        { toolName: 'lg-erasor', color: '#E6E1E5' },
    ],

    WEBSITE_URL: {
        CCE: 'https://www.classpoint.io/classpoint-certified-educator',
        CSC: 'https://www.classpoint.io/classpoint-school-coach',
        CCT: 'https://www.classpoint.io/classpoint-certified-trainer',
    },

    DEFAULT_PARTICIPANT_COLOR: '#C9C5CA',
};
