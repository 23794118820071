import React from 'react';
import { leaveClass } from './actions/signalr.actions';
import { utilConstants } from './constants/utils.constants';
import { Button, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { sentryLogComponentError } from './services/sentryService';

interface IState {
    error: null;
    errorInfo: null;
}

class ErrorBoundary extends React.Component<any, IState> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
        this.sendError = this.sendError.bind(this);
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.sendError(error.toString());
        this.setState({ errorInfo: error.toString() });

        const payload = {
            error: error.toString(),
            errorInfo: errorInfo,
        };
        sentryLogComponentError(error.toString(), 'Component Error', payload);

        leaveClass();
    }

    sendError(error: string) {}

    refreshPageHandler = () => {
        window.location.reload();
    };
    render() {
        if (this.state.errorInfo && process.env.REACT_APP_ENV === 'production') {
            return (
                <div className="errorBoundary">
                    <div className="errorContent">
                        <img src={utilConstants.IMAGE.ERROR_BOUNDARY} alt="error_boundary" />
                        <Typography variant="body1">
                            {this.props.t('lang_error_boundary.txt_unexpected_error_summary1')}
                            <br />
                            {this.props.t('lang_error_boundary.txt_unexpected_error_summary2')}
                        </Typography>
                        <Button onClick={() => this.refreshPageHandler()} variant="contained">
                            {this.props.t('lang_error_boundary.btn_reload_app')}
                        </Button>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary);
