import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';

import Constants from '../../../constants';
import { CurrentView } from '../../../constants/current-view.enum';
import { StoreInterface } from '../../../interfaces/store.interface';
import Footer from '../Footer';

export default function JoinFailed({ classCode, backToStepTwo }: any) {
    const { t } = useTranslation();
    const currentView = useSelector((state: StoreInterface) => state.currentView);

    let content = null;
    switch (currentView) {
        case CurrentView.CLASS_FULL:
            content = {
                title: t('lang_join.heading_class_full'),
                titleClass: 'tred',
                image: <img src={Constants.IMAGE_URLS.classfull} alt="classfull" />,
                message: t('lang_join.txt_class_full'),
            };
            break;
        case CurrentView.CLASS_LOCKED:
            content = {
                title: t('lang_join.heading_class_locked'),
                titleClass: '',
                image: <LockTwoToneIcon className="blue" />,
                message: t('lang_join.txt_class_locked'),
            };
            break;
        case CurrentView.NOT_ALLOW_GUESTS:
            content = {
                title: t('lang_join.heading_not_allow_guests'),
                titleClass: 'torange',
                image: <img src={Constants.IMAGE_URLS.guestNotAllowed} alt="geustNotAllowed" />,
                message: t('lang_join.txt_not_allow_guests'),
            };
            break;
        default:
            return null;
    }

    return (
        <div className="join_card_wrap join_verifi">
            <div className="class_code">
                <Typography variant="caption">{t(`lang_common.txt_class_code`)}</Typography>
                <Typography variant="h1">{classCode}</Typography>
            </div>
            <div className="sign_logo">
                {content?.image}
                <Typography variant="h3" className={content.titleClass}>
                    {content?.title}
                </Typography>
                <Typography variant="body1">{content?.message}</Typography>
            </div>

            <div className="loginbtn mt_100">
                <Button variant="contained" onClick={() => backToStepTwo()}>
                    {t('lang_join.btn_back')}
                </Button>
            </div>
            <Footer />
        </div>
    );
}
