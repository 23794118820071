import { ActionTypes } from '../constants/action-types.enum';

function openExportedPdf(pdfUrl: string) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        dispatch({
            type: ActionTypes.OPEN_EXPORTED_PDF,
            payload: pdfUrl,
        });
    };
}

function closeExportedPdf() {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        dispatch({
            type: ActionTypes.CLOSE_EXPORTED_PDF,
            payload: null,
        });
    };
}

export const exportPdfActions = {
    openExportedPdf,
    closeExportedPdf,
};
