import { Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { activityActions } from '../../../../../../actions/activity.actions';
import ResponseToSubmitDto from '../../../../../../dtos/response-to-submit.dto';
import { ActivityPropsInterface, ActivityStatusTypes } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';

function FillBlanksInput() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const fbNumOfBlanks = activityProps.fbNumOfBlanks as number;
    const submittedResponses = activity.submittedResponses;
    const [responseInputs, setResponseInputs] = useState<string[]>([...Array(fbNumOfBlanks).fill('')]);
    const [errorMessage, setErrorMessage] = useState('');
    const inputEl = useRef<any>([]);

    useEffect(() => {
        setResponseInputs([...Array(fbNumOfBlanks).fill('')]);
        setErrorMessage('');
    }, [activityProps, submittedResponses]);

    const updateResponseInput = (index: number, value: string) => {
        setResponseInputs((responseInput) => {
            const updatedResponseInputs = [...responseInput];
            updatedResponseInputs[index] = value;
            if (!isAnyBlankEmpty(updatedResponseInputs)) setErrorMessage('');
            return updatedResponseInputs;
        });
    };

    function isAnyBlankEmpty(blankInputs: string[]) {
        for (const input of blankInputs) {
            if (!input.trim()) return true;
        }
        return false;
    }

    const submitFillBlanks = async () => {
        if (isAnyBlankEmpty(responseInputs)) {
            setErrorMessage(t(`lang_activity.err_please_fill_all_blanks`));
            return;
        }
        const responseToSubmit: ResponseToSubmitDto = {
            activityId: activityProps.activityId,
            activityType: activityProps.activityType,
            responseId: 'resp-' + v4(),
            responseData: JSON.stringify(responseInputs),
        };
        dispatch(activityActions.submitResponse(responseToSubmit));
    };

    const handleKeyUp = (e: any, index: number) => {
        if (e.key === 'Enter') {
            if (index < fbNumOfBlanks - 1) inputEl.current[index + 1].focus();
            else submitFillBlanks();
        }
    };

    return (
        <React.Fragment>
            <div className="fill_blanks">
                {activity.activityStatus === ActivityStatusTypes.submitting && submittedResponses.length === 0 && (
                    <React.Fragment>
                        <div className="fill_form_warp">
                            {errorMessage ? (
                                <div className="error_sec">
                                    <Typography variant="caption" className="notranslate">
                                        {errorMessage}
                                    </Typography>
                                </div>
                            ) : null}
                            <ul>
                                {[...Array(fbNumOfBlanks)].map((item, index) => (
                                    <li key={index} className={responseInputs[index].trim() ? 'active' : ''}>
                                        <div className="fiilform">
                                            <div className="number">
                                                <Typography variant="body1">{index + 1}</Typography>
                                            </div>
                                            <div className="field">
                                                <TextField
                                                    fullWidth
                                                    id="fullWidth"
                                                    variant="standard"
                                                    autoComplete="off"
                                                    inputProps={{ maxLength: 30 }}
                                                    inputRef={(el) => (inputEl.current[index] = el)}
                                                    onChange={(e: any) => updateResponseInput(index, e.target.value)}
                                                    value={responseInputs[index]}
                                                    onKeyUp={(e) => handleKeyUp(e, index)}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="sh_btn">
                            <Button variant="contained" onClick={() => submitFillBlanks()}>
                                {t(`lang_activity.btn_submit`)}
                            </Button>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

export default FillBlanksInput;
