import { Button, TextField, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SavedParticipantForJoin } from '../../../interfaces/saved-participant-for-join.interface';
import { ActionTypes } from '../../../constants/action-types.enum';
import httpService from '../../../services/httpService';
import { StoreInterface } from '../../../interfaces/store.interface';
import { getValidatedImageUrl } from '../../../helpers/utils';
import { CurrentView } from '../../../constants/current-view.enum';
import Footer from '../Footer';
import LoadingButton from '@mui/lab/LoadingButton';
import ProfileImage from '../../Common/ProfileImage';
import { participantActions } from '../../../actions/participant.actions';

interface AvatarInterface {
    id: number;
    imagePath: string;
}
interface propsInterface {
    classCode: string;
    presenterEmail: string;
    selectedAvatar: AvatarInterface;
    setSelectedAvatar: (arg0: AvatarInterface) => void;
    cpcsRegion: string;
    participantUsername: string;
    participantNameErrorMessage: string;
    presetAvatars: AvatarInterface[];
    submitStepTwo: any;
    onParticipantUsernameChange: (arg0: string) => void;
    isSubmittingStepTwo: boolean;
    setIsSubmittingStepTwo: any;
}

const JoinStepTwo = ({
    classCode,
    presenterEmail,
    selectedAvatar,
    setSelectedAvatar,
    cpcsRegion,
    participantUsername,
    participantNameErrorMessage,
    presetAvatars,
    submitStepTwo,
    onParticipantUsernameChange,
    isSubmittingStepTwo,
    setIsSubmittingStepTwo,
}: propsInterface) => {
    const dispatch = useDispatch();
    const participant = useSelector((state: StoreInterface) => state.participant);
    const currentView = useSelector((state: StoreInterface) => state.currentView);
    const savedParticipantsForJoin = participant.savedParticipantsForJoin;
    const [avatarChoices, setAvatarChoices] = useState<{ id: number; imagePath: string }[]>([...presetAvatars]);
    const [selectedAvatarIndex, setSelectedAvatarIndex] = useState<number>(0);
    const { t } = useTranslation();

    useEffect(() => {
        tryLoadCachedAvatar();
        loadSavedParticipants();
    }, []);

    const tryLoadCachedAvatar = async () => {
        if (participant.participantAvatar) {
            const validImageUrl = await getValidatedImageUrl(participant.participantAvatar);
            if (validImageUrl) updateFirstAvatar({ id: 0, imagePath: validImageUrl });
        }
    };

    const loadSavedParticipants = async () => {
        // don't call api if class code is a number
        if (!isNaN(classCode as any)) return;
        const savedParticipants: SavedParticipantForJoin[] = await httpService.getSavedParticipantsForJoin(
            cpcsRegion,
            presenterEmail,
        );
        if (savedParticipants.length > 0)
            dispatch({
                type: ActionTypes.SAVED_PARTICIPANTS_LOADED,
                payload: savedParticipants,
            });
    };

    const loadMatchingAvatar = async (participantUsername: string) => {
        const matchedParticipant = savedParticipantsForJoin.find(
            (participant) => participant.participantUsername.toLowerCase() === participantUsername.toLowerCase().trim(),
        );
        const avatarUrlToLoad = matchedParticipant
            ? matchedParticipant.participantAvatar
            : participant.participantAvatar;
        updateFirstAvatar({ id: 0, imagePath: avatarUrlToLoad });
    };

    const updateFirstAvatar = (avatar: AvatarInterface) => {
        avatarChoices[0] = {
            id: avatar.id,
            imagePath: avatar.imagePath,
        };
        setAvatarChoices([...avatarChoices]);
        if (selectedAvatarIndex === 0) {
            setSelectedAvatar({ id: avatar.id, imagePath: avatar.imagePath });
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            submitStepTwo();
        }
    };

    const handleAvatarConfirmed = (imageUrl: string) => {
        const customAvatar = { id: 0, imagePath: imageUrl };
        updateFirstAvatar(customAvatar);
        setSelectedAvatar(customAvatar);
        setSelectedAvatarIndex(0);
        dispatch(participantActions.updateUsernameAndAvatar(participantUsername, imageUrl));
    };

    useEffect(() => {
        if (currentView === CurrentView.SHOW_API_ERROR) setIsSubmittingStepTwo(false);
    }, [currentView]);

    return (
        <Fragment>
            <div className="join_card_wrap join_verifi">
                <div className="class_code">
                    <Typography variant="caption">{t(`lang_common.txt_class_code`)}</Typography>
                    <Typography variant="h1">{classCode}</Typography>
                </div>
                <div className="sign_logo">
                    <ProfileImage handleAvatarConfirmed={handleAvatarConfirmed} selectedAvatar={selectedAvatar} />
                </div>
                <div className="login_form">
                    <TextField
                        id="standard-basic"
                        placeholder={t(`lang_join.txt_your_name`)}
                        autoComplete="off"
                        variant="standard"
                        value={participantUsername}
                        inputProps={{ maxLength: 25 }}
                        onChange={(e) => {
                            onParticipantUsernameChange(e.target.value);
                            loadMatchingAvatar(e.target.value);
                        }}
                        onKeyPress={(e) => handleKeyDown(e)}
                        error={participantNameErrorMessage !== ''}
                        helperText={participantNameErrorMessage}
                        autoFocus
                    />
                </div>
                <div className="loginbtn">
                    {isSubmittingStepTwo ? (
                        <LoadingButton loading disabled color="primary" aria-label="upload picture" component="button">
                            {t(`lang_join.txt_joining`)}
                        </LoadingButton>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={() => {
                                submitStepTwo();
                            }}
                        >
                            {t(`lang_join.btn_lets_go`)}
                        </Button>
                    )}
                </div>
                <Footer />
            </div>
        </Fragment>
    );
};

export default JoinStepTwo;
