import Joi from 'joi';

const classCodeSchema = Joi.object({
    classCode: Joi.string()
        .min(3)
        .max(8)
        .required()
        .error((errors: any) => {
            errors.forEach((err: any) => {
                switch (err.code) {
                    case 'string.empty':
                        err.message = 'err_code_required';
                        break;
                    case 'string.max':
                        err.message = `err_code_invalid_or_not_in_use`;
                        break;
                    case 'string.min':
                        err.message = `err_code_invalid_or_not_in_use`;
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
});

export default classCodeSchema;
