import { Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { activityActions } from '../../../../../../actions/activity.actions';
import Constants from '../../../../../../constants';
import ResponseToSubmitDto from '../../../../../../dtos/response-to-submit.dto';
import { ActivityPropsInterface, ActivityStatusTypes } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';

function MultipleChoiceInput() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const mcChoices = activityProps.mcChoices as string[];
    const submittedResponses = activity.submittedResponses;

    const [responseInputs, setResponseInputs] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = React.useState('');
    const choicesRef = useRef<any>([]);

    useEffect(() => {
        setResponseInputs([]);
        setErrorMessage('');
    }, [activityProps]);

    const addToChoicesRef = (el: any) => {
        if (el && !choicesRef.current.includes(el)) choicesRef.current.push(el);
    };

    const selectChoice = (choice: string, target: any) => {
        if (activityProps.mcIsAllowSelectMultiple) {
            if (target.checked) setResponseInputs([...responseInputs, choice]);
            else setResponseInputs((responseInput) => responseInput.filter((c) => c !== choice));
        } else {
            choicesRef.current.forEach((input: any) => (input.checked = false));
            target.checked = true;
            setResponseInputs([choice]);
        }
    };

    const submitMultipleChoice = async () => {
        if (responseInputs.length === 0) {
            if (activityProps.mcIsAllowSelectMultiple)
                return setErrorMessage(t(`lang_activity.err_msg_please_select_at_least_one_option`));
            else return setErrorMessage(t(`lang_activity.err_msg_please_select_an_option_first`));
        }
        const responseToSubmit: ResponseToSubmitDto = {
            activityId: activityProps.activityId,
            activityType: activityProps.activityType,
            responseId: 'resp-' + v4(),
            responseData: JSON.stringify(responseInputs.sort()),
        };
        dispatch(activityActions.submitResponse(responseToSubmit));
    };

    return (
        <>
            {activity.activityStatus === ActivityStatusTypes.submitting && submittedResponses.length === 0 && (
                <>
                    <div className="multichoice_form">
                        {errorMessage && (
                            <div className="multiple_chooses_error_massage">
                                <Typography variant="caption" className="notranslate">
                                    {errorMessage}
                                </Typography>
                            </div>
                        )}
                        <div className="custom_sheck">
                            {mcChoices.map((choice, index) => (
                                <label className={Constants.COLOR_NAMES_ARRAY[mcChoices.indexOf(choice)]} key={index}>
                                    <input
                                        type="checkbox"
                                        id={choice}
                                        value={choice}
                                        ref={addToChoicesRef}
                                        onClick={(e) => {
                                            selectChoice(choice, e.target);
                                            setErrorMessage('');
                                        }}
                                    />
                                    <span>{choice}</span>
                                </label>
                            ))}
                        </div>
                        <div className="sh_btn">
                            <Button variant="contained" onClick={() => submitMultipleChoice()}>
                                {t(`lang_activity.btn_submit`)}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default MultipleChoiceInput;
