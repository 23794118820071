import { Button } from '@mui/material';
import React, { Fragment } from 'react';

const ContinueVotingButton = ({ clickfunction }: any) => {
    return (
        <Fragment>
            <Button variant="contained" onClick={clickfunction}>
                Continue voting
            </Button>
        </Fragment>
    );
};

export default ContinueVotingButton;
