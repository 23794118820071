import { useSelector } from 'react-redux';

import { Link } from '@mui/material';

import { StoreInterface } from '../../interfaces/store.interface';
import { utilConstants } from '../../constants/utils.constants';

export default function Footer() {
    const customBrand = useSelector((state: StoreInterface) => state.presenter.customBranding);

    return (
        <div
            style={{
                background:
                    customBrand && (customBrand.backgroundImageUrl || customBrand.backgroundColor) && 'transparent',
            }}
            className="login_foot"
        >
            <p className="foot_login_body">
                <Link
                    href={utilConstants.FOOTER_LINK}
                    target="_blank"
                    style={{ color: customBrand && customBrand.footerTextColor && customBrand.footerTextColor }}
                    variant="body1"
                >
                    Made with ❤️ by Inknoe
                </Link>
            </p>
        </div>
    );
}
