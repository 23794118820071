import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActivityTypes } from '../../../../../../constants/activity-types.enum';
import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import Waveform from './components/Waveform';

type AudioRecordResponseType = {
    isDarkMode: boolean;
};
function AudioRecordResponse({ isDarkMode }: AudioRecordResponseType) {
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;

    return (
        <>
            {activityProps.activityType === ActivityTypes.AUDIO_RECORD &&
                activity.submittedResponses.map((response, index: number) => (
                    <div className="chat_text update_images update_audio" key={index}>
                        <Waveform url={response.responseData} customHeight={64} isDarkMode={isDarkMode} />
                    </div>
                ))}
        </>
    );
}

export default AudioRecordResponse;
