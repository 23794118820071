import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import { ActivityTypes } from '../../../../../../constants/activity-types.enum';
import { findPresenterInstructionPool } from '../../../../../../helpers/languageHelpers';

const generateActivityInstruction = (activityProps: ActivityPropsInterface) => {
    const activityType = activityProps.activityType;

    let instruction = '';
    switch (activityType) {
        case ActivityTypes.MULTIPLE_CHOICE:
            instruction = instructMultipleChoice(activityProps);
            break;
        case ActivityTypes.WORD_CLOUD:
            instruction = instructWordCloud(activityProps);
            break;
        case ActivityTypes.FILL_IN_THE_BLANKS:
            instruction = instructFillBlanks(activityProps);
            break;
        case ActivityTypes.SHORT_ANSWER:
            instruction = instructShortAnswer(activityProps);
            break;
        case ActivityTypes.SLIDE_DRAWING:
            instruction = instructSlideDrawing(activityProps);
            break;
        case ActivityTypes.IMAGE_UPLOAD:
            instruction = instructImageUpload(activityProps);
            break;
        case ActivityTypes.AUDIO_RECORD:
            instruction = instructAudioRecord(activityProps);
            break;
        case ActivityTypes.VIDEO_UPLOAD:
            instruction = instructVideoUpload(activityProps);
            break;
        case ActivityTypes.QUICK_POLL:
            instruction = instructQuickPoll(activityProps);
            break;
        default:
            break;
    }
    return instruction;
};

const instructMultipleChoice = (activityProps: ActivityPropsInterface): string => {
    const presenterInstructionMessagesPool: any = findPresenterInstructionPool(activityProps);
    const isAllowMultiple = activityProps.mcIsAllowSelectMultiple;
    if (!isAllowMultiple) return presenterInstructionMessagesPool.multipleChoiceSingle;
    else return presenterInstructionMessagesPool.multipleChoiceMultiple;
};

const instructWordCloud = (activityProps: ActivityPropsInterface): string => {
    const presenterInstructionMessagesPool = findPresenterInstructionPool(activityProps);
    if (activityProps.numOfSubmissionsAllowed === 1) return presenterInstructionMessagesPool.wordCloudSingle;
    else return presenterInstructionMessagesPool.wordCloudMultiple;
};

const instructFillBlanks = (activityProps: ActivityPropsInterface): string => {
    const presenterInstructionMessagesPool = findPresenterInstructionPool(activityProps);
    if (activityProps.fbCorrectAnswers?.length === 0) return presenterInstructionMessagesPool.fillBlanksTypeResponse;
    else return presenterInstructionMessagesPool.fillBlanksTypeCorrectAnswer;
};

const instructShortAnswer = (activityProps: ActivityPropsInterface): string => {
    const presenterInstructionMessagesPool = findPresenterInstructionPool(activityProps);
    if (activityProps.numOfSubmissionsAllowed === 1) return presenterInstructionMessagesPool.shortAnswerSingle;
    else return presenterInstructionMessagesPool.shortAnswerMultiple;
};

const instructSlideDrawing = (activityProps: ActivityPropsInterface): string => {
    const presenterInstructionMessagesPool = findPresenterInstructionPool(activityProps);
    return presenterInstructionMessagesPool.slideDrawingInstruction;
};

const instructImageUpload = (activityProps: ActivityPropsInterface): string => {
    const presenterInstructionMessagesPool = findPresenterInstructionPool(activityProps);
    if (activityProps.numOfSubmissionsAllowed === 1) return presenterInstructionMessagesPool.imageUploadSingle;
    else return presenterInstructionMessagesPool.imageUploadMultiple;
};

const instructAudioRecord = (activityProps: ActivityPropsInterface): string => {
    const presenterInstructionMessagesPool = findPresenterInstructionPool(activityProps);
    return presenterInstructionMessagesPool.audioRecordInstruction;
};

const instructVideoUpload = (activityProps: ActivityPropsInterface): string => {
    const presenterInstructionMessagesPool = findPresenterInstructionPool(activityProps);
    return presenterInstructionMessagesPool.videoUploadInstruction;
};

const instructQuickPoll = (activityProps: ActivityPropsInterface): string => {
    const presenterInstructionMessagesPool = findPresenterInstructionPool(activityProps);
    return presenterInstructionMessagesPool.returnToPollInstruction;
};

export default generateActivityInstruction;
