import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import SlideViewer from './SlideViewer';
import SidePanel from './SidePanel';
import ExportPdfPopUp from './SidePanel/ExportPdfPopUp/ExportPdfPopUp';
import { StoreInterface } from '../../../../interfaces/store.interface';

import './Main.scss';

const Main = () => {
    const activityProps = useSelector((state: StoreInterface) => state.activity).activityProps;

    return (
        <Fragment>
            <div className="main_body">
                <div className="container">
                    <Grid container spacing={2}>
                        <Grid item md={activityProps ? 8 : 9} xs={12} className="left_grid">
                            <SlideViewer />
                        </Grid>
                        <Grid item md={activityProps ? 4 : 3} xs={12} className="right_grid">
                            <SidePanel />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <ExportPdfPopUp />
        </Fragment>
    );
};

export default Main;
