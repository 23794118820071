import { Button, TextField } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { activityActions } from '../../../../../../actions/activity.actions';
import ResponseToSubmitDto from '../../../../../../dtos/response-to-submit.dto';
import { ActivityPropsInterface, ActivityStatusTypes } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';

function WordCloudInput() {
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
    } = useForm();

    const dispatch = useDispatch();
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const numOfSubmissionsAllowed = activityProps.numOfSubmissionsAllowed as number;
    const submittedResponses = activity.submittedResponses;
    const { t } = useTranslation();

    const WordCloudSubmit = async (responseData: string) => {
        const responseToSubmit: ResponseToSubmitDto = {
            activityId: activityProps.activityId,
            activityType: activityProps.activityType,
            responseId: 'resp-' + v4(),
            responseData,
        };
        dispatch(activityActions.submitResponse(responseToSubmit));
    };

    function wordDuplicateFilter(word: string) {
        const result = submittedResponses.filter((item) => item.responseData === word.trim().toLowerCase());
        return result.length > 0;
    }

    function onSubmit(e: any) {
        if (!e.answer.trim())
            return setError('answer', {
                type: 'required',
                message: t(`lang_activity.err_response_cannot_empty`),
            });
        if (wordDuplicateFilter(e.answer))
            return setError('answer', {
                type: 'sameAnswer',
                message: t(`lang_activity.err_response_submitted`),
            });
        WordCloudSubmit(e.answer.trim().toLowerCase());
        setValue('answer', '');
    }

    return (
        <React.Fragment>
            {activity.activityStatus === ActivityStatusTypes.submitting &&
                submittedResponses.length < numOfSubmissionsAllowed && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="short_ans_form">
                            <div className="text_area">
                                <TextField
                                    autoComplete="off"
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder={t(`lang_activity.txt_placeholder_type_word_phrase`)}
                                    {...register('answer', {
                                        required: true,
                                        maxLength: 50,
                                    })}
                                    inputProps={{ maxLength: 30 }}
                                    error={errors.answer ? true : false}
                                    helperText={
                                        errors?.answer?.type === 'required'
                                            ? t(`lang_activity.err_response_cannot_empty`)
                                            : errors?.answer?.type === 'sameAnswer'
                                            ? t(`lang_activity.err_response_submitted`)
                                            : errors?.answer?.type === 'maxLength'
                                            ? t(`lang_activity.err_response_too_long`)
                                            : ''
                                    }
                                />
                            </div>
                            <div className="sh_btn">
                                <Button variant="contained" type="submit">
                                    {t(`lang_activity.btn_submit`)}
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
        </React.Fragment>
    );
}

export default WordCloudInput;
