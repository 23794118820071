import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, LinearProgress, Modal, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import { BootstrapTooltip } from '../../../../../helpers/mui/CustomToolTip';

import PresenterInterface from '../../../../../interfaces/presenter.interface';
import { StoreInterface } from '../../../../../interfaces/store.interface';
import Constants from '../../../../../constants';
import { utilConstants } from '../../../../../constants/utils.constants';

import CustomAvatar, { AvatarType } from '../../../../Common/CustomAvatar';

export default function SlideViewerFooter({
    presenter,
    shouldDisplayIcons,
    onSaveSlide,
}: {
    presenter: PresenterInterface;
    shouldDisplayIcons: boolean;
    onSaveSlide: () => void;
}) {
    const store = useSelector((state: StoreInterface) => state);
    const { currentClass, activity } = store;

    const footerRef = useRef<HTMLDivElement>(null);
    const footerLeftRef = useRef<HTMLDivElement>(null);

    const [isSlideFullScreen, setIsSlideFullScreen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(0);
    const [availableFooterWidth, setAvailableFooterWidth] = useState(0);
    const [footerLeftWidth, setFooterLeftWidth] = useState(0);

    const footerRightWidth = windowWidth > 480 ? 115 : 95;
    const footerRightSingleIconWidth = 60;
    const showBothIcons = footerLeftWidth + footerRightWidth < availableFooterWidth;
    const showFullScreenIcon = footerLeftWidth + footerRightSingleIconWidth < availableFooterWidth;

    const isCCT = store.presenter.isCCT;
    const isCCE = store.presenter.isCCE;
    const isCSC = store.presenter.isCSC;

    const getDimensions = () => {
        setWindowWidth(window.innerWidth);
        setAvailableFooterWidth((footerRef.current?.clientWidth && footerRef.current.clientWidth - 20) || 0);
        setFooterLeftWidth(footerLeftRef.current?.clientWidth || 0);
    };

    useEffect(() => {
        setTimeout(() => getDimensions(), 100);
        window.addEventListener('resize', getDimensions);
        return () => window.removeEventListener('resize', getDimensions);
    }, []);

    useEffect(() => {
        setIsSlideFullScreen(false);
    }, [activity.activityProps, currentClass.isInSlideshow]);

    return (
        <div ref={footerRef} className="presenter_foot">
            <div ref={footerLeftRef} className="footleft">
                <div className="pfoot_profile">
                    <div className="pfoot_profile_img">
                        <CustomAvatar
                            avatarName={`${presenter.firstName} ${presenter.lastName}`}
                            avatarUrl={presenter.avatarUrl}
                            type={AvatarType.USER}
                        />
                    </div>
                    <div className="pfoot_profile_content">
                        <div className="presenterIsCCT">
                            <Typography variant="h4">
                                {presenter.firstName} {presenter.lastName}
                            </Typography>
                            <span className="badge">
                                {isCCE && (
                                    <BootstrapTooltip title="ClassPoint Certified Educator" placement="bottom">
                                        <a href={utilConstants.WEBSITE_URL.CCE} target="_blank">
                                            <img src={Constants.IMAGE_URLS.cce_logo} alt="isCCE" />
                                        </a>
                                    </BootstrapTooltip>
                                )}
                                {isCSC && (
                                    <BootstrapTooltip title="ClassPoint School Coach" placement="bottom">
                                        <a href={utilConstants.WEBSITE_URL.CSC} target="_blank">
                                            <img src={Constants.IMAGE_URLS.csc_logo} alt="isCSC" />
                                        </a>
                                    </BootstrapTooltip>
                                )}
                                {isCCT && (
                                    <BootstrapTooltip title="ClassPoint Certified Trainer" placement="bottom">
                                        <a href={utilConstants.WEBSITE_URL.CCT} target="_blank">
                                            <img src={Constants.IMAGE_URLS.cct_logo2} alt="isCCT" />
                                        </a>
                                    </BootstrapTooltip>
                                )}
                            </span>
                        </div>
                        <Typography variant="body1">{presenter.organization}</Typography>
                    </div>
                </div>
            </div>
            {shouldDisplayIcons ? (
                <div className="footright">
                    <ul>
                        {showBothIcons && (
                            <li className={showBothIcons ? 'showBorder' : ''}>
                                <DownloadIcon onClick={() => onSaveSlide()} />
                            </li>
                        )}
                        {showFullScreenIcon && (
                            <li>
                                <FullscreenIcon onClick={() => setIsSlideFullScreen(true)} />
                            </li>
                        )}
                    </ul>
                </div>
            ) : null}
            <Modal
                open={isSlideFullScreen}
                onClose={() => setIsSlideFullScreen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="slide_show_modal mobile_rotate">
                    <div className="slide_show_wrapper">
                        <img
                            src={activity.activityProps?.activitySlideUrl || currentClass.currentSlideshow.imageUrl}
                            alt="slide"
                            width="100%"
                        />
                        {currentClass.currentSlideshow.isAudienceSlideViewerEnabled && (
                            <div className="loader_main">
                                <LinearProgress
                                    color="warning"
                                    variant="determinate"
                                    value={
                                        (currentClass.currentSlideshow.currentSlideIndex /
                                            currentClass.currentSlideshow.totalSlideCount) *
                                        100
                                    }
                                />
                            </div>
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
