export const PresetBackgrounds = [
    '/assets/images/backgrounds/01.webp',
    '/assets/images/backgrounds/02.webp',
    '/assets/images/backgrounds/03.webp',
    '/assets/images/backgrounds/04.webp',
    '/assets/images/backgrounds/05.webp',
    '/assets/images/backgrounds/06.webp',
    '/assets/images/backgrounds/07.webp',
    '/assets/images/backgrounds/08.webp',
    '/assets/images/backgrounds/09.webp',
    '/assets/images/backgrounds/10.webp',
    '/assets/images/backgrounds/11.webp',
    '/assets/images/backgrounds/12.webp',
    '/assets/images/backgrounds/13.webp',
    '/assets/images/backgrounds/14.webp',
    '/assets/images/backgrounds/15.webp',
    '/assets/images/backgrounds/16.webp',
    '/assets/images/backgrounds/17.webp',
    '/assets/images/backgrounds/18.webp',
    '/assets/images/backgrounds/19.webp',
    '/assets/images/backgrounds/20.webp',
    '/assets/images/backgrounds/21.webp',
    '/assets/images/backgrounds/22.webp',
    '/assets/images/backgrounds/23.webp',
    '/assets/images/backgrounds/24.webp',
    '/assets/images/backgrounds/25.webp',
    '/assets/images/backgrounds/26.webp',
    '/assets/images/backgrounds/27.webp',
    '/assets/images/backgrounds/28.webp',
    '/assets/images/backgrounds/29.webp',
    '/assets/images/backgrounds/30.webp',
];
