import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import QpChoicesInterface from '../../../../../../interfaces/qp-choices.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';

function QuickPollResponse() {
    const { t } = useTranslation();
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const qpChoices = activityProps.qpChoices as string[];
    const qpChoicesObj = qpChoices.map((qpChoice) => JSON.parse(qpChoice));
    const responseData = activity.submittedResponses[0].responseData;
    const submittedChoice: QpChoicesInterface = qpChoicesObj.find((choiceObj) => choiceObj.id === responseData);

    return (
        <div className="chat_text_group">
            <div className="chat_text">
                <div className="multi_ans quickPool">
                    {activity.submittedResponses.length > 0 && submittedChoice && (
                        <span
                            style={{
                                backgroundColor: submittedChoice.color,
                            }}
                        >
                            {t(`lang_quick_poll.qpchoice_${submittedChoice.id}`)}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

export default QuickPollResponse;
