import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import React, { Fragment, useState, useRef, useEffect } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import Constants from '../../../../../../constants';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';
import { useDispatch, useSelector } from 'react-redux';
import { activityActions } from '../../../../../../actions/activity.actions';
import { v4 } from 'uuid';
import LoaddingButton from '../../../../../Common/LoadingButton';
import ResponseToSubmitDto from '../../../../../../dtos/response-to-submit.dto';
import { ActivityTypes } from '../../../../../../constants/activity-types.enum';
import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import { getBase64FromFile } from '../../../../../../helpers/utils';
import ImageVideoCaption from '../ImageUpload/ImageVideoCaption';
import httpServices from '../../../../../../services/httpService';
import { participantActions } from '../../../../../../actions/participant.actions';

const VideoCaptureModal = ({
    openCapture,
    handleCloseCapture,
    setVideoData,
    videoData,
    setIsCapturing,
    setIsCaptureDone,
    isCapturing,
    isCaptureDone,
    setRecordedChunks,
    recordedChunks,
}: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const participant = useSelector((state: StoreInterface) => state.participant);
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const maxVideoCaptureDuration = 600;
    const webcamRef: any = useRef(null);
    const mediaRecorderRef: any = React.useRef(null);
    const [timerTime, setTimerTime] = useState<any>(0);
    const [timer, setTimer] = useState<any>();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [cameraError, setCameraError] = useState('');
    const [caption, setCaption] = useState('');
    const [captionError, setCaptionError] = useState('');
    const playerRef = useRef<any>(null);
    const secondsDisplay = ('0' + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    const minutesDisplay = ('0' + (Math.floor(timerTime / 60000) % 60)).slice(-2);

    useEffect(() => {
        if (recordedChunks.length > 0) {
            const blob = new Blob(recordedChunks, {
                type: 'video/mp4',
            });
            getBase64FromFile(blob).then((result) => {
                setVideoData(result);
            });
        }
    }, [recordedChunks]);
    useEffect(() => {
        if (videoData) {
            getDuration(videoData, function (duration: any) {
                playerRef.current.seekTo(duration, 'seconds');
                setTimeout(() => {
                    playerRef.current.seekTo(0, 'seconds');
                }, 500);
            });
        }
    }, [videoData]);

    useEffect(() => {
        if (Math.floor(timerTime / 1000) >= maxVideoCaptureDuration) {
            handleStopCaptureClick();
            stopTimer();
        }
    }, [timerTime]);

    useEffect(() => {
        if (webcamRef.current) {
            if (webcamRef.current.stream) {
                setCameraError('');
            } else {
                setCameraError(t('lang_activity.err_camera_not_available'));
            }
        }
    }, [webcamRef.current]);

    const handleStartCaptureClick = React.useCallback(() => {
        const mimeType = 'video/webm;codecs=h264';
        if (
            webcamRef.current.stream &&
            webcamRef.current.stream.active &&
            MediaRecorder &&
            MediaRecorder.isTypeSupported(mimeType)
        ) {
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                mimeType: mimeType,
            });
            mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
            mediaRecorderRef.current.start();
            setIsCapturing(true);
            setCameraError('');
            startTimer();
        } else {
            setIsCapturing(false);
            setCameraError(t('lang_activity.err_camera_not_available'));
        }
    }, [webcamRef, setIsCapturing, mediaRecorderRef]);

    const handleStopCaptureClick = React.useCallback(() => {
        mediaRecorderRef.current.stop();
        setIsCapturing(false);
        setIsCaptureDone(true);
    }, [mediaRecorderRef, webcamRef, setIsCapturing]);

    const startTimer = () => {
        setTimerTime(timerTime);
        let newtimerTime = Date.now() - timerTime;
        setTimer(
            setInterval(() => {
                setTimerTime(Date.now() - newtimerTime);
            }, 1000),
        );
    };

    const stopTimer = () => {
        setTimerTime(0);
        clearInterval(timer);
    };

    const closeModal = () => {
        if (isCapturing) {
            return;
        }
        handleCloseCapture();
    };

    const discardRecording = () => {
        setRecordedChunks([]);
        setVideoData(null);
        setIsCaptureDone(false);
        stopTimer();
        closeModal();
        setCaption('');
    };

    const handleDataAvailable = React.useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev: any) => prev.concat(data));
            }
        },
        [setRecordedChunks],
    );

    const handleSubmit = async () => {
        if (activityProps.isCaptionRequired && !caption.trim())
            return setCaptionError(t('lang_activity.err_caption_required'));

        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: 'video/mp4',
            });

            getBase64FromFile(blob).then((result) => {
                setVideoData(result);
            });
            const responseId = 'resp-' + v4();
            setIsSubmitting(true);
            const videoUrl = await httpServices.uploadToStorage(blob, responseId, participant.cpcsRegion, 'user');
            if (!videoUrl) {
                setIsSubmitting(false);
                dispatch(participantActions.showApiError());
                return;
            }

            const responseData = [videoUrl, caption];
            const responseToSubmit: ResponseToSubmitDto = {
                activityId: activityProps.activityId,
                activityType: activityProps.activityType,
                responseId,
                responseData: JSON.stringify(responseData),
            };
            dispatch(activityActions.submitResponse(responseToSubmit));
            setIsSubmitting(false);
            setCaption('');
            setRecordedChunks([]);
            discardRecording();
        }
        handleCloseCapture();
        setIsCaptureDone(false);
    };

    const getDuration = function (url: any, next: any) {
        const _player = new Audio(url);
        _player.addEventListener(
            'durationchange',
            function (e) {
                if (this.duration !== Infinity) {
                    const duration = this.duration;
                    _player.remove();
                    next(duration);
                }
            },
            false,
        );
        _player.load();
        _player.currentTime = 24 * 60 * 60; //fake big time
        _player.volume = 0;
        _player.play();
        //waiting...
    };

    return (
        <Fragment>
            <Modal
                open={openCapture}
                // onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="video_Upload_modal"
            >
                <Box className="short_modalinner">
                    <div className="close_modal" onClick={closeModal}>
                        <CloseRoundedIcon />
                    </div>
                    <div className="short_ans_head">
                        <img src={Constants.IMAGE_URLS.videoUpload} alt="msgicon" />
                        <Typography variant="h4">{ActivityTypes.VIDEO_UPLOAD}</Typography>
                    </div>
                    <Divider />
                    <div className="video_upload_warp">
                        <div className="webcam_div">
                            <Fragment>
                                {isCaptureDone && videoData ? (
                                    <ReactPlayer
                                        className="video_player "
                                        width="100%"
                                        height="350px"
                                        controls={true}
                                        url={videoData}
                                        ref={(el: any) => (playerRef.current = el)}
                                    />
                                ) : (
                                    <>
                                        <Webcam
                                            ref={webcamRef}
                                            audio={true}
                                            muted={true}
                                            mirrored={true}
                                            screenshotFormat="image/jpeg"
                                            videoConstraints={{
                                                width: 540,
                                                facingMode: 'environment',
                                            }}
                                            onUserMedia={() => {
                                                setCameraError('');
                                            }}
                                            onUserMediaError={() => {}}
                                        />
                                    </>
                                )}
                                {isCapturing && !cameraError ? (
                                    <div className="stopwatch-display">
                                        <Typography variant="caption">
                                            {minutesDisplay} : {secondsDisplay}
                                        </Typography>
                                    </div>
                                ) : null}
                                {cameraError && (
                                    <div className="noCAm">
                                        <Typography variant="body1">{cameraError}</Typography>
                                    </div>
                                )}
                            </Fragment>
                        </div>

                        {recordedChunks.length === 0 ? (
                            <div className="sh_btn images_crop">
                                <div className="video_capture_button">
                                    {isCapturing ? (
                                        <StopCircleIcon
                                            onClick={() => {
                                                handleStopCaptureClick();
                                                stopTimer();
                                            }}
                                        />
                                    ) : (
                                        <Fragment>
                                            {!isCaptureDone && (
                                                <img
                                                    src={Constants.IMAGE_URLS.audioPlay}
                                                    alt="msgicon"
                                                    onClick={handleStartCaptureClick}
                                                />
                                            )}
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        ) : null}

                        {videoData && (
                            <Fragment>
                                <ImageVideoCaption
                                    isCaptionRequired={activityProps.isCaptionRequired}
                                    caption={caption}
                                    setCaption={setCaption}
                                    onHitEnter={handleSubmit}
                                    errorMessage={captionError}
                                    setErrorMessage={setCaptionError}
                                />
                                <div className="sh_btn images_crop">
                                    <Button variant="contained" className="cancel" onClick={() => discardRecording()}>
                                        {t(`lang_activity.btn_discard`)}
                                    </Button>
                                    {!isSubmitting ? (
                                        <Button variant="contained" onClick={handleSubmit}>
                                            {t(`lang_activity.btn_submit`)}
                                        </Button>
                                    ) : (
                                        <LoaddingButton />
                                    )}
                                </div>
                            </Fragment>
                        )}
                    </div>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default VideoCaptureModal;
