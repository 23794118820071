import { localStorageService } from '../services/localStorageService';
import { ActionTypes } from '../constants/action-types.enum';
import QnaDataInterface from '../interfaces/qna.interface';

const QnaReducer = (state = getInitialState(), action: { type: string; payload: any }): QnaDataInterface => {
    switch (action.type) {
        case ActionTypes.JOINED_CLASS:
        case ActionTypes.QNA_DATA_UPDATED:
            const qnaData = action.payload.qnaData;
            return qnaData;
        case ActionTypes.LEFT_CLASS:
            return initialState;
        default:
            return state;
    }
};

export default QnaReducer;

const getInitialState = (): QnaDataInterface => {
    const cachedStore = localStorageService.getStoreData();
    if (cachedStore && cachedStore.qna) return cachedStore.qna;
    return initialState;
};

const initialState = {
    qnas: [],
    qnaVoters: {},
};
