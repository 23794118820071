import { Avatar, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import './VotingAudio.scss';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';

import Waveform from '../SidePanel/Audio/components/Waveform';

import VotingAudioUploadModal from './VotingAudioModal';

function VotingAudio({ handleVote, timerTime }: any) {
    const activity = useSelector((state: any) => state.activity);
    const participant = useSelector((state: any) => state.participant);
    const [numOfVotesLeft, setNumOfVotesLeft] = useState(0);
    const [choosedResponse, setChoosedResponse] = useState<any>({});
    const [choosedResponseIndex, setChoosedResponseIndex] = useState<any>();
    const [open, setOpen] = React.useState(false);
    const votingResponses = activity.votingResponses ? activity.votingResponses : [];

    const [flag, setFlag] = React.useState<number>(0);

    useEffect(() => {
        setNumOfVotesLeft(activity.votingNumber);
    }, []);

    const dispatch = useDispatch();

    const handleClickedImage = (data: any, dataIndex: number) => {
        setChoosedResponse(data);
        setChoosedResponseIndex(dataIndex);
        setOpen(true);
    };

    const showPreviousNote = (currentIndex: number) => {
        if (currentIndex <= 0) {
            setChoosedResponseIndex(0);
            setChoosedResponse(votingResponses[0]);
        } else {
            setChoosedResponseIndex(currentIndex - 1);
            setChoosedResponse(votingResponses[currentIndex - 1]);
        }
    };

    const showNextNote = (currentIndex: number) => {
        if (currentIndex >= votingResponses.length - 1) {
            setChoosedResponseIndex(currentIndex);
            setChoosedResponse(votingResponses[currentIndex]);
        } else {
            setChoosedResponseIndex(currentIndex + 1);
            setChoosedResponse(votingResponses[currentIndex + 1]);
        }
    };
    const handleClose = () => setOpen(false);
    return (
        <Fragment>
            <div className="audio_card">
                <div className="short_ans_wrap">
                    <div>
                        <div className="inner_short_answer">
                            {activity.votingResponses.map((response: any, index: number) => {
                                const isVotedByMe = response.voterParticipantIds.includes(participant.participantId);
                                return (
                                    <Stack key={index} className="video_box">
                                        <div
                                            className="sh_box"
                                            style={{
                                                order: 5,
                                            }}
                                        >
                                            <div className="content_box">
                                                <div className="audio_player">
                                                    <Grid container spacing={1}>
                                                        <Grid item className="flex">
                                                            <div>
                                                                <Waveform
                                                                    url={response.responseData}
                                                                    isRecodedDone={true}
                                                                    isVotingEnable={true}
                                                                    timerTime={timerTime}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                            <div className="sh_footer">
                                                <div className="profile">
                                                    <Avatar
                                                        alt={participant.participantName}
                                                        src={participant.participantAvatar}
                                                    />
                                                    <Typography variant="body1">{response.participantName}</Typography>
                                                </div>

                                                {!isVotedByMe && (
                                                    <div className="like_count">
                                                        <FavoriteBorderIcon
                                                            onClick={() => {
                                                                if (numOfVotesLeft !== 0) {
                                                                    handleVote(response.responseId, true);
                                                                }
                                                            }}
                                                        />
                                                        <Typography variant="caption">
                                                            {response.voterParticipantIds.length}
                                                        </Typography>
                                                    </div>
                                                )}
                                                {isVotedByMe && (
                                                    <div className="like_count">
                                                        <FavoriteIcon
                                                            className="red stroke"
                                                            onClick={() => {
                                                                handleVote(response.responseId, false);
                                                            }}
                                                        />
                                                        <Typography variant="caption">
                                                            {response.voterParticipantIds.length}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Stack>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <VotingAudioUploadModal
                choosedResponse={choosedResponse}
                choosedResponseIndex={choosedResponseIndex}
                showPreviousNote={showPreviousNote}
                showNextNote={showNextNote}
                handleClose={handleClose}
                open={open}
                handleVote={handleVote}
                timerTime={timerTime}
            />
        </Fragment>
    );
}

export default VotingAudio;
