import { Box, Modal, Button, Divider, Typography } from '@mui/material';
import { Fragment, useState, useEffect } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './QuickPoll.scss';
import Constants from '../../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { activityActions } from '../../../../../../actions/activity.actions';
import { v4 } from 'uuid';
import ResponseToSubmitDto from '../../../../../../dtos/response-to-submit.dto';
import { ActivityTypes } from '../../../../../../constants/activity-types.enum';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import QpChoicesInterface from '../../../../../../interfaces/qp-choices.interface';

function QuickPollModal({ handleClose, open }: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const qpChoices = activityProps.qpChoices as string[];
    const [selectedId, setSelectedId] = useState('');

    useEffect(() => {
        setSelectedId('');
    }, [activity.activityProps]);

    const QuickPollChoiceSubmit = () => {
        if (!selectedId) return;

        const responseToSubmit: ResponseToSubmitDto = {
            activityId: activityProps.activityId,
            activityType: activityProps.activityType,
            responseId: 'resp-' + v4(),
            responseData: selectedId,
        };
        dispatch(activityActions.submitResponse(responseToSubmit));
        handleClose();
    };

    return (
        <Fragment>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="quickpoll_modal"
            >
                <Box className="quickpoll_modal_box">
                    <div className="close_modal" onClick={handleClose}>
                        <CloseRoundedIcon />
                    </div>
                    <div className="quickpoll_head">
                        <img src={Constants.IMAGE_URLS.qp_image} alt="msgicon" />
                        <Typography variant="h4">{t(`lang_activity_names.${ActivityTypes.QUICK_POLL}`)}</Typography>
                    </div>
                    <Divider />
                    <div
                        className={
                            JSON.parse(qpChoices[0]).id === 'a' && qpChoices.length === 4
                                ? 'quickpoll_select  quickpoll_bar quickpoll_4'
                                : JSON.parse(qpChoices[0]).id === 'a' && qpChoices.length > 7
                                ? `quickpoll_select quickpoll_bar quickpoll_8`
                                : JSON.parse(qpChoices[0]).id === 'a' && qpChoices.length > 6
                                ? `quickpoll_select quickpoll_bar quickpoll_7`
                                : JSON.parse(qpChoices[0]).id === 'a' && qpChoices.length > 4
                                ? `quickpoll_select quickpoll_bar quickpoll_5`
                                : JSON.parse(qpChoices[0]).id !== 'a' && qpChoices.length > 4
                                ? `quickpoll_select quickpoll_5`
                                : JSON.parse(qpChoices[0]).id === 'a' && qpChoices.length < 4
                                ? `quickpoll_select quickpoll_bar`
                                : `quickpoll_select`
                        }
                    >
                        <div
                            className={
                                JSON.parse(qpChoices[0]).id === 'stronglyAgree'
                                    ? `quickpoll_wraper quickpoll_strong`
                                    : `quickpoll_wraper`
                            }
                        >
                            {qpChoices.map((qpChoice, index: number) => {
                                const choiceItem: QpChoicesInterface = JSON.parse(qpChoice);
                                return (
                                    <Button
                                        key={index}
                                        className={
                                            qpChoices.length > 2
                                                ? selectedId === choiceItem.id
                                                    ? `quickpoll_box flex_grow_0 active`
                                                    : `quickpoll_box flex_grow_0`
                                                : selectedId === choiceItem.id
                                                ? 'quickpoll_box active'
                                                : 'quickpoll_box'
                                        }
                                        onClick={() => setSelectedId(choiceItem.id)}
                                    >
                                        {choiceItem.iconUrl && <img src={choiceItem.iconUrl} alt="msgicon" />}
                                        <Typography
                                            variant="body1"
                                            style={{
                                                color: !choiceItem.iconUrl ? choiceItem.color : '',
                                            }}
                                        >
                                            {t(`lang_quick_poll.qpchoice_${choiceItem.id}`)}
                                        </Typography>
                                    </Button>
                                );
                            })}
                        </div>
                        <div className="sh_btn">
                            <Button onClick={QuickPollChoiceSubmit} variant="contained">
                                {t(`lang_activity.btn_submit`)}
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </Fragment>
    );
}

export default QuickPollModal;
