import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { joinClass } from '../../../actions/signalr.actions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import './ConnectFromOtherTab.scss';
import { StoreInterface } from '../../../interfaces/store.interface';
import { ActionTypes } from '../../../constants/action-types.enum';
import { mapParticipantJoinInput } from '../../../helpers/userHelper';

const ConnectFromOtherTab = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const store = useSelector((state: StoreInterface) => state);

    return (
        <Fragment>
            <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="connectFromModal"
            >
                <Box className="connectFrom_modalinner">
                    <Typography variant="body1">{t(`lang_common.txt_joined_from_another`)}</Typography>
                    <div className="sh_btn connect_btn">
                        <Button
                            variant="contained"
                            onClick={() => {
                                const participantJoinInput = mapParticipantJoinInput(
                                    store.participant,
                                    store.currentClass?.classSessionId || '',
                                );
                                dispatch(joinClass(participantJoinInput));
                            }}
                        >
                            {t(`lang_common.btn_ok`)}
                        </Button>
                        <Button
                            variant="contained"
                            className="cancel"
                            onClick={() => dispatch({ type: ActionTypes.LEFT_CLASS })} // this will leave class for all tabs in the same browser
                        >
                            {t(`lang_participant_main.menu_leave_class`)}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default ConnectFromOtherTab;
