import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Button, Modal, Typography } from '@mui/material';

import Constants from '../../../../../../constants';
import { CurrentView } from '../../../../../../constants/current-view.enum';
import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import { StoreInterface } from '../../../../../../interfaces/store.interface';

import CustomAvatar, { AvatarType } from '../../../../../Common/CustomAvatar';

function MultipleChoiceShowCorrectAnswerModal() {
    const { t } = useTranslation();
    const { activity, presenter, currentView } = useSelector((state: StoreInterface) => state);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const mcChoices = activityProps.mcChoices as string[];
    const mcCorrectAnswers = activityProps.mcCorrectAnswers as string[];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    useEffect(() => {
        if (currentView === CurrentView.SHOW_CORRECT_ANSWER && !hasOpened) {
            setIsModalOpen(true);
            setHasOpened(true);
        } else setIsModalOpen(false);
    }, [currentView]);

    const generateCorrectIncorrectMessage = () => {
        if (mcCorrectAnswers.join(',') === JSON.parse(activity.submittedResponses[0].responseData).join(',')) {
            return JSON.parse(activity.submittedResponses[0].responseData).length > 1
                ? t('lang_activity.txt_answers_are_correct')
                : t('lang_activity.txt_answer_is_correct');
        } else
            return JSON.parse(activity.submittedResponses[0].responseData).length > 1
                ? `${t('lang_activity.txt_answers_are_incorrect')} ${generateCorrectAnswersMessage()}`
                : `${t('lang_activity.txt_answer_is_incorrect')} ${generateCorrectAnswersMessage()}`;
    };

    const generateCorrectAnswersMessage = () => {
        return mcCorrectAnswers.length > 1
            ? t('lang_activity.txt_correct_answers_are')
            : t('lang_activity.txt_correct_answer_is');
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fragment>
                <Box className="correctAnsModal">
                    <div className="instraction_head">
                        <div className="type_ans_box">
                            <CustomAvatar
                                avatarName={`${presenter.firstName} ${presenter.lastName}`}
                                avatarUrl={presenter.avatarUrl}
                                type={AvatarType.USER}
                            />
                            <div className="chat_text">
                                <Typography variant="h4">{generateCorrectIncorrectMessage()}</Typography>
                            </div>
                        </div>
                    </div>
                    <div className="multiplecorrectAnsModal_content">
                        <ul>
                            {mcCorrectAnswers.map((correctAnswer, index: number) => {
                                return (
                                    <li key={index}>
                                        <Typography
                                            variant="body1"
                                            style={{
                                                color: Constants.COLOR_value_ARRAY[mcChoices.indexOf(correctAnswer)],
                                            }}
                                        >
                                            {correctAnswer}
                                        </Typography>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <div className="foot_btn">
                        <Button variant="contained" onClick={() => setIsModalOpen(false)}>
                            {t('lang_common.btn_ok')}
                        </Button>
                    </div>
                </Box>
            </Fragment>
        </Modal>
    );
}

export default MultipleChoiceShowCorrectAnswerModal;
