import { LinearProgress, Typography } from '@mui/material';
import hourglass from './../../../../../assets/animation/hourglass.json';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

export default function NotInSlideshow() {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className="presenter_img loading_page">
                <div className="text">
                    <Lottie
                        loop
                        animationData={hourglass}
                        play
                        style={{
                            width: '24%',
                            height: 'auto',
                        }}
                    />
                    <Typography variant="h6">{t(`lang_slide_viewer.txt_presenter_not_in_slideshow`)}</Typography>
                </div>
            </div>
            <div className="loader_main">
                <LinearProgress color="warning" variant="determinate" value={0} />
            </div>
        </Fragment>
    );
}
