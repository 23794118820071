import { activityActions } from './activity.actions';
import { ActionTypes } from '../constants/action-types.enum';
import { store } from '../helpers/store';
import httpService from '../services/httpService';
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import UpdateParticipantDto from '../dtos/update-participant.dto';
import { ParticipantJoinInputInterface } from '../dtos/participant-join-input.dto';
import CurrentClassInterface from '../interfaces/current-class.interface';
import { sentryLogSignalrError } from '../services/sentryService';
import { logger } from '../services/logger';
import QnaDataInterface from '../interfaces/qna.interface';
import { ParticipantInterface } from '../interfaces/participant.interface';

export const joinClass = (participantInput: ParticipantJoinInputInterface) => async (dispatch: any) => {
    // don't know why participant can have empty participantId, participantName, participantUsername, so log it
    if (!participantInput.participantId || !participantInput.participantName || !participantInput.participantUsername) {
        sentryLogSignalrError('Empty participant input fields', 'ParticipantStartup', {
            participantInput,
            store: store.getState(),
        });
        leaveClass();
        dispatch({ type: ActionTypes.SHOW_API_ERROR });
        return;
    }

    const hubBaseUrl =
        participantInput.cpcsRegion === 'localhost'
            ? process.env.REACT_APP_CLASSSESSIONAPP_LOCAL
            : `https://${participantInput.cpcsRegion}.classpoint.app`;

    const connection = new HubConnectionBuilder()
        .withUrl(hubBaseUrl + '/classsession', {
            transport: HttpTransportType.WebSockets,
            skipNegotiation: true,
        })
        .configureLogging(LogLevel.Debug)
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: () => {
                return 1000;
            },
        })
        .build();

    try {
        connection.onreconnected(async () => {
            logger.log('SIGNALR>>>>>> ParticipantReconnect');
            const currentClass = store.getState().currentClass as CurrentClassInterface;
            await connection.invoke('ParticipantReconnect', participantInput, currentClass.classSessionId);
            dispatch({
                type: ActionTypes.SIGNALR_CONNECTED,
                payload: connection,
            });
        });

        connection.on('SendJoinClass', async (currentClass: any) => {
            logger.log('SIGNALR>>>>>> SendJoinClass', currentClass);
            dispatch({
                type: ActionTypes.JOINED_CLASS,
                payload: currentClass,
            });
            dispatch(activityActions.syncActivityWhenJoin(currentClass));
            const presenter = await httpService.getPresenterInfo(currentClass.presenter.email);
            if (presenter)
                dispatch({
                    type: ActionTypes.UPDATE_PRESENTER_INFO,
                    payload: presenter,
                });
        });

        connection.on('PresenterStartSlideshow', async (newClassInfo: any) => {
            logger.log('SIGNALR>>>>>> PresenterStartSlideshow', newClassInfo);
            const currentClass = store.getState().currentClass as CurrentClassInterface;
            currentClass.isInSlideshow = true;
            currentClass.currentSlideshow.isAudienceSlideViewerEnabled =
                newClassInfo.currentSlideshow.isAudienceSlideViewerEnabled;
            dispatch({
                type: ActionTypes.NEW_SLIDESHOW,
                payload: currentClass,
            });
            const presenter = await httpService.getPresenterInfo(currentClass.presenter.email);
            if (presenter)
                dispatch({
                    type: ActionTypes.UPDATE_PRESENTER_INFO,
                    payload: presenter,
                });
        });

        connection.on('SlideChanged', (newSlideInfo: any) => {
            logger.log('SIGNALR>>>>>> SlideChanged', newSlideInfo);
            const updatedCurrentClass = JSON.parse(
                JSON.stringify(store.getState().currentClass),
            ) as CurrentClassInterface;
            updatedCurrentClass.currentSlideshow = {
                ...updatedCurrentClass.currentSlideshow,
                currentSlideIndex: newSlideInfo.currentSlideIndex,
                totalSlideCount: newSlideInfo.totalSlideCount,
                imageUrl: newSlideInfo.imageUrl,
                // imageUrl: newSlideInfo.imageUrl || updatedCurrentClass.currentSlideshow.imageUrl, // if imageUrl is null then keep displaying old image url
            };
            dispatch({
                type: ActionTypes.SLIDE_CHANGED,
                payload: updatedCurrentClass,
            });
        });

        connection.on('ClassInvalid', async () => {
            //TODO: this is received when joining class. add a view for this error
            logger.log('SIGNALR>>>>>> ClassInvalid');
            await participantKickedOut();
        });

        connection.on('ClassLocked', async () => {
            logger.log('SIGNALR>>>>>> ClassLocked');
            await leaveClass();
            await connection.stop();
            dispatch({ type: ActionTypes.CLASS_LOCKED });
        });

        connection.on('ClassFull', async () => {
            logger.log('SIGNALR>>>>>> ClassFull');
            await leaveClass();
            await connection.stop();
            dispatch({ type: ActionTypes.CLASS_FULL });
        });

        connection.on('NotAllowGuests', async () => {
            logger.log('SIGNALR>>>>>> NotAllowGuests');
            await leaveClass();
            await connection.stop();
            dispatch({ type: ActionTypes.NOT_ALLOW_GUESTS });
        });

        connection.on('DuplicateName', async () => {
            logger.log('SIGNALR>>>>>> DuplicateName');
            await leaveClass();
            await connection.stop();
            dispatch({ type: ActionTypes.NAME_TAKEN });
        });

        connection.on('PresenterEndSlideshow', () => {
            logger.log('SIGNALR>>>>>> PresenterEndSlideshow');
            const updatedCurrentClass = {
                ...store.getState().currentClass,
                isInSlideshow: false,
            };
            dispatch({
                type: ActionTypes.SLIDESHOW_ENDED,
                payload: updatedCurrentClass,
            });
        });

        connection.on('ParticipantUpdatedByClassSessionApp', (updatedParticipantDto: UpdateParticipantDto) => {
            logger.log('SIGNALR>>>>>> ParticipantUpdatedByClassSessionApp', updatedParticipantDto);
            dispatch({
                type: ActionTypes.PARTICIPANT_UPDATED_BY_CLASSSESSION_APP,
                payload: updatedParticipantDto,
            });
        });

        connection.on('RemovedFromClass', async () => {
            logger.log('SIGNALR>>>>>> RemovedFromClass');
            await participantKickedOut();
        });

        connection.on('ConnectionMadeOnAnotherTab', async () => {
            logger.log('SIGNALR>>>>>> ConnectionMadeOnAnotherTab');
            await connection.stop();
            dispatch({ type: ActionTypes.CONNECTED_FROM_ANOTHER_TAB });
        });

        connection.on('ActivityStarted', async (data) => {
            if (typeof data === 'string') data = JSON.parse(data);
            logger.log('SIGNALR>>>>>> ActivityStarted', data);
            dispatch(activityActions.startActivity(data));
        });

        connection.on('ActivityClosed', async () => {
            logger.log('SIGNALR>>>>>> ActivityClosed');
            dispatch(activityActions.closeSubmission());
        });

        connection.on('ActivityEnded', async () => {
            logger.log('SIGNALR>>>>>> ActivityEnded');
            dispatch(activityActions.endActivity());
        });

        connection.on('CorrectAnswerShown', async (isShown) => {
            logger.log('SIGNALR>>>>>> CorrectAnswerShown', isShown);
            if (isShown) dispatch(activityActions.shownCorrectAnswer());
            else dispatch(activityActions.hideCorrectAnswer());
        });

        connection.on('DeletedResponse', async (data) => {
            logger.log('SIGNALR>>>>>> DeletedResponse', data);
            dispatch(activityActions.deleteResponse(data));
        });

        connection.on('VotingStarted', async (data) => {
            logger.log('SIGNALR>>>>>> VotingStarted', data);
            dispatch(activityActions.votingStarted(data));
        });

        connection.on('NumOfVotesUpdated', async (data) => {
            logger.log('SIGNALR>>>>>> NumOfVotesUpdated', data);
            dispatch(activityActions.numOfVotesUpdated(data));
        });

        connection.on('VotingStopped', async () => {
            logger.log('SIGNALR>>>>>> VotingStopped');
            dispatch(activityActions.votingStopped());
        });

        connection.on('PresenterShowLeaderboard', async (leaderboardString) => {
            logger.log('SIGNALR>>>>>> PresenterShowLeaderboard', leaderboardString);
            dispatch({
                type: ActionTypes.LOAD_LEADERBOARD,
                payload: JSON.parse(leaderboardString),
            });
        });

        connection.on('PresenterHideLeaderboard', async () => {
            logger.log('SIGNALR>>>>>> PresenterHideLeaderboard');
            dispatch({
                type: ActionTypes.DISMISS_LEADERBOARD,
            });
        });

        connection.on('GotPoints', async (points: number) => {
            logger.log('SIGNALR>>>>>> GotPoints', points);
            dispatch({
                type: ActionTypes.GOT_POINTS,
                payload: points,
            });
        });

        connection.on('PdfExported', async (pdfUrl: string) => {
            logger.log('SIGNALR>>>>>> PdfExported', pdfUrl);
            dispatch({
                type: ActionTypes.OPEN_EXPORTED_PDF,
                payload: pdfUrl,
            });
        });

        connection.on('QnaDataUpdated', async (qnaData: QnaDataInterface) => {
            logger.log('SIGNALR>>>>>> QnaDataUpdated', qnaData);
            const updatedCurrentClass = JSON.parse(
                JSON.stringify(store.getState().currentClass),
            ) as CurrentClassInterface;
            updatedCurrentClass.qnaData = qnaData;
            dispatch({
                type: ActionTypes.QNA_DATA_UPDATED,
                payload: updatedCurrentClass,
            });
        });

        connection.on('RefetchPresenter', async () => {
            logger.log('SIGNALR>>>>>> RefetchPresenter');
            const participant = JSON.parse(JSON.stringify(store.getState().participant)) as ParticipantInterface;
            const updatedPresenter = await httpService.getPresenterInfo(participant.presenterEmail);
            if (updatedPresenter)
                dispatch({
                    type: ActionTypes.UPDATE_PRESENTER_INFO,
                    payload: updatedPresenter,
                });
        });

        const participantKickedOut = async () => {
            await connection.stop();
            dispatch({ type: ActionTypes.LEFT_CLASS });
        };

        await connection.start();
        dispatch({
            type: ActionTypes.SIGNALR_CONNECTED,
            payload: connection,
        });
        await connection.invoke('ParticipantStartup', participantInput);

        return;
    } catch (error) {
        sentryLogSignalrError(error, 'ParticipantStartup', {
            participantInput,
            store: store.getState(),
        });
        leaveClass();
        dispatch({ type: ActionTypes.SHOW_API_ERROR });
    }
};

export const leaveClass = async () => {
    try {
        const connection = store.getState().connection;
        await connection.invoke('ParticipantLeaveClass', store.getState().participant);
        await connection.stop();
        store.dispatch({ type: ActionTypes.LEFT_CLASS });
    } catch (error) {
        sentryLogSignalrError(error, 'leaveClass', {
            store: store.getState(),
        });
        store.dispatch({ type: ActionTypes.LEFT_CLASS });
    }
};

export const leaveClassKeepingState = async () => {
    // Here we only call signalr to leave class but don't change state, otherwise when internet is slow we'll lose state and cannot join back class on refresh
    try {
        const connection = store.getState().connection;
        await connection.invoke('ParticipantLeaveClass', store.getState().participant);
        await connection.stop();
    } catch (error) {
        sentryLogSignalrError(error, 'leaveClassKeepingState', {
            store: store.getState(),
        });
    }
};
