import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import styles from './SingleQuestion.module.scss';

import httpServices from '../../../../../../../services/httpService';

import { StoreInterface } from '../../../../../../../interfaces/store.interface';
import { QnaInterface } from '../../../../../../../interfaces/qna.interface';

import CustomAvatar, { AvatarType } from '../../../../../../Common/CustomAvatar';

import { ROUND_CHECK, THUMBS_UP_OUTLINED, THUMBS_UP_SOLID } from '../../../../../../../Icon';

interface SingleQuestionInterface {
    data: QnaInterface;
}

const SingleQuestion = ({ data }: SingleQuestionInterface) => {
    const { t } = useTranslation();

    const participant = useSelector((state: StoreInterface) => state.participant);
    const qnaData = useSelector((state: StoreInterface) => state.qnaData);
    const classGroups = useSelector((state: StoreInterface) => state.currentClass.groups);
    const myVotedQnaIds = qnaData.qnaVoters[participant.participantId] || [];

    const [isVoted, setIsVoted] = useState(myVotedQnaIds.includes(data.id));
    const [currentQuestionVoteCount, setCurrentQuestionVoteCount] = useState(data.votes);

    const voteQuestionHandler = async (isVoted: boolean) => {
        setIsVoted((prev) => !prev);
        setCurrentQuestionVoteCount((prev) => (isVoted ? prev - 1 : prev + 1));
        const success = await httpServices.voteQna(
            participant.cpcsRegion,
            participant.presenterEmail,
            participant.participantId,
            data.id,
            !isVoted,
        );
        if (!success) setIsVoted((prev) => !prev);
    };

    const deleteQuestionHandler = async () => {
        await httpServices.deleteQna(participant.cpcsRegion, participant.presenterEmail, data.id);
    };

    useEffect(() => {
        setCurrentQuestionVoteCount(qnaData.qnas.find((qna) => qna.id === data.id)?.votes || 0);
    }, [qnaData]);

    return (
        <div className={styles.singleQuestion}>
            <div className={styles.single_top}>
                <div className={styles.question_profile}>
                    <CustomAvatar
                        avatarName={data.participantName}
                        avatarUrl={data.participantAvatar}
                        type={AvatarType.PARTICIPANT}
                        savedClassGroups={classGroups}
                        groupId={data.groupId}
                    />
                    <p className={styles.name}>{data.participantName}</p>
                </div>
                {data.isAnswered ? (
                    <div className={`${styles.answered}`}>
                        <i className={styles.icon}>
                            <ROUND_CHECK />
                        </i>
                        <p className={styles.text}>{t('lang_qna.answered')}</p>
                    </div>
                ) : (
                    <>
                        {participant.participantId === data.participantId && (
                            <LoadingButton onClick={() => deleteQuestionHandler()} className={`${styles.deleteBtn}`}>
                                <DeleteOutlineOutlinedIcon className={`${styles.icon}`} />
                            </LoadingButton>
                        )}
                        <div className={styles.upgrade_ans} onClick={() => voteQuestionHandler(isVoted)}>
                            <i className={styles.icon}>{isVoted ? <THUMBS_UP_SOLID /> : <THUMBS_UP_OUTLINED />}</i>
                            <p className={styles.number}>{currentQuestionVoteCount}</p>
                        </div>
                    </>
                )}
            </div>

            <div className={styles.question}>
                <p className={styles.text}>{data.question}</p>
            </div>
        </div>
    );
};

export default SingleQuestion;
