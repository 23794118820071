import { ActionTypes } from '../constants/action-types.enum';
import PresenterInterface from '../interfaces/presenter.interface';
import { localStorageService } from '../services/localStorageService';

const PresenterReducer = (state = getCachedState(), action: { type: string; payload: any }): PresenterInterface => {
    switch (action.type) {
        case ActionTypes.UPDATE_PRESENTER_INFO:
            return action.payload;
        case ActionTypes.LEFT_CLASS:
            return initialState;
        default:
            return state;
    }
};

const getCachedState = () => {
    const cachedStore = localStorageService.getStoreData();
    if (cachedStore && cachedStore.presenter) return cachedStore.presenter;

    return initialState;
};

const initialState: PresenterInterface = {
    firstName: '',
    lastName: '',
    avatarUrl: '',
    organization: '',
    isCCT: false,
    isCCE: false,
    isCSC: false,
    isOnPro: false,
};

export default PresenterReducer;
