import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';

export const generatePresenterInstructionMessages = (activityProps: ActivityPropsInterface) => {
    const PresenterInstructionMessages = {
        ar: {
            multipleChoiceSingle: `أختر<mark> !إجابة واحدة فقط</mark> أجب على هذا السؤال وانقر على تقديم`,
            multipleChoiceMultiple: `أختر <mark>واحد أو أكثر</mark> إجابات على هذا السؤال وانقر فوق تقديم`,
            wordCloudSingle: `!قم بالإجابة عن السؤال بكلمة أو جملة، هيا بنا`,
            wordCloudMultiple: `قم بالإجابة عن السؤال بكلمة أو جملة، بإمكانك تقديم عدة إجابات.`,
            fillBlanksTypeResponse: `.اكتب إجابة لكل فراغ وانقر فوق تقديم`,
            fillBlanksTypeCorrectAnswer: `.اكتب الإجابة الصحيحة لكل فراغ وانقر فوق تقديم`,
            shortAnswerSingle: `اكتب إجابتك القصيرة أدناه ثم اضغط على تقديم <mark>فقط إجابة واحدة </mark> مسموحة`,
            shortAnswerMultiple: `اكتب إجابتك القصيرة أدناه ثم اضغط على تقديم. يمكنك إضافة ما يصل إلى ${activityProps.numOfSubmissionsAllowed} إجابات`,
            slideDrawingInstruction: `!إضغط على 'افتح الشريحة', وابدأ الرسم، هيا بنا`,
            imageUploadSingle: `ابحث عن صورة من مكتبتك أو ابحث من جوجل <mark>فقط إجابة واحدة</mark> مسموحة.`,
            imageUploadMultiple: `ابحث عن صورة من مكتبتك أو ابحث من جوجل. يمكنك إضافة ما يصل إلى ${activityProps.numOfSubmissionsAllowed} إجابات`,
            audioRecordInstruction: `اختر ملفًا صوتيًا من مكتبتك، أو انقر فوق الزر أدناه لبدء التسجيل الصوتي`,
            videoUploadInstruction: `اختر مقطع فيديو من مكتبتك، أو التقط مقطع فيديو جديد`,
            returnToPollInstruction: `انقر فوق الزر للعودة إلى الاستطلاع.`,
        },
        de: {
            multipleChoiceSingle: `Wählen Sie <mark>NUR EINE</mark> Antwort auf diese Frage und klicken Sie auf Senden.`,
            multipleChoiceMultiple: `Wählen Sie <mark>Eine oder mehrere</mark> Antworten auf diese Frage und klicken Sie auf Senden.`,
            wordCloudSingle: `antworten Sie auf meine Frage mit einem Wort oder einem Satz. Versuchen wir es mal!`,
            wordCloudMultiple: `Beantworten Sie meine Frage mit einem Wort oder einem Satz. Sie können <mark>bis zu ${activityProps.numOfSubmissionsAllowed}</mark> antworten.`,
            fillBlanksTypeResponse: `geben Sie eine Antwort für jedes Feld ein und klicken Sie auf Senden.`,
            fillBlanksTypeCorrectAnswer: `geben Sie die richtige Antwort für jedes Feld ein und klicken Sie auf Senden.`,
            shortAnswerSingle: `teben Sie Ihre kurze Antwort unten ein und senden Sie sie ab.`,
            shortAnswerMultiple: `geben Sie Ihre kurze Antwort unten ein und senden Sie sie ab. Sie können <mark>bis zu${activityProps.numOfSubmissionsAllowed}</mark> antwortet.`,
            slideDrawingInstruction: `klicken Sie auf 'Folie öffnen', wählen Sie eine Markerfarbe und beginnen Sie auf der Folie zu zeichnen. Los geht's!`,
            imageUploadSingle: `ein Bild als Antwort einreichen. Sie können ein Foto von Ihrem Gerät abrufen oder über Google suchen.`,
            imageUploadMultiple: `ein Foto aus Ihrer Bibliothek suchen oder über Google suchen. Sie können <mark>bis zu ${activityProps.numOfSubmissionsAllowed}</mark> antwortet.`,
            audioRecordInstruction: `wählen Sie ein Audio aus Ihrer Bibliothek oder klicken Sie auf die Schaltfläche unten, um Ihre Audioaufnahme zu starten.`,
            videoUploadInstruction: `ein Video aus Ihrer Bibliothek auswählen oder ein neues Video aufnehmen.`,
            returnToPollInstruction: `Klicken Sie auf die Schaltfläche, um zur Umfrage zurückzukehren..`,
        },
        en: {
            multipleChoiceSingle: `choose <mark>ONLY ONE</mark> answer to this question and click Submit.`,
            multipleChoiceMultiple: `choose <mark>ONE or MORE</mark> answers to this question and click Submit.`,
            wordCloudSingle: `respond to my question with a word or phrase. Let's have a go!`,
            wordCloudMultiple: `respond to my question with a word or phrase. you can submit <mark>up to ${activityProps.numOfSubmissionsAllowed}</mark> responses.`,
            fillBlanksTypeResponse: `type a response for each blank and click Submit.`,
            fillBlanksTypeCorrectAnswer: `type the correct answer for each blank and click Submit.`,
            shortAnswerSingle: `type your short answer below and submit.`,
            shortAnswerMultiple: `type your short answer below and submit. You can add <mark>up to ${activityProps.numOfSubmissionsAllowed}</mark> answers.`,
            slideDrawingInstruction: `click on 'Open slide', choose a marker colour and start drawing on the slide. Let's go!`,
            imageUploadSingle: `submit an image as response. You can find a photo from your device or search from Google.`,
            imageUploadMultiple: `find a photo from your library or search from Google. You can add <mark>up to ${activityProps.numOfSubmissionsAllowed}</mark> answers.`,
            audioRecordInstruction: `choose an audio from your library, or click the button below to start your audio recording.`,
            videoUploadInstruction: `choose a video from your library, or capture a new one.`,
            returnToPollInstruction: `click the button to return to the poll.`,
        },
        es: {
            multipleChoiceSingle: `elige <mark>SOLO UNA</mark> respuesta a esta pregunta y haz clic en Enviar.`,
            multipleChoiceMultiple: `elige <mark>UNA o MÁS</mark> respuestas a esta pregunta y haz clic en Enviar.`,
            wordCloudSingle: `responde a mi pregunta con una palabra o frase. ¡Vamos a intentarlo!`,
            wordCloudMultiple: `responde a mi pregunta con una palabra o frase. Puedes enviar <mark>hasta ${activityProps.numOfSubmissionsAllowed}</mark> respuestas.`,
            fillBlanksTypeResponse: `escribe una respuesta para cada espacio en blanco y haz clic en Enviar.`,
            fillBlanksTypeCorrectAnswer: `escribe la respuesta correcta para cada espacio en blanco y haz clic en Enviar.`,
            shortAnswerSingle: `escribe tu respuesta corta a continuación y envíala.`,
            shortAnswerMultiple: `escribe tu respuesta corta a continuación y envíala. Puedes añadir <mark>hasta ${activityProps.numOfSubmissionsAllowed}</mark> respuestas.`,
            slideDrawingInstruction: `haz clic en ‘Abrir diapositiva’, elige un color del marcador y empieza a dibujar en la diapositiva. ¡Vamos!`,
            imageUploadSingle: `envía una imagen como respuesta. Puedes encontrar una foto desde tu dispositivo o buscarla en Google.`,
            imageUploadMultiple: `encuentra una foto de tu biblioteca o búscala en Google. Puedes añadir <mark>hasta ${activityProps.numOfSubmissionsAllowed}</mark> respuestas.`,
            audioRecordInstruction: `elige un audio de tu biblioteca o haz clic en el botón de abajo para iniciar la grabación de audio.`,
            videoUploadInstruction: `elige un vídeo de tu biblioteca o graba uno nuevo.`,
            returnToPollInstruction: `haz clic en el botón para volver a la encuesta.`,
        },
        fil: {
            multipleChoiceSingle: `<mark>ISA LAMANG</mark> ang sagot sa tanong na ito. Pumili at I-sumite.`,
            multipleChoiceMultiple: `<mark>ISA O HIGIT PA</mark> ang sagot sa tanong na ito. Pumili at I-sumite.`,
            wordCloudSingle: `mangyaring sumagot sa aking tanong gamit ang salita o parirala. Game!`,
            wordCloudMultiple: `mangyaring sumagot sa aking tanong gamit ang salita o parirala. Maaari kang mag-sumite ng <mark>hanngang sa ${activityProps.numOfSubmissionsAllowed}</mark> mga tugon.`,
            fillBlanksTypeResponse: `mag-type ng tugon sa bawat blangko at I-sumite.`,
            fillBlanksTypeCorrectAnswer: `i-type ang tamang sagot sa bawat blangko at I-sumite.`,
            shortAnswerSingle: `i-type ang iyong sagot sa baba at i-sumite.`,
            shortAnswerMultiple: `mag-type ng tugon sa ibaba at mag-sumite. Maaari kang magdagdag ng hanggang sa ${activityProps.numOfSubmissionsAllowed} na mga sagot.`,
            slideDrawingInstruction: `pindutin ang 'Buksan ang slide', pumili ng kulay ng panulat at magsimulang gumuhit sa slide. Game!`,
            imageUploadSingle: `magsumite ng larawan bilang tugon. Pwede maghanap ng larawan mula sa iyong device o maghanap mula sa Google.`,
            imageUploadMultiple: `maghanap ng larawan mula sa iyong library o mag-search mula sa Google. Maaari kang magdagdag ng hanggang sa ${activityProps.numOfSubmissionsAllowed} na mga sagot.`,
            audioRecordInstruction: `pumili ng audio mula sa iyong library o pindutin ang buton sa ibaba upang simulan ang pag-record ng audio.`,
            videoUploadInstruction: `pumili ng video mula sa iyong library o kumuha ng bagong video.`,
            returnToPollInstruction: `mangyaring pindutin ang buton upang makabalik sa poll.`,
        },
        fr: {
            multipleChoiceSingle: `choisissez <mark>SEULEMENT UNE</mark> réponse à cette question et cliquez sur Soumettre.`,
            multipleChoiceMultiple: `choisissez <mark>UNE ou PLUSIEURS</mark> réponses à cette question et cliquez sur Soumettre.`,
            wordCloudSingle: `répondre à ma question par un mot ou une phrase. Essayons !`,
            wordCloudMultiple: `répondez à ma question avec un mot ou une phrase. Vous pouvez soumettre <mark>jusqu'à ${activityProps.numOfSubmissionsAllowed}</mark> réponses.`,
            fillBlanksTypeResponse: `tapez une réponse pour chaque champs et cliquez sur Soumettre.`,
            fillBlanksTypeCorrectAnswer: `tapez la bonne réponse pour chaque champs et cliquez sur Soumettre.`,
            shortAnswerSingle: `tapez votre réponse courte ci-dessous et soumettez-la.`,
            shortAnswerMultiple: `tapez votre réponse ci-dessous et soumettez-la. Vous pouvez ajouter jusqu'à ${activityProps.numOfSubmissionsAllowed} réponses.`,
            slideDrawingInstruction: `cliquez sur 'Open slide', choisissez une couleur de marqueur et commencez à dessiner sur la diapositive. Allons-y!`,
            imageUploadSingle: `soumettre une image comme réponse. Vous pouvez trouver une photo sur votre appareil ou effectuer une recherche sur Google.`,
            imageUploadMultiple: `trouver une photo dans votre bibliothèque ou effectuer une recherche sur Google. Vous pouvez ajouter jusqu'à ${activityProps.numOfSubmissionsAllowed} réponses.`,
            audioRecordInstruction: `choisissez un fichier audio dans votre bibliothèque ou cliquez sur le bouton ci-dessous pour démarrer votre enregistrement audio.`,
            videoUploadInstruction: `choisissez une vidéo dans votre bibliothèque ou capturez-en une nouvelle.`,
            returnToPollInstruction: `cliquez sur le bouton pour revenir au sondage.`,
        },
        hi: {
            multipleChoiceSingle: `<mark>केवल एक</mark> इस प्रश्न का उत्तर चुनें और सबमिट करें पर क्लिक करें।`,
            multipleChoiceMultiple: `इस प्रश्न के लिए <mark>एक या अधिक</mark> उत्तर चुनें और सबमिट करें क्लिक करें।`,
            wordCloudSingle: `एक शब्द या वाक्यांश के साथ मेरे प्रश्न का उत्तर दें। चलो चलें!`,
            wordCloudMultiple: `एक शब्द या वाक्यांश के साथ मेरे प्रश्न का उत्तर दें। आप <mark>${activityProps.numOfSubmissionsAllowed}</mark> तक प्रतिक्रियाएँ सबमिट कर सकते हैं।`,
            fillBlanksTypeResponse: `प्रत्येक रिक्त स्थान के लिए एक प्रतिक्रिया टाइप करें और सबमिट पर क्लिक करें।`,
            fillBlanksTypeCorrectAnswer: `प्रत्येक रिक्त स्थान के लिए सही उत्तर टाइप करें और सबमिट पर क्लिक करें।`,
            shortAnswerSingle: `अपना संक्षिप्त उत्तर नीचे टाइप करें और सबमिट करें।`,
            shortAnswerMultiple: `अपना संक्षिप्त उत्तर नीचे टाइप करें और सबमिट करें। आप <mark>ज़्यादा से ज़्यादा ${activityProps.numOfSubmissionsAllowed}</mark> जवाब जोड़ सकते हैं।`,
            slideDrawingInstruction: `ओपन स्लाइड' पर क्लिक करें, एक मार्कर रंग चुनें और स्लाइड पर ड्राइंग शुरू करें। चलो चलें!`,
            imageUploadSingle: `प्रतिक्रिया के रूप में एक छवि सबमिट करें। आप अपने डिवाइस से फोटो ढूंढ सकते हैं या Google से खोज सकते हैं।`,
            imageUploadMultiple: `अपनी लाइब्रेरी से एक तस्वीर ढूंढें या Google से खोजें। आप <mark>ज़्यादा से ज़्यादा ${activityProps.numOfSubmissionsAllowed}</mark> जवाब जोड़ सकते हैं।`,
            audioRecordInstruction: `अपनी लाइब्रेरी से एक ऑडियो चुनें, या अपनी ऑडियो रिकॉर्डिंग शुरू करने के लिए नीचे दिए गए बटन पर क्लिक करें।`,
            videoUploadInstruction: `अपनी लाइब्रेरी से एक वीडियो चुनें, या एक नया कैप्चर करें।`,
            returnToPollInstruction: `मतदान पर लौटने के लिए बटन पर क्लिक करें।`,
        },
        id: {
            multipleChoiceSingle: `pilih <mark>HANYA SATU</mark> jawaban di pertanyaan ini dan tekan Kirim.`,
            multipleChoiceMultiple: `pilih <mark>SATU atau LEBIH</mark> jawaban di pertanyaan ini dan tekan Kirim.`,
            wordCloudSingle: `tanggapi pertanyaan saya dengan kata atau frasa. Ayo coba!`,
            wordCloudMultiple: `tanggapi pertanyaan saya dengan kata atau frasa. Anda bisa kirimkan <mark>hingga ${activityProps.numOfSubmissionsAllowed}</mark> jawaban.`,
            fillBlanksTypeResponse: `ketik jawaban untuk setiap bagian yang kosong dan tekan Kirim.`,
            fillBlanksTypeCorrectAnswer: `ketik jawaban yang benar untuk setiap bagian yang kosong dan tekan Kirim.`,
            shortAnswerSingle: `tulis jawaban Anda dibawah ini dan tekan kirim.`,
            shortAnswerMultiple: `ketik jawaban singkat di bawah ini dan kirimkan. Anda bisa menambahkan hingga ${activityProps.numOfSubmissionsAllowed} jawaban.`,
            slideDrawingInstruction: `klik 'Buka slide', pilih warna penanda dan mulai menggambar di slide. Ayo kita coba!`,
            imageUploadSingle: `kirim gambar/foto sebagai jawaban. Anda dapat mencari foto di galeri atau cari dari Google.`,
            imageUploadMultiple: `cari gambar dari galeri atau cari dari Google. Anda bisa menambahkan hingga ${activityProps.numOfSubmissionsAllowed} jawaban.`,
            audioRecordInstruction: `pilih audio dari galeri atau tekan tombol di bawah ini untuk merekam audio.`,
            videoUploadInstruction: `pilih audio dari galeri atau rekam yang baru.`,
            returnToPollInstruction: `klik tombol untuk kembali ke polling.`,
        },
        ja: {
            multipleChoiceSingle: `この質問に対する回答を<mark>1つだけ</mark>選択して、[送信] をクリックしてください。`,
            multipleChoiceMultiple: `この質問に対する回答を<mark>1つ以上</mark>選択して、[送信] をクリックしてください。`,
            wordCloudSingle: `私の質問に単語または語句で答えてください。やってみましょう！`,
            wordCloudMultiple: `私の質問に単語または語句で答えてください。<mark>${activityProps.numOfSubmissionsAllowed}件まで</mark>回答を送信できます。`,
            fillBlanksTypeResponse: `各空欄に回答を入力して、[送信] をクリックしてください。`,
            fillBlanksTypeCorrectAnswer: `各空欄に正しい答えを入力して、[送信] をクリックしてください。`,
            shortAnswerSingle: `以下に短い回答を入力して送信してください。`,
            shortAnswerMultiple: `以下に短い回答を入力して送信してください。<mark>${activityProps.numOfSubmissionsAllowed}件まで</mark>回答を追加できます。`,
            slideDrawingInstruction: `[スライドを開く] をクリック後、マーカーの色を選択して、スライドに描画を開始してください。始めましょう！`,
            imageUploadSingle: `回答として画像を送信します。デバイスから写真を探したり、Googleで検索したりできます。`,
            imageUploadMultiple: `ライブラリから写真を探すか、Googleで検索してください。<mark>${activityProps.numOfSubmissionsAllowed}件まで</mark>回答を追加できます。`,
            audioRecordInstruction: `ライブラリから音声を選択するか、以下のボタンをクリックして音声録音を開始してください。`,
            videoUploadInstruction: `ライブラリから動画を選択するか、新しい動画を録画してください。`,
            returnToPollInstruction: `投票に戻るにはボタンをクリックしてください。`,
        },
        ms: {
            multipleChoiceSingle: `Pilih <mark>SATU</mark> jawapan untuk soalan ini dan klik Hantar.`,
            multipleChoiceMultiple: `Pilih <mark>SATU ATAU LEBIH</mark> jawapan untuk soalan ini dan klik Hantar.`,
            wordCloudSingle: `Balas soalan ini dengan perkataan atau frasa. Jom cuba!`,
            wordCloudMultiple: `Balas soalan ini dengan perkataan atau frasa. Anda boleh hantar lebih dari satu respons.`,
            fillBlanksTypeResponse: `Taipkan respon anda dalam tempat kosong dan klik Hantar.`,
            fillBlanksTypeCorrectAnswer: `Taipkan jawapan betul dalam tempat kosong dan klik Hantar.`,
            shortAnswerSingle: `Taipkan jawapan anda di bawah dan hantar. <mark>SATU SAHAJA</mark> jawapan dibenarkan.`,
            shortAnswerMultiple: `Taipkan jawapan anda di bawah dan hantar. Anda boleh tambah ke ${activityProps.numOfSubmissionsAllowed} jawapan.`,
            slideDrawingInstruction: `Klik 'Buka slaid', pilih warna marker dan mula lukis dalam slaid. Jom!`,
            imageUploadSingle: `Cari gambar dari library anda atau Google. <mark>SATU SAHAJA</mark> jawapan dibenarkan.`,
            imageUploadMultiple: `Cari gambar dari libary anda atau Google. Anda boleh tambah ke ${activityProps.numOfSubmissionsAllowed} jawapan.`,
            audioRecordInstruction: `pilih audio dari library anda, atau klik button bawah untuk mulakan rakaman audio.`,
            videoUploadInstruction: `pilih video dari library anda, atau ambil yang baru.`,
            returnToPollInstruction: `klik button untuk balik ke poll.`,
        },
        pt: {
            multipleChoiceSingle: `selecione <mark>SOMENTE UMA</mark> resposta para esta pergunta e clique em enviar.`,
            multipleChoiceMultiple: `selecione <mark>UMA ou MAIS</mark> respostas para esta pergunta e clique em enviar.`,
            wordCloudSingle: `responda minha pergunta com uma palavra ou expressão. Vamos lá`,
            wordCloudMultiple: `responda minha pergunta com uma palavra ou expressão. Podes enviar <mark>até ${activityProps.numOfSubmissionsAllowed}</mark> respostas.`,
            fillBlanksTypeResponse: `digite uma resposta para cada lacuna e clique em Enviar.`,
            fillBlanksTypeCorrectAnswer: `digite a resposta correta para cada lacuna e clique em Enviar.`,
            shortAnswerSingle: `digite sua resposta curta abaixo e envie.`,
            shortAnswerMultiple: `digite sua resposta curta abaixo e envie. Podes enviar <mark>até ${activityProps.numOfSubmissionsAllowed}</mark> respostas.`,
            slideDrawingInstruction: `clique em 'Abrir slide', selecione uma cor de marcador e comece a desenhar. Vamos lá!`,
            imageUploadSingle: `envie uma imagem como resposta. Selecione uma foto da sua biblioteca ou pesquise no Google.`,
            imageUploadMultiple: `selecione uma foto da sua biblioteca ou pesquise no Google. Podes enviar <mark>até ${activityProps.numOfSubmissionsAllowed}</mark> respostas.`,
            audioRecordInstruction: `selecione um áudio da sua biblioteca ou clique no botão abaixo para gravar um novo áudio,`,
            videoUploadInstruction: `selecione um vÌdeo da sua biblioteca ou grave um novo vÌdeo.`,
            returnToPollInstruction: `clique no botão para retornar pergunta.`,
        },
        ru: {
            multipleChoiceSingle: `выберите <mark>ТОЛЬКО ОДИН</mark> ответ на этот вопрос и нажмите «Отправить».`,
            multipleChoiceMultiple: `выберите <mark>ОДИН или БОЛЕЕ</mark> ответов на этот вопрос и нажмите «Отправить».`,
            wordCloudSingle: `ответьте на мой вопрос одним словом или фразой. Начнем!`,
            wordCloudMultiple: `ответьте на мой вопрос одним словом или фразой. Вы можете отправить <mark>не более ${activityProps.numOfSubmissionsAllowed}</mark> ответов.`,
            fillBlanksTypeResponse: `заполните каждый пропуск и нажмите «Отправить».`,
            fillBlanksTypeCorrectAnswer: `введите правильный ответ в каждый пропуск и нажмите «Отправить».`,
            shortAnswerSingle: `введите свой краткий ответ ниже и отправьте.`,
            shortAnswerMultiple: `введите свой краткий ответ ниже и отправьте. Вы можете добавить <mark>не более ${activityProps.numOfSubmissionsAllowed}</mark> ответов`,
            slideDrawingInstruction: `нажмите «Открыть слайд», выберите цвет маркера и начните рисовать на слайде. Приступим!`,
            imageUploadSingle: `отправить изображение в качестве ответа. Вы можете найти и загрузить фото со своего устройства или выполнить поиск в Google.`,
            imageUploadMultiple: `найдите фотографию в своей библиотеке или выполните поиск в Google. Вы можете добавить <mark>не более ${activityProps.numOfSubmissionsAllowed}</mark> ответов.`,
            audioRecordInstruction: `выберите аудио файл из своей библиотеки или нажмите кнопку ниже, чтобы записать аудио.`,
            videoUploadInstruction: `выберите видео из своей библиотеки или снимите новое.`,
            returnToPollInstruction: `нажмите на кнопку, чтобы вернуться к опросу.`,
        },
        th: {
            multipleChoiceSingle: `เลือกเพียง <mark> 1 </mark> คำตอบ แล้วกด "ส่ง"`,
            multipleChoiceMultiple: `เลือก <mark> 1 หรือมากกว่า 1 </mark> คำตอบ แล้วกด "ส่ง"`,
            wordCloudSingle: `ตอบคำถามด้วยคำหรือข้อความ เริ่มเลย！`,
            wordCloudMultiple: `ตอบคำถามด้วยคำหรือข้อความ สามารถตอบได้ <mark> สูงสุด ${activityProps.numOfSubmissionsAllowed} </mark> คำตอบ.`,
            fillBlanksTypeResponse: `เติมคำตอบในแต่ละช่องว่าง แล้วกด "ส่ง"`,
            fillBlanksTypeCorrectAnswer: `เติมคำตอบที่ถูกต้องในแต่ละช่องว่าง แล้วกด "ส่ง"`,
            shortAnswerSingle: `พิมพ์คำตอบด้านล่าง แล้วกด "ส่ง"`,
            shortAnswerMultiple: `พิมพ์คำตอบสั้นๆ ของคุณด้านล่าง แล้วกดส่ง สามารถตอบได้สูงสุด ${activityProps.numOfSubmissionsAllowed} คำตอบ`,
            slideDrawingInstruction: `กด "เปิดสไลด์" เลือกปากกาแล้วเริ่มวาดได้เลย`,
            imageUploadSingle: `ส่งภาพเป็นคำตอบ โดยสามารถเลือกภาพได้จากทั้งในอุปกรณ์หรือค้นหาบน Google`,
            imageUploadMultiple: `เลือกรูปภาพจากอุปกรณ์ของคุณหรือค้นหาออนไลน์ สามารถเลือกได้สูงสุด ${activityProps.numOfSubmissionsAllowed} ภาพ.`,
            audioRecordInstruction: `เลือกเสียงจากอุปกรณ์ของคุณ หรือกดปุ่มด้านล่างเพื่อเริ่มการบันทึกเสียง`,
            videoUploadInstruction: `เลือกวิดีโอจากอุปกรณ์ของคุณ หรือถ่ายวิดีโอใหม่`,
            returnToPollInstruction: `กดปุ่มเพื่อกลับไปยัง Poll`,
        },
        tw: {
            multipleChoiceSingle: `請選擇 <mark>一個</mark> 答案並點擊提交。`,
            multipleChoiceMultiple: `請選擇 <mark>一個或多個</mark> 答案並點擊提交。`,
            wordCloudSingle: `請提交一個詞語參與作答。`,
            wordCloudMultiple: `請提交詞語參與作答，您能提交 <mark>最多 ${activityProps.numOfSubmissionsAllowed}</mark> 個回答。`,
            fillBlanksTypeResponse: `請填寫每個空格，並點擊提交。`,
            fillBlanksTypeCorrectAnswer: `請填寫每個空格，並點擊提交。`,
            shortAnswerSingle: `請回答下方簡答題並提交。`,
            shortAnswerMultiple: `請回答下方簡答題並提交。您能新增 <mark>最多 ${activityProps.numOfSubmissionsAllowed}</mark> 個答案。`,
            slideDrawingInstruction: `點擊"開啟題目"，選擇顏色並開始書寫投影片。`,
            imageUploadSingle: `從您的電腦選擇或 Google 搜尋圖片，提交圖片答案。。`,
            imageUploadMultiple: `從您的電腦選擇或 Google 搜尋圖片。您能提交 <mark>最多 ${activityProps.numOfSubmissionsAllowed}</mark> 個答案。`,
            audioRecordInstruction: `從您的電腦選擇音檔，或點擊下方按鈕開始錄音。`,
            videoUploadInstruction: `從您的電腦選擇影片，或用相機錄製上傳。`,
            returnToPollInstruction: `點擊按鈕回到投票`,
        },
        vi: {
            multipleChoiceSingle: `bạn chỉ được chọn <mark>MỘT</mark> đáp án cho câu hỏi này, sau đó hãy ấn Nộp bài.`,
            multipleChoiceMultiple: `bạn có thể chọn <mark>MỘT hoặc NHIỀU HƠN MỘT</mark> đáp án cho câu hỏi này, sau đó hãy ấn Nộp bài.`,
            wordCloudSingle: `hãy trả lời câu hỏi của tôi bằng một từ, cụm từ, hoặc một câu. Bắt đầu trả lời thôi nào!`,
            wordCloudMultiple: `hãy trả lời câu hỏi này bằng một từ, cụm từ, hoặc một câu. Bạn có thể nộp <mark>tối đa ${activityProps.numOfSubmissionsAllowed}</mark> câu trả lời.`,
            fillBlanksTypeResponse: `gõ câu trả lời vào chỗ trống, sau đó hãy ấn Nộp bài.`,
            fillBlanksTypeCorrectAnswer: `gõ đáp án của bạn vào chỗ trống, sau đó hãy ấn Nộp bài.`,
            shortAnswerSingle: `gõ câu trả lời của bạn tại ô trống dưới đây và nộp bài.`,
            shortAnswerMultiple: `gõ câu trả lời của bạn vào ô dưới đây và ấn Nộp bài. Bạn được phép gửi tối đa ${activityProps.numOfSubmissionsAllowed} câu trả lời.`,
            slideDrawingInstruction: `ấn chọn 'Mở slide', chọn một bút vẽ, sau đó bạn có thể tuỳ ý vẽ trên slide. Bắt đầu thôi!`,
            imageUploadSingle: `nộp bài bằng một hình ảnh. Bạn có thể chọn ảnh có sẵn trong thiết bị hoặc tìm kiếm hình ảnh qua Google.`,
            imageUploadMultiple: `tìm một ảnh trong thư viện ảnh của bạn hoặc tìm kiếm online qua Google. Bạn được phép gửi tối đa ${activityProps.numOfSubmissionsAllowed} câu trả lời.`,
            audioRecordInstruction: `chọn một file âm thanh từ thiết bị của bạn, hoặc ấn nút dưới đây để bắt đầu thu âm câu trả lời của bạn.`,
            videoUploadInstruction: `chọn một video từ thư viện của bạn, hoặc quay một video mới`,
            returnToPollInstruction: `ấn nút này để quay lại phần thăm dò ý kiến.`,
        },
        zh: {
            multipleChoiceSingle: `请选择 <mark>一个</mark> 答案。点击"提交"作答。`,
            multipleChoiceMultiple: `请选择 <mark>一个或多个</mark> 答案。点击“提交”作答。`,
            wordCloudSingle: `请提交词语参与作答。`,
            wordCloudMultiple: `请提交词语参与作答。您可以提交<mark>最多 ${activityProps.numOfSubmissionsAllowed} </mark> 个词语。`,
            fillBlanksTypeResponse: `请填写每个空格，点击“提交”作答。`,
            fillBlanksTypeCorrectAnswer: `请填写每个空格，点击“提交”作答。`,
            shortAnswerSingle: `请在下列文字框中输入您的作答并提交。`,
            shortAnswerMultiple: `请填写答案，点击“提交”作答。你可以提交 ${activityProps.numOfSubmissionsAllowed} 个作答。`,
            slideDrawingInstruction: `请点击“打开题目”，开始书写作答。出发！`,
            imageUploadSingle: `请提交图片作答。您可以从您的设备选择图片或者进行谷歌搜索查找图片。`,
            imageUploadMultiple: `请从本机选择图片或谷歌搜索图片上传。你可以上传 ${activityProps.numOfSubmissionsAllowed} 张图片。`,
            audioRecordInstruction: `请从本机选择音频文件，或点击下面按钮开始录音。`,
            videoUploadInstruction: `请从本机选择视频文件上传，或使用摄像头录制上传。`,
            returnToPollInstruction: `点击按钮返回投票。`,
        },
    };
    return PresenterInstructionMessages;
};
