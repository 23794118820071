import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';

import classCodeSchema from '../../../validation/classCodeSchema';
import participantNameSchema from '../../../validation/participantNameSchema';
import { joinClass } from '../../../actions/signalr.actions';
import JoinStepOne from './JoinStepOne';
import JoinStepTwo from './JoinStepTwo';
import AvatarConstants from '../../../constants/avatar.constants';
import httpService from '../../../services/httpService';
import { CurrentView } from '../../../constants/current-view.enum';
import { participantActions } from '../../../actions/participant.actions';
import { ParticipantJoinInputInterface } from '../../../dtos/participant-join-input.dto';
import { StoreInterface } from '../../../interfaces/store.interface';
import { PresetBackgrounds } from '../../../constants/preset-backgrounds';
import JoinFailed from './JoinFailed';
// import { Globe } from '../../../Icon';
import ChangeLanguageModal from '../ClassRoom/Header/ChangeLanguageModal';

import './Join.scss';
import { getValidImageUrls, selectRandomElementsFromArray } from '../../../helpers/utils';
import { logger } from '../../../services/logger';
import { InterfaceLanguages } from '../../../constants/languages-constant';
import ReDirecting from './ReDirecting';
import { ActionTypes } from '../../../constants/action-types.enum';

const presetAvatars = AvatarConstants.AVATAR_IMAGES.sort(() => 0.5 - Math.random()).slice(0, 9);

const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

const Join = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const store = useSelector((state: StoreInterface) => state);
    const participant = store.participant;

    const autoPlayRef = useRef<() => void>();

    const [codeInUrl] = useState<string>(useParams<any>().urlClassCode);
    const [currentStep, setCurrentStep] = useState(1);
    const [classCode, setClassCode] = useState('');
    const [classCodeErrorMessage, setClassCodeErrorMessage] = useState('');
    const [cpcsRegion, setCpcsRegion] = useState('');
    const [presenterEmail, setPresenterEmail] = useState('');
    const [participantUsername, setParticipantUsername] = useState(store.participant.participantUsername);
    const [participantNameErrorMessage, setParticipantNameErrorMessage] = useState('');
    // const [selectedAvatar, setSelectedAvatar] = useState(presetAvatars[0]);
    const [selectedAvatar, setSelectedAvatar] = useState({ id: 0, imagePath: '' });
    // const [backgroundImageUrl, setBackgroundImageUrl] = useState(
    //     PresetBackgrounds[Math.floor(Math.random() * PresetBackgrounds.length)],
    // );
    // const [unsplashImages, setUnsplashImages] = useState<string[]>(PresetBackgrounds);

    const [selectedBackgroundImages, setSelectedBackgroundImages] = useState(
        selectRandomElementsFromArray(PresetBackgrounds, 5),
    );
    const [activeImageIndex, setActiveImageIndex] = useState(0);

    const [isSubmittingStepOne, setIsSubmittingStepOne] = useState(false);
    const [isSubmittingStepTwo, setIsSubmittingStepTwo] = useState(false);
    const [openChangeLanguage, setOpenChangeLanguage] = useState(false);
    const [redirectToCp1, setRedirectToCp1] = useState(false);

    const closeChangeLanguage = () => setOpenChangeLanguage(false);

    const currentLanguage = participant.language;
    let codeInQueryParams = useQuery().get('code');

    autoPlayRef.current = () => {
        if (activeImageIndex === selectedBackgroundImages.length - 1) setActiveImageIndex(0);
        else setActiveImageIndex((prevState) => prevState + 1);
    };

    useEffect(() => {
        if (codeInQueryParams) setAndValidateCodeInUrl(codeInQueryParams);
        if (codeInUrl) {
            // console.log('codeInUrl', codeInUrl);
            if (codeInUrl.trim().toLowerCase() !== 'join')
                window.location.href = `${process.env.REACT_APP_CP1_CLIENT}/${codeInUrl}`;
        }
        // getUnsplashImages();
        setRedirectToCp1(false);

        const changeActiveImage = () => autoPlayRef.current && autoPlayRef.current();
        const changeArticleTimeout = setInterval(changeActiveImage, 10000);
        return () => clearInterval(changeArticleTimeout);
    }, []);

    const setAndValidateCodeInUrl = async (codeInUrl: string) => {
        try {
            await classCodeSchema.validateAsync({
                classCode: codeInUrl,
            });
            setClassCode(codeInUrl.toUpperCase());
        } catch (e: any) {
            logger.error(e);
        }
    };

    // const getUnsplashImages = async () => {
    //     const imageUrls = await httpService.getUnsplashImages();
    //     if (imageUrls.length > 0)
    //         getValidImageUrls(imageUrls).then((result: any) => {
    //             setUnsplashImages(result);
    //         });
    // };

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         refreshBackgorundImage();
    //     }, 10000);
    //     return () => clearInterval(interval);
    // }, [backgroundImageUrl, unsplashImages]);

    // const refreshBackgorundImage = () => {
    //     if (unsplashImages.length === 0) return;
    //     const currentIndex = unsplashImages.findIndex((url) => url === backgroundImageUrl);
    //     let nextIndex: number;
    //     if (currentIndex < 0 || currentIndex >= unsplashImages.length - 1) nextIndex = 0;
    //     else nextIndex = currentIndex + 1;
    //     setBackgroundImageUrl(unsplashImages[nextIndex]);
    // };

    useEffect(() => {
        if (participant.classCode) {
            setClassCode(participant.classCode);
        }
    }, [participant]);

    useEffect(() => {
        if (store.currentView === CurrentView.NAME_TAKEN) {
            setParticipantNameErrorMessage(t('lang_join.err_name_taken'));
            setIsSubmittingStepTwo(false);
        } else setParticipantNameErrorMessage('');
    }, [store.currentView]);

    const backToStepTwo = () => {
        dispatch(participantActions.backToStepTwo());
        setIsSubmittingStepTwo(false);
    };

    const setAndValidateClassCode = async (classCode: string) => {
        try {
            await classCodeSchema.validateAsync({
                classCode,
            });
            setClassCodeErrorMessage('');
            return true;
        } catch (error: any) {
            setClassCodeErrorMessage(t(`lang_join.${error.message}`));
            return false;
        }
    };

    const handleKeypress = (e: any) => {
        //it triggers by pressing the enter key
        if (e.key === 'Enter') {
            submitStepOne(e);
        }
    };

    const handleParticipantUsernameChange = async (participantUsername: string) => {
        setParticipantUsername(participantUsername);
        if (store.currentView !== 0) backToStepTwo();
        validateParticipantUsername(participantUsername);
    };

    const validateParticipantUsername = async (participantUsername: string) => {
        try {
            await participantNameSchema.validateAsync({
                participantUsername,
            });
            setParticipantNameErrorMessage('');
            return true;
        } catch (error: any) {
            setParticipantNameErrorMessage(t(`lang_join.${error.message}`));
            return false;
        }
    };

    const submitStepOne = async (e: any) => {
        if (!(await setAndValidateClassCode(classCode))) return;

        setIsSubmittingStepOne(true);
        const presenterEmailAndRegion = await httpService.getPresenterEmailAndRegion(classCode);
        // console.log('presenterEmailAndRegion', presenterEmailAndRegion);
        setIsSubmittingStepOne(false);
        if (typeof presenterEmailAndRegion === 'number') {
            if (presenterEmailAndRegion === 404)
                return setClassCodeErrorMessage(t(`lang_join.err_code_invalid_or_not_in_use`));
            if (presenterEmailAndRegion === 200) {
                setRedirectToCp1(true);
                setTimeout(() => {
                    window.location.href = `${process.env.REACT_APP_CP1_CLIENT}/${classCode}`;
                }, 4000);
                return;
            }
        } else {
            if (!presenterEmailAndRegion) return dispatch(participantActions.showApiError());
            setPresenterEmail(presenterEmailAndRegion.presenterEmail);
            setCpcsRegion(presenterEmailAndRegion.cpcsRegion);
            setCurrentStep(2);
            dispatch(
                participantActions.classCodeValidated(
                    classCode,
                    presenterEmailAndRegion.presenterEmail,
                    presenterEmailAndRegion.cpcsRegion,
                ),
            );
        }
    };

    const submitStepTwo = async () => {
        if (!(await validateParticipantUsername(participantUsername))) return;

        backToStepTwo();
        setIsSubmittingStepTwo(true);
        dispatch(participantActions.updateUsernameAndAvatar(participantUsername, selectedAvatar.imagePath));

        // validate join before connecting websocket
        const validateJoinError = await httpService.validateJoin(
            cpcsRegion,
            presenterEmail,
            classCode,
            store.participant.deviceId,
            participantUsername,
        );
        if (validateJoinError) {
            setIsSubmittingStepTwo(false);
            if (validateJoinError === 'class_code_not_match') {
                setCurrentStep(1);
                return setClassCodeErrorMessage(t(`lang_join.err_code_invalid_or_not_in_use`));
            }
            if (validateJoinError === 'class_is_locked') return dispatch({ type: ActionTypes.CLASS_LOCKED });
            if (validateJoinError === 'class_is_full') return dispatch({ type: ActionTypes.CLASS_FULL });
            if (validateJoinError === 'not_allow_guests') return dispatch({ type: ActionTypes.NOT_ALLOW_GUESTS });
            if (validateJoinError === 'duplicate_name') return dispatch({ type: ActionTypes.NAME_TAKEN });
            if (validateJoinError === 'error') return dispatch({ type: ActionTypes.SHOW_API_ERROR });
        } else {
            const participantInput: ParticipantJoinInputInterface = {
                participantUsername: participantUsername,
                // currently participantName and participantUsername are same
                participantName: participantUsername,
                participantId: store.participant.deviceId,
                participantAvatar: selectedAvatar.imagePath,
                cpcsRegion,
                presenterEmail,
                classSessionId: '',
            };
            dispatch(joinClass(participantInput));
        }
    };

    const renderSteps = () => {
        if (
            [CurrentView.CLASS_LOCKED, CurrentView.CLASS_FULL, CurrentView.NOT_ALLOW_GUESTS].includes(store.currentView)
        ) {
            return <JoinFailed classCode={classCode} backToStepTwo={backToStepTwo} />;
        } else if (currentStep === 1) {
            return (
                <>
                    {!redirectToCp1 ? (
                        <JoinStepOne
                            classCode={classCode}
                            classCodeErrorMessage={classCodeErrorMessage}
                            submitStepOne={submitStepOne}
                            handleKeypress={handleKeypress}
                            setClassCode={setClassCode}
                            setClassCodeErrorMessage={setClassCodeErrorMessage}
                            isSubmitting={isSubmittingStepOne}
                            setIsSubmitting={setIsSubmittingStepOne}
                        />
                    ) : (
                        <ReDirecting />
                    )}
                </>
            );
        } else if (currentStep === 2) {
            return (
                <JoinStepTwo
                    cpcsRegion={cpcsRegion}
                    classCode={classCode}
                    presenterEmail={presenterEmail}
                    presetAvatars={presetAvatars}
                    selectedAvatar={selectedAvatar}
                    setSelectedAvatar={setSelectedAvatar}
                    participantUsername={participantUsername}
                    participantNameErrorMessage={participantNameErrorMessage}
                    onParticipantUsernameChange={handleParticipantUsernameChange}
                    submitStepTwo={submitStepTwo}
                    isSubmittingStepTwo={isSubmittingStepTwo}
                    setIsSubmittingStepTwo={setIsSubmittingStepTwo}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <div
            className="classpooint_joining"
            style={{
                backgroundImage: `url(${selectedBackgroundImages[activeImageIndex]})`,
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div className="language_change_btn" onClick={() => setOpenChangeLanguage(true)}>
                {/* <Globe /> */}
                <img
                    src={InterfaceLanguages.find((language) => language.code === currentLanguage)?.country_flag}
                    alt="language"
                />
                <Typography variant="h3">
                    {InterfaceLanguages.find((language) => language.code === currentLanguage)?.nameShort}
                </Typography>
            </div>
            <div style={{ display: 'none' }}>
                {selectedBackgroundImages.map((url, index) => (
                    <img src={url} alt={'background'} key={index} />
                ))}
            </div>
            <Card className="join_card">
                <CardContent className="h_100">{renderSteps()}</CardContent>
            </Card>
            <ChangeLanguageModal openChangeLanguage={openChangeLanguage} closeChangeLanguage={closeChangeLanguage} />
        </div>
    );
};

export default Join;
