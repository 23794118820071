import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Constants from '../../../../../../constants';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import { ActivityPropsInterface } from '../../../../../../interfaces/activity.interface';
import MultipleChoiceShowCorrectAnswerModal from './MultipleChoiceShowCorrectAnswerModal';

function MultipleChoiceResponse() {
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const mcChoices = activityProps.mcChoices as string[];
    const mcCorrectAnswers = activityProps.mcCorrectAnswers as string[];

    return (
        <Fragment>
            <div className="chat_text_group">
                <div className="chat_text">
                    <div className="multi_ans">
                        {activity.submittedResponses.length > 0 &&
                            JSON.parse(activity.submittedResponses[0].responseData).map((response: string) => (
                                <span
                                    key={activity.submittedResponses[0].responseData.indexOf(response)}
                                    className={Constants.COLOR_NAMES_ARRAY[mcChoices.indexOf(response)]}
                                >
                                    {response}
                                </span>
                            ))}
                    </div>
                </div>
            </div>
            {mcCorrectAnswers.length > 0 && <MultipleChoiceShowCorrectAnswerModal />}
        </Fragment>
    );
}

export default MultipleChoiceResponse;
