import { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './Qna.module.scss';

import { StoreInterface } from '../../../../../interfaces/store.interface';

// import { QUESTION } from '../../../../../Icon';
import QnaModal from './components/QnaModal/QnaModal';

const Qna = () => {
    const qnaData = useSelector((state: StoreInterface) => state.qnaData);

    const [qnaModalView, setQnaModalView] = useState<'view' | 'ask'>();

    const qnaModalViewHandler = (view: 'view' | 'ask' | undefined) => {
        setQnaModalView(view);
    };

    return (
        <>
            <QnaModal qnaModalView={qnaModalView} qnaModalViewHandler={qnaModalViewHandler} />
            <div className={styles.qna} onClick={() => setQnaModalView(qnaData.qnas.length > 0 ? 'view' : 'ask')}>
                {/* <i className={styles.icon}>
                    <QUESTION />
                </i> */}
                <p className={styles.text}>Q & A</p>
            </div>
        </>
    );
};

export default Qna;
