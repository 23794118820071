import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, styled, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import {
    calculateParticipantLevelAndBadge,
    getLevelsArray,
    percentCalculator,
} from '../../../../../../helpers/userHelper';

import { StoreInterface } from '../../../../../../interfaces/store.interface';
import Constants from '../../../../../../constants';

import CustomAvatar, { AvatarType } from '../../../../../Common/CustomAvatar';

import { Plus } from '../../../../../../Icon';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const ParticipantInfo = () => {
    const { t } = useTranslation();
    const participant = useSelector((state: StoreInterface) => state.participant);
    const presenter = useSelector((state: StoreInterface) => state.presenter);
    const currentClass = useSelector((state: StoreInterface) => state.currentClass);
    const classGroups = useSelector((state: StoreInterface) => state.currentClass.groups);
    const currentGroup = currentClass.groups?.find((group) => group.groupId === currentClass.groupId);

    const levelsArray = getLevelsArray(presenter);
    const isProUser = presenter.isOnPro || false;

    const [participantPointSum, setParticipantPointSum] = useState(0);

    useEffect(() => {
        setParticipantPointSum(participant.participantPoints + participant.participantTotalPoints);
    }, [participant.participantPoints, participant.participantTotalPoints]);

    return (
        <div className={`right_card ${!isProUser && 'basicUser'}`}>
            <div className="rc_profile">
                <div className="avater_images">
                    <CustomAvatar
                        avatarName={participant.participantName}
                        avatarUrl={participant.participantAvatar}
                        type={AvatarType.PARTICIPANT}
                        savedClassGroups={classGroups}
                        groupId={participant.groupId}
                    />
                </div>
                <div className="profilename">
                    <div className={`details`}>
                        <Typography variant="h4">{`${participant.participantName}`}</Typography>
                        {currentGroup && (
                            <p style={{ background: currentGroup.groupColor }} className={`groupName`}>
                                {currentGroup.groupName}
                            </p>
                        )}
                    </div>

                    {isProUser && (
                        <>
                            <div className="pro_user_profile_data">
                                <img
                                    src={calculateParticipantLevelAndBadge(participantPointSum, levelsArray).badgeUrl}
                                    alt="level_icon"
                                    className="level_icon"
                                />
                                <div className="Pro_user_content">
                                    <Box sx={{ flexGrow: 1 }} className="cpprogressbar">
                                        <BorderLinearProgress
                                            variant="determinate"
                                            value={percentCalculator(participantPointSum, levelsArray)}
                                        />
                                    </Box>
                                    <div className="current_level">
                                        {calculateParticipantLevelAndBadge(participantPointSum, levelsArray).level ===
                                        levelsArray.length ? (
                                            <Typography variant="caption">
                                                {t(`lang_new.you_are_on_top_level`)}
                                            </Typography>
                                        ) : (
                                            <Typography variant="caption">
                                                {t(`lang_participant_main.txt_lvl_details`, {
                                                    pointsNeed:
                                                        levelsArray[
                                                            calculateParticipantLevelAndBadge(
                                                                participantPointSum,
                                                                levelsArray,
                                                            ).level
                                                        ] -
                                                        participant.participantPoints -
                                                        participant.participantTotalPoints,
                                                    level:
                                                        calculateParticipantLevelAndBadge(
                                                            participantPointSum,
                                                            levelsArray,
                                                        ).level + 1,
                                                })}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="level_point_sec">
                <div className="box points_box">
                    <div className="text">
                        <Typography variant="body1">
                            {isProUser ? t(`lang_participant_main.txt_total`) : t(`lang_participant_main.txt_stars`)}
                        </Typography>
                    </div>
                    <div className="box_content">
                        <img src={Constants.IMAGE_URLS.iconStar} alt="1" />
                        <Typography variant="h5">
                            {participant.participantTotalPoints +
                                participant.participantPoints -
                                participant.pointsBeingAdded}
                        </Typography>
                    </div>
                </div>
                {isProUser && (
                    <>
                        <div className="box points_box">
                            <div className="text">
                                <Typography variant="body1">{t(`lang_participant_main.txt_recent`)}</Typography>
                            </div>
                            <div className="box_content">
                                <Typography variant="h5" className="point_chip">
                                    <Plus />
                                </Typography>
                                <img src={Constants.IMAGE_URLS.iconStar} alt="1" />
                                <Typography variant="h5">
                                    {participant.participantPoints > 0
                                        ? participant.participantPoints - participant.pointsBeingAdded
                                        : '-'}
                                </Typography>
                            </div>
                        </div>
                        <div className="box level_box">
                            <div className="text">
                                <Typography variant="body1">{t(`lang_participant_main.txt_level`)}</Typography>
                            </div>
                            <div className="box_content">
                                <img
                                    src={calculateParticipantLevelAndBadge(participantPointSum, levelsArray).badgeUrl}
                                    alt="level_icon"
                                />
                                {/* <Typography variant="h5">
                                    {calculateParticipantLevelAndBadge(participantPointSum, levelsArray).level}
                                </Typography> */}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ParticipantInfo;
