import Joi from 'joi';

const participantNameSchema = Joi.object({
    participantUsername: Joi.string()
        .trim()
        .required()
        .error((errors: any) => {
            errors.forEach((err: any) => {
                switch (err.code) {
                    case 'string.empty':
                        err.message = 'err_name_required';
                        break;
                    case 'string.min':
                        err.message = 'err_name_too_short';
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
});

export default participantNameSchema;
