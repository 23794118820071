import { Box, Modal } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { activityActions } from '../../../../../../../actions/activity.actions';
import { v4 } from 'uuid';
import ResponseToSubmitDto from '../../../../../../../dtos/response-to-submit.dto';
import { dataURItoBlob } from '../../../../../../../helpers/utils';
import FabricCanvas from '../components/FabricCanvas';
import { StoreInterface } from '../../../../../../../interfaces/store.interface';
import { ActivityPropsInterface } from '../../../../../../../interfaces/activity.interface';
import httpServices from '../../../../../../../services/httpService';
import { participantActions } from '../../../../../../../actions/participant.actions';
import { calculateCanvasDimensions } from '../../../../../../../helpers/slideDrawingHelper';

interface SlideDrawingModalInterface {
    open: boolean;
    handleClose: () => void;
    // handleOpen: () => void;
    // isMobilelandscape: boolean;
    // isTablet: boolean;
}

const SlideDrawingModal = ({
    open,
    handleClose,
}: // handleOpen,
// isMobilelandscape,
// isTablet,
SlideDrawingModalInterface) => {
    const canvasRef: any = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [canvasDimension, setCanvasDimension] = useState({ width: 0, height: 0 });
    const [deviceMode, setDeviceMode] = useState<'mobile' | 'pc'>('mobile');

    const participant = useSelector((state: StoreInterface) => state.participant);
    const activity = useSelector((state: StoreInterface) => state.activity);
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const responseId = 'resp-' + v4();
    const dispatch = useDispatch();

    const uploadToServer = async () => {
        setIsSubmitting(true);
        canvasRef.current.storeCanvasData();
        const dataURL = localStorage.getItem('canvasDataUrl');
        const audioInput = dataURItoBlob(dataURL);
        const slideDrawingUrl = await httpServices.uploadToStorage(
            audioInput,
            `${responseId}.png`,
            participant.cpcsRegion,
            'user',
        );
        if (!slideDrawingUrl) {
            setIsSubmitting(false);
            dispatch(participantActions.showApiError());
            return;
        }

        const responseToSubmit: ResponseToSubmitDto = {
            activityId: activityProps.activityId,
            activityType: activityProps.activityType,
            responseId,
            responseData: slideDrawingUrl,
        };
        dispatch(activityActions.submitResponse(responseToSubmit));
        setIsSubmitting(false);
        discardCanvas();
    };

    // useEffect(() => {
    //     if (open) {
    //         storePreviousAndClose();
    //         setTimeout(() => handleOpen(), 100);
    //     }
    // }, [deviceMode]);

    const storePreviousAndClose = () => {
        if (canvasRef.current) canvasRef.current.storeCanvasState();
        handleClose();
    };

    const discardCanvas = () => {
        localStorage.removeItem('canvasData');
        localStorage.removeItem('canvasDataUrl');
        handleClose();
    };

    const resizeCanvas = async () => {
        if (!activityProps.activitySlideUrl) return;
        const dimensions = await calculateCanvasDimensions(activityProps.activitySlideUrl);
        setCanvasDimension(dimensions[0]);
        setDeviceMode(dimensions[1]);
    };

    useEffect(() => {
        discardCanvas();
    }, [activity]);

    useEffect(() => {
        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);
        return () => window.removeEventListener('resize', resizeCanvas);
    }, []);

    return (
        <Fragment>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={`slider_Upload_modal`}
                // className={isMobilelandscape ? 'slider_Upload_modal mobile_drawing_modal' : 'slider_Upload_modal'}
                disableEnforceFocus={true}
                disableAutoFocus={true}
            >
                <Box className="short_modalinner slideDrawingContainer">
                    {/* <div className="close_modal" onClick={storePreviousAndClose}>
                        <CloseRoundedIcon />
                    </div>
                    <div className="short_ans_head" onMouseDown={(e) => {}}>
                        <img src={Constants.IMAGE_URLS.slideDrawingIcon} alt="msgicon" />
                        <Typography variant="h4"> {t(`lang_activity_names.Slide Drawing`)} </Typography>
                    </div>
                    <Divider /> */}
                    <div
                        className={`slide_upload_warp ${deviceMode === 'mobile' && 'mobileView'} ${
                            deviceMode === 'mobile' && canvasDimension.height === 300 && 'heightFull'
                        }`}
                    >
                        <FabricCanvas
                            backgroundImage={activityProps.activitySlideUrl}
                            canvasRef={canvasRef}
                            canvasDimension={canvasDimension}
                            deviceMode={deviceMode}
                            // isMobilelandscape={isMobilelandscape}
                            // isTablet={isTablet}
                            discardCanvas={discardCanvas}
                            isSubmitting={isSubmitting}
                            uploadToServer={uploadToServer}
                            storePreviousAndClose={storePreviousAndClose}
                        />
                    </div>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default SlideDrawingModal;
