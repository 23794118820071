import { Avatar, Box, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import './VotingImageUpload.scss';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Masonry from '@mui/lab/Masonry';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { activityActions } from '../../../../../actions/activity.actions';
import VotingImageUploadModal from './VotingImageUploadModal';
import _ from 'underscore';

const VotingImageUpload = ({ numOfVotesLeft, handleVote }: any) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [choosedResponse, setChoosedResponse] = useState<any>({});
    const [choosedResponseIndex, setChoosedResponseIndex] = useState<any>();
    const activity = useSelector((state: any) => state.activity);
    const votingResponses = activity.votingResponses ? activity.votingResponses : [];
    const participant = useSelector((state: any) => state.participant);

    const showNextNote = (currentIndex: number) => {
        if (currentIndex >= votingResponses.length - 1) {
            setChoosedResponseIndex(currentIndex);
            setChoosedResponse(votingResponses[currentIndex]);
        } else {
            setChoosedResponseIndex(currentIndex + 1);
            setChoosedResponse(votingResponses[currentIndex + 1]);
        }
    };

    const showPreviousNote = (currentIndex: number) => {
        if (currentIndex <= 0) {
            setChoosedResponseIndex(0);
            setChoosedResponse(votingResponses[0]);
        } else {
            setChoosedResponseIndex(currentIndex - 1);
            setChoosedResponse(votingResponses[currentIndex - 1]);
        }
    };

    const handleClickedImage = (data: any, dataIndex: number) => {
        setChoosedResponse(data);
        setChoosedResponseIndex(dataIndex);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    return (
        <Fragment>
            {/* <div className="card_head">
                <div className="card_head_left">
                    <Typography variant="h4">
                        <FavoriteBorderIcon />
                        Let’s vote!
                    </Typography>
                </div>
                <div className="card_head_right">
                    <Typography variant="h5">Votes to give:</Typography>
                    <Typography variant="h6">
                        <FavoriteIcon
                            className={numOfVotesLeft > 0 ? 'red' : ''}
                        />{' '}
                        x {numOfVotesLeft}
                    </Typography>
                </div>
            </div> */}
            <div className="imageUpload_card">
                <div className="inner_imagesupload">
                    <div className="imageUpload_wraper">
                        <div>
                            <div className="inner_image">
                                {activity.votingResponses.map((response: any, index: number) => {
                                    const isVotedByMe = response.voterParticipantIds.includes(
                                        participant.participantId,
                                    );
                                    return (
                                        <Stack key={index} className="images_boxs">
                                            <div
                                                className="big_sh_body"
                                                // onClick={() =>
                                                //     handleClickedImage(
                                                //         response,
                                                //         index,
                                                //     )
                                                // }
                                            />
                                            <div className="sh_footer">
                                                <div className="profile">
                                                    <Avatar
                                                        alt={response.participantName}
                                                        src={response.participantAvatar}
                                                    />
                                                    <Typography variant="body1">{response.participantName}</Typography>
                                                </div>

                                                {!isVotedByMe && (
                                                    <div className="like_count">
                                                        <FavoriteBorderIcon
                                                            onClick={() => {
                                                                if (numOfVotesLeft > 0)
                                                                    handleVote(response.responseId, true);
                                                            }}
                                                        />
                                                        <Typography variant="caption">
                                                            {response.voterParticipantIds.length}
                                                        </Typography>
                                                    </div>
                                                )}
                                                {isVotedByMe && (
                                                    <div className="like_count">
                                                        <FavoriteIcon
                                                            className="red stroke"
                                                            onClick={() => handleVote(response.responseId, false)}
                                                        />
                                                        <Typography variant="caption">
                                                            {response.voterParticipantIds.length}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                            <img
                                                src={`${JSON.parse(response.responseData)[0]}?w=162&auto=format`}
                                                srcSet={`${
                                                    JSON.parse(response.responseData)[0]
                                                }?w=162&auto=format&dpr=2 2x`}
                                                alt={response.participantName}
                                                loading="lazy"
                                                style={{
                                                    borderBottomLeftRadius: 4,
                                                    borderBottomRightRadius: 4,
                                                }}
                                            />
                                        </Stack>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <VotingImageUploadModal
                choosedResponse={choosedResponse}
                choosedResponseIndex={choosedResponseIndex}
                showPreviousNote={showPreviousNote}
                showNextNote={showNextNote}
                handleClose={handleClose}
                open={open}
                handleVote={handleVote}
            /> */}
        </Fragment>
    );
};

export default VotingImageUpload;
