import { combineReducers } from 'redux';
import connection from './connection.reducer';
import currentClass from './currentClass.reducer';
import participant from './participant.reducer';
import presenter from './presenter.reducer';
import currentView from './currentView.reducer';
import activity from './activity.reducer';
import leaderboard from './leaderboard.reducer';
import exportedPdf from './export-pdf.reducer';
import qnaData from './qna.reducer';

const rootReducer = combineReducers({
    connection,
    currentClass,
    participant,
    presenter,
    currentView,
    activity,
    leaderboard,
    exportedPdf,
    qnaData,
});

export default rootReducer;
