import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DoneIcon from '@mui/icons-material/Done';
import { Button, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from '@mui/lab/LoadingButton';

import { utilConstants } from '../../../../../../../constants/utils.constants';
import { TextIcon, Eraser, Pen, Highlighter, ShapeIcon, Undo } from './drawIcon';
import CanvasDraw from './CanvasDraw';
import LoaddingButton from '../../../../../../Common/LoadingButton';

enum EraserSize {
    Small = 30,
    Medium = 60,
    Large = 90,
}

interface FabricCanvasInterface {
    backgroundImage: string;
    canvasRef: any;
    canvasDimension: { width: number; height: number };
    deviceMode: 'mobile' | 'pc';
    // isMobilelandscape: boolean;
    // isTablet: boolean;
    discardCanvas: () => void;
    isSubmitting: boolean;
    uploadToServer: () => void;
    storePreviousAndClose: () => void;
}

function FabricCanvas({
    backgroundImage,
    canvasRef,
    canvasDimension,
    deviceMode,
    // isMobilelandscape,
    // isTablet,
    discardCanvas,
    isSubmitting,
    uploadToServer,
    storePreviousAndClose,
}: FabricCanvasInterface) {
    const { t } = useTranslation();

    const [tool, setTool] = useState([
        {
            toolName: 'pen',
            icon: Pen,
        },
        {
            toolName: 'highlight',
            icon: Highlighter,
        },
        {
            toolName: 'eraser',
            icon: Eraser,
        },
        {
            toolName: 'shape',
            icon: ShapeIcon,
        },
        {
            toolName: 'textBox',
            icon: TextIcon,
        },
        {
            toolName: 'undo',
            icon: Undo,
        },
    ]);
    const [rightTool, setRightTool] = useState<any>([]);
    const [toolStatus, setToolStatus] = useState<any>();
    const [rightToolStatus, setRightToolStatus] = useState<any>();
    const [toolName, setToolName] = useState('pen');
    const [optionStatus, setOptionStatus] = useState<any>(0);
    const [optionPicker, setOptionPicker] = useState<any>(utilConstants.PENCIL_COLOR);

    const [holdState, setHoldState] = useState<any>({
        penColor: '#000000',
        penColorStatus: 0,
        highlightColor: 'rgba(255, 236, 61,0.3)',
        highlightColorStatus: 0,
        shapeColor: '#f20000',
        shapeColorStatus: 0,
        textColor: '#000000',
        textColorStatus: 0,
        erasorThickness: EraserSize.Large,
        erasorToolStatus: 2,
    });

    const optionHandler = (index: number, color?: string, highlightColor?: string) => {
        switch (toolName) {
            case 'pen': {
                canvasRef.current.handlePencilColor(color);
                setHoldState({
                    ...holdState,
                    penColor: color,
                    penColorStatus: index,
                });
                break;
            }
            case 'highlight': {
                canvasRef.current.handlePencilColor(highlightColor);
                setHoldState({
                    ...holdState,
                    highlightColor: highlightColor,
                    highlightColorStatus: index,
                });
                break;
            }
            case 'shape': {
                canvasRef.current.handleShapeColor(color);
                setHoldState({
                    ...holdState,
                    shapeColor: color,
                    shapeColorStatus: index,
                });
                break;
            }

            case 'textBox': {
                canvasRef.current.handleTextColor(color);

                setHoldState({
                    ...holdState,
                    textColor: color,
                    textColorStatus: index,
                });
                break;
            }
            default: {
                canvasRef.current.handlePencilColor(color);
                setHoldState({
                    ...holdState,
                    penColor: color,
                    penColorStatus: index,
                });
            }
        }
    };

    const ErasorHandler = (index: number, currentTool?: string) => {
        switch (currentTool) {
            case 'sm-erasor': {
                setHoldState({
                    ...holdState,
                    erasorThickness: EraserSize.Small,
                    erasorToolStatus: index,
                });
                canvasRef.current.handleErasorSize(EraserSize.Small);
                break;
            }
            case 'md-erasor': {
                setHoldState({
                    ...holdState,
                    erasorThickness: EraserSize.Medium,
                    erasorToolStatus: index,
                });
                canvasRef.current.handleErasorSize(EraserSize.Medium);
                break;
            }
            case 'lg-erasor': {
                setHoldState({
                    ...holdState,
                    erasorThickness: EraserSize.Large,
                    erasorToolStatus: index,
                });
                canvasRef.current.handleErasorSize(EraserSize.Large);
                break;
            }

            default: {
                setHoldState({
                    ...holdState,
                    erasorThickness: EraserSize.Large,
                    erasorToolStatus: index,
                });
                canvasRef.current.handleErasorSize(EraserSize.Large);
            }
        }
    };

    const toolHandler = (index: number, selectedTool: string, toolType: string) => {
        if (toolType === 'main' && selectedTool !== 'undo') {
            setToolStatus(index);
            setToolName(selectedTool);
        }

        switch (selectedTool) {
            case 'pen': {
                canvasRef.current.handlePencil();
                setRightTool([]);
                setOptionPicker(utilConstants.PENCIL_COLOR);
                canvasRef.current.handlePencilColor(holdState.penColor);
                setOptionStatus(holdState.penColorStatus);
                break;
            }
            case 'highlight': {
                canvasRef.current.handleHighlight();
                setRightTool([]);
                setOptionPicker(utilConstants.HIGHLIGHT_COLOR);
                canvasRef.current.handlePencilColor(holdState.highlightColor);
                setOptionStatus(holdState.highlightColorStatus);
                break;
            }
            case 'eraser': {
                canvasRef.current.handleEraser();
                if (holdState.erasorThickness === EraserSize.Small) {
                    setHoldState({
                        ...holdState,
                        erasorThickness: EraserSize.Small,
                        erasorToolStatus: 0,
                    });
                    canvasRef.current.handleErasorSize(EraserSize.Small);
                } else if (holdState.erasorThickness === EraserSize.Medium) {
                    setHoldState({
                        ...holdState,
                        erasorThickness: EraserSize.Medium,
                        erasorToolStatus: 1,
                    });
                    canvasRef.current.handleErasorSize(EraserSize.Medium);
                } else if (holdState.erasorThickness === EraserSize.Large) {
                    setHoldState({
                        ...holdState,
                        erasorThickness: EraserSize.Large,
                        erasorToolStatus: 2,
                    });
                    canvasRef.current.handleErasorSize(EraserSize.Large);
                }

                setRightTool(utilConstants.ERASOR_SIZE_TOOL);

                break;
            }

            case 'shape': {
                if (rightToolStatus) {
                    if (rightToolStatus === 0) {
                        canvasRef.current.handleRect();
                        setRightToolStatus(0);
                    } else if (rightToolStatus === 1) {
                        canvasRef.current.handleCircle();
                        setRightToolStatus(1);
                    } else {
                        canvasRef.current.handleLine();
                        setRightToolStatus(2);
                    }
                    setOptionPicker(utilConstants.SHAPE_COLOR);
                    setRightTool(utilConstants.SHAPE_RIGHT_TOOL);
                    canvasRef.current.handleShapeColor(holdState.shapeColor);
                    setOptionStatus(holdState.shapeColorStatus);
                    break;
                }
                canvasRef.current.handleRect();
                setRightToolStatus(0);
                setOptionPicker(utilConstants.SHAPE_COLOR);
                setRightTool(utilConstants.SHAPE_RIGHT_TOOL);
                canvasRef.current.handleShapeColor(holdState.shapeColor);
                setOptionStatus(holdState.shapeColorStatus);
                break;
            }
            case 'rectangle': {
                canvasRef.current.handleRect();
                setRightToolStatus(index);
                setOptionPicker(utilConstants.SHAPE_COLOR);
                setRightTool(utilConstants.SHAPE_RIGHT_TOOL);
                canvasRef.current.handleShapeColor(holdState.shapeColor);
                setOptionStatus(holdState.shapeColorStatus);
                break;
            }
            case 'circle': {
                canvasRef.current.handleCircle();
                setRightToolStatus(index);
                setOptionPicker(utilConstants.SHAPE_COLOR);
                setRightTool(utilConstants.SHAPE_RIGHT_TOOL);
                canvasRef.current.handleShapeColor(holdState.shapeColor);
                setOptionStatus(holdState.shapeColorStatus);
                break;
            }
            case 'line': {
                canvasRef.current.handleLine();
                setRightToolStatus(index);
                setOptionPicker(utilConstants.SHAPE_COLOR);
                setRightTool(utilConstants.SHAPE_RIGHT_TOOL);
                canvasRef.current.handleShapeColor(holdState.shapeColor);
                setOptionStatus(holdState.shapeColorStatus);
                break;
            }
            case 'textBox': {
                canvasRef.current.handleTextBox();
                setRightTool([]);
                setOptionPicker(utilConstants.PENCIL_COLOR);
                canvasRef.current.handlePencilColor(holdState.textColor);
                setOptionStatus(holdState.textColorStatus);
                break;
            }
            case 'undo': {
                canvasRef.current.undoCanvas();
                break;
            }
            default: {
                setToolName('pen');
                setRightTool([]);
                setOptionPicker(utilConstants.PENCIL_COLOR);
                canvasRef.current.handlePencilColor(holdState.penColor);
                setOptionStatus(holdState.penColorStatus);
                break;
            }
        }
    };

    useEffect(() => {
        if (toolName === 'pen') {
            setOptionStatus(holdState.penColorStatus);
        } else if (toolName === 'highlight') {
            setOptionStatus(holdState.highlightColorStatus);
        } else if (toolName === 'shape') {
            setOptionStatus(holdState.shapeColorStatus);
        } else if (toolName === 'textBox') {
            setOptionStatus(holdState.textColorStatus);
        }
    }, [
        holdState.penColorStatus,
        holdState.highlightColorStatus,
        holdState.shapeColorStatus,
        holdState.textColorStatus,
    ]);

    useEffect(() => {
        toolHandler(0, 'pen', 'main');
    }, []);

    return (
        <>
            <div
                className={`slide_drawing_box ${
                    deviceMode === 'mobile' && canvasDimension.height === 300 && 'heightFull'
                }`}
            >
                <CanvasDraw
                    ref={canvasRef}
                    backgroundImage={backgroundImage}
                    canvasDimension={canvasDimension}
                    // isMobilelandscape={isMobilelandscape}
                />
            </div>

            <div
                style={{
                    height: deviceMode === 'mobile' ? `${canvasDimension.height}px` : 'max-content',
                    minHeight: deviceMode === 'mobile' ? `300px` : 'max-content',
                }}
                id="toolsWrapper"
                className={`toolsWrapper`}
            >
                <div className={`toolbar ${deviceMode === 'pc' && 'pcMode'}`}>
                    <div className="toolleft">
                        <ul>
                            {tool.map((item: any, index: any) => (
                                <li
                                    key={index}
                                    className={toolStatus === index ? 'active' : ''}
                                    onClick={() => toolHandler(index, item.toolName, 'main')}
                                >
                                    <item.icon />
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="toolright">
                        {toolName !== 'eraser' ? (
                            <ul>
                                {rightTool.length > 0
                                    ? rightTool.map((item: any, index: any) => (
                                          <li
                                              key={index}
                                              className={rightToolStatus === index ? 'active' : ''}
                                              onClick={() => toolHandler(index, item.toolName, 'sub')}
                                          >
                                              <item.icon />
                                          </li>
                                      ))
                                    : null}

                                {optionPicker.map((item: any, index: any) => (
                                    <li
                                        key={index}
                                        onClick={() => {
                                            optionHandler(index, item.color, item.highlightColor);
                                        }}
                                    >
                                        <div
                                            className={optionStatus === index ? 'color active' : 'color'}
                                            style={{
                                                backgroundColor: item.color,
                                            }}
                                        >
                                            <DoneIcon />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <ul>
                                {rightTool.map((item: any, index: any) => (
                                    <li
                                        key={index}
                                        // className={toolStatus === index ? 'active' : ''}
                                        onClick={() => {
                                            ErasorHandler(index, item.toolName);
                                        }}
                                    >
                                        <div
                                            className={
                                                holdState.erasorToolStatus === index
                                                    ? `${item.toolName} color active`
                                                    : `color ${item.toolName}`
                                            }
                                            style={{
                                                backgroundColor: item.color,
                                            }}
                                        >
                                            <DoneIcon />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {/* <div className={`note_for_mobile`}>
                        {false ? (
                            <>
                                <div className="sh_btn images_crop block">
                                    <Button variant="contained" className="cancel" onClick={() => discardCanvas()}>
                                        {t(`lang_activity.btn_discard`)}
                                    </Button>
                                    {!isSubmitting ? (
                                        <Button
                                            variant="contained"
                                            onClick={(e) => {
                                                uploadToServer();
                                            }}
                                        >
                                            {t(`lang_activity.btn_submit`)}
                                        </Button>
                                    ) : (
                                        <LoaddingButton />
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <Typography
                                    onClick={() => {
                                        uploadToServer();
                                    }}
                                    variant="body1"
                                    className={isSubmitting ? 'disabledbtn' : ''}
                                >
                                    {t('lang_activity.btn_submit')}
                                </Typography>
                                <Typography
                                    onClick={() => {
                                        discardCanvas();
                                    }}
                                    variant="body1"
                                    className="discard"
                                >
                                    {t('lang_activity.btn_discard')}
                                </Typography>
                            </>
                        )}
                    </div> */}
                </div>

                <div
                    className={`sh_btn images_crop ${
                        canvasDimension.height < 375 && deviceMode === 'mobile' && 'smallButtons'
                    }`}
                >
                    <Button variant="contained" className="cancel" onClick={() => discardCanvas()}>
                        <p className={`btnText`}>{t(`lang_activity.btn_discard`)}</p>
                        <ClearIcon className={`smallScreenIcon`} />
                    </Button>

                    <LoadingButton
                        loading={isSubmitting}
                        loadingPosition="start"
                        variant="contained"
                        onClick={(e) => {
                            uploadToServer();
                        }}
                    >
                        {!isSubmitting && (
                            <>
                                <p className={`btnText`}>{t(`lang_activity.btn_submit`)}</p>
                                <DoneIcon className={`smallScreenIcon`} />
                            </>
                        )}
                    </LoadingButton>
                </div>
            </div>

            <IconButton className={`closeIconWrapper`} onClick={() => storePreviousAndClose()}>
                <ClearIcon className={`closeIcon`} />
            </IconButton>
        </>
    );
}

export default FabricCanvas;
