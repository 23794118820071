import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import Constants from '../../../../../../constants';
import { StoreInterface } from '../../../../../../interfaces/store.interface';
import SlideDrawingModal from './modal/SlideDrawingModal';

function SlideDrawingInput() {
    const { t } = useTranslation();

    const activity = useSelector((state: StoreInterface) => state.activity);

    const [open, setOpen] = useState(false);
    // const [isMobilelandscape, setIsMobilelandscape] = useState(false);

    // const isMobile = {
    //     Android: function () {
    //         return navigator.userAgent.match(/Android/i);
    //     },
    //     BlackBerry: function () {
    //         return navigator.userAgent.match(/BlackBerry/i);
    //     },
    //     iOS: function () {
    //         return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    //     },
    //     Opera: function () {
    //         return navigator.userAgent.match(/Opera Mini/i);
    //     },
    //     Windows: function () {
    //         return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    //     },
    //     any: function () {
    //         return (
    //             isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()
    //         );
    //     },
    // };
    // const userAgent = navigator.userAgent.toLowerCase();
    // const isTablet =
    //     /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    //         userAgent,
    //     );

    const handleOpen = () => {
        // if (isMobile.any() || isTablet) detectMobile();
        setOpen(true);
    };

    useEffect(() => {
        localStorage.removeItem('canvasData');
        setOpen(false);
    }, [activity]);

    // const detectMobile = () => {
    //     // Add a short delay to preventing reading old data
    //     setTimeout(() => {
    //         if (window.matchMedia('(orientation: portrait)').matches) {
    //             setIsMobilelandscape(false);
    //         }
    //         if (window.matchMedia('(orientation: landscape)').matches) {
    //             setIsMobilelandscape(true);
    //         }
    //     }, 100);
    // };

    // useEffect(() => {
    //     window.addEventListener('orientationchange', detectMobile);
    //     return () => window.removeEventListener('orientationchange', detectMobile);
    // }, []);

    return (
        <>
            <SlideDrawingModal
                open={open}
                handleClose={() => setOpen(false)}
                // handleOpen={handleOpen}
                // isMobilelandscape={isMobilelandscape}
                // isTablet={isTablet}
            />
            {activity.activityStatus !== 'submitting' ||
                (activity.submittedResponses.length === 0 && (
                    <div className="upload_buttons">
                        <div className="upload_grid">
                            <div className="box_12">
                                <Button className="box_image" onClick={() => handleOpen()}>
                                    <img src={Constants.IMAGE_URLS.drawing} alt="msgicon" />
                                    <Typography>{t('lang_activity.btn_open_slide')}</Typography>
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
}

export default SlideDrawingInput;
