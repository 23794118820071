import { logger } from '../services/logger';

export const checkIfFillBlanksIsAllCorrect = (fbCorrectAnswers: string[], responseData: string) => {
    const responseArray: string[] = JSON.parse(responseData);
    for (let index = 0; index < responseArray.length; index++) {
        const possibeCorrectAnswers: string[] = JSON.parse(fbCorrectAnswers[index] || '[]');
        if (
            !possibeCorrectAnswers
                .map((correctAnswer) => correctAnswer.trim().toLowerCase())
                .includes(responseArray[index].trim().toLowerCase())
        )
            return false;
    }
    return true;
};

export const checkIfSingleBlankIsCorrect = (possibleCorrectAnswersString: string, answer: string) => {
    if (!possibleCorrectAnswersString) return false;
    const possibeCorrectAnswers: string[] = JSON.parse(possibleCorrectAnswersString);
    return possibeCorrectAnswers
        .map((correctAnswer) => correctAnswer.trim().toLowerCase())
        .includes(answer.trim().toLowerCase());
};

export const getVideoDuration = (file: any): Promise<number> =>
    new Promise((resolve, reject) => {
        try {
            const startTime = new Date();
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result as string;
                if (!result) resolve(0);
                else {
                    const media = new Audio(result);
                    media.onloadedmetadata = () => {
                        const timeTaken = (new Date().getTime() - startTime.getTime()) / 1000;
                        logger.log(`Video duration: ${media.duration}s; time taken for computation: ${timeTaken}s`);
                        resolve(media.duration);
                    };
                }
            };
            reader.onerror = (error: any) => {
                logger.error('getVideoDuration() error', error);
                // reject(error);
                resolve(0);
            };
            reader.readAsDataURL(file);
        } catch (err) {
            logger.error('getVideoDuration() error', err);
            resolve(0);
        }
    });

export const getVideoFileSize = (file: File): number => {
    return Math.ceil(file.size / 1024 / 1024);
};

export const generateMaxDurationDisplay = (duration: number): string => {
    if (duration >= 60) {
        if (duration % 60 === 0) return duration === 60 ? `1 minute` : `${duration / 60} minutes`;
        else return `${(duration / 60).toFixed(1)} minutes`;
    } else return `${duration} seconds`;
};

export const toHHMMSS = (timerTime: number) => {
    var sec_num = Math.floor(timerTime);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0)
        .join(':');
};

export const isIosDevice = () => {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform,
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

export const getIosVersion = () => {
    const ua: any = navigator.userAgent;
    if (/(iPhone|iPod|iPad)/i.test(ua)) {
        return ua
            .match(/OS [\d_]+/i)[0]
            .substr(3)
            .split('_')
            .map((n: any) => parseInt(n));
    }
    return [0];
};
