import { ActionTypes } from '../constants/action-types.enum';
import { logger } from '../services/logger';
import { store } from './../helpers/store';

const classCodeValidated = (classCode: string, presenterEmail: string, cpcsRegion: string) => ({
    type: ActionTypes.CLASS_CODE_VALIDATED,
    payload: {
        classCode,
        presenterEmail,
        cpcsRegion,
    },
});

const updateUsernameAndAvatar = (participantUsername: string, participantAvatar: string) => ({
    type: ActionTypes.UPDATE_USERNAME_AND_AVATAR,
    payload: {
        participantUsername,
        participantAvatar,
    },
});

const backToStepTwo = () => ({
    type: ActionTypes.BACK_TO_JOIN_STEP_TWO,
});

const leaveCurrentAndUpdateClassCode = async (classCode: string) => {
    try {
        await store.getState().connection.invoke('ParticipantLeaveClass', store.getState().participant);
        await store.getState().connection.stop();
        store.dispatch({ type: ActionTypes.LEFT_CLASS });
        store.dispatch({
            type: ActionTypes.UPDATE_CLASS_CODE,
            payload: { classCode },
        });
    } catch (e) {
        logger.log(e);
        store.dispatch({ type: ActionTypes.LEFT_CLASS });
        store.dispatch({
            type: ActionTypes.UPDATE_CLASS_CODE,
            payload: { classCode },
        });
    }
};

export const changeLanguage = (lang: string) => ({
    type: ActionTypes.CHANGE_LANGUAGE,
    payload: lang,
});

export const showApiError = () => ({
    type: ActionTypes.SHOW_API_ERROR,
});

export const dismissApiError = () => ({
    type: ActionTypes.DISMISS_API_ERROR,
});

export const participantActions = {
    classCodeValidated,
    updateUsernameAndAvatar,
    backToStepTwo,
    leaveCurrentAndUpdateClassCode,
    changeLanguage,
    showApiError,
    dismissApiError,
};
