import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Avatar, Typography } from '@mui/material';

import './LeaderboardModal.scss';

import { calculateParticipantLevelAndBadge, getLevelsArray } from '../../../../../helpers/userHelper';

import LeaderboardParticipantInterface from '../../../../../interfaces/leaderboard-participant.interface';
import { StoreInterface } from '../../../../../interfaces/store.interface';
import { utilConstants } from '../../../../../constants/utils.constants';
import { ActionTypes } from '../../../../../constants/action-types.enum';

import CustomAvatar, { AvatarType } from '../../../../Common/CustomAvatar';

const Leaderboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const presenter = useSelector((state: StoreInterface) => state.presenter);
    const participant = useSelector((state: StoreInterface) => state.participant);
    const leaderboard = useSelector((state: StoreInterface) => state.leaderboard);
    const classGroups = useSelector((state: StoreInterface) => state.currentClass.groups);

    const [participantRank, setParticipantRank] = useState(0);

    const LimitParticipantRank = 10;

    useEffect(() => {
        const findParticipant = leaderboard.find((leader) => leader.participantId === participant.participantId);
        if (findParticipant) setParticipantRank(findParticipant.participantRank);
    }, [leaderboard]);

    leaderboard.sort((a: any, b: any) => {
        return b.score - a.score;
    });

    const firstGroup = leaderboard.filter((leader) => leader.participantRank === 1);
    const secondGroup = leaderboard.filter((leader) => leader.participantRank === 2);
    const thirdGroup = leaderboard.filter((leader) => leader.participantRank === 3);

    const displayMultipleLeaders = (leaders: LeaderboardParticipantInterface[]): string => {
        switch (leaders.length) {
            case 1:
                return leaders[0]?.participantName;
            case 2:
                return `${leaders[0]?.participantName}, ${leaders[1]?.participantName}`;
            default:
                return `${leaders[0]?.participantName} + ${leaders.length - 1}`;
        }
    };

    const dismissLeaderboard = () => {
        dispatch({
            type: ActionTypes.DISMISS_LEADERBOARD,
        });
    };

    return (
        <Modal
            open={leaderboard.length > 0}
            onClose={dismissLeaderboard}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="leaderboard_modal"
        >
            <Box className="leaderboard_modalinner">
                <div className="leader_board_rank_barchart">
                    <ul className="leader_board_rank_chart ">
                        <li className={`second ${!secondGroup.length && 'no_place'}`}>
                            <span className="chart_tooltip">
                                <div className={`crown_avater ${secondGroup.length === 2 && 'double'}`}>
                                    {secondGroup.length ? (
                                        <div className="images">
                                            {secondGroup.map((participant, index: number) => {
                                                return secondGroup.length === 2 ? (
                                                    <CustomAvatar
                                                        key={index}
                                                        avatarName={participant.participantName}
                                                        avatarUrl={participant.participantAvatar}
                                                        type={AvatarType.PARTICIPANT}
                                                        savedClassGroups={classGroups}
                                                        groupId={participant.groupId}
                                                    />
                                                ) : index === 0 ? (
                                                    <CustomAvatar
                                                        key={index}
                                                        avatarName={participant.participantName}
                                                        avatarUrl={participant.participantAvatar}
                                                        type={AvatarType.PARTICIPANT}
                                                        savedClassGroups={classGroups}
                                                        groupId={participant.groupId}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        key={index}
                                                        alt={participant.participantName}
                                                        className={index === 1 ? 'left_image' : 'right_image'}
                                                        src={utilConstants.IMAGE_URLS.multipleWinnerAvatar}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <Avatar className="noAvatar" alt="?" src={utilConstants.IMAGE_URLS.noAvatar} />
                                    )}
                                </div>
                                <Typography variant="body1">{displayMultipleLeaders(secondGroup)}</Typography>
                                <div className="point_star_box">
                                    <img src={utilConstants.IMAGE_URLS.quizSummaryIconStar} alt="point_icon" />
                                    <Typography variant="body1">{secondGroup[0]?.participantSumPoints}</Typography>
                                </div>
                            </span>
                            <div className="bar">
                                <div className="level_display">
                                    <img
                                        src={
                                            calculateParticipantLevelAndBadge(
                                                secondGroup[0]?.participantSumPoints,
                                                getLevelsArray(presenter),
                                            ).badgeUrl
                                        }
                                        alt="level_icon"
                                    />
                                    <Typography variant="body1">
                                        Lv.{' '}
                                        {
                                            calculateParticipantLevelAndBadge(
                                                secondGroup[0]?.participantSumPoints,
                                                getLevelsArray(presenter),
                                            ).level
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </li>
                        <li className={`first ${!firstGroup.length && 'no_place'}`}>
                            <span className="chart_tooltip">
                                <div className={`crown_avater ${firstGroup.length === 2 && 'double'}`}>
                                    {firstGroup.length ? (
                                        <div className="images">
                                            {firstGroup.map((participant, index: number) => {
                                                return firstGroup.length === 2 ? (
                                                    <CustomAvatar
                                                        key={index}
                                                        avatarName={participant.participantName}
                                                        avatarUrl={participant.participantAvatar}
                                                        type={AvatarType.PARTICIPANT}
                                                        savedClassGroups={classGroups}
                                                        groupId={participant.groupId}
                                                    />
                                                ) : index === 0 ? (
                                                    <CustomAvatar
                                                        key={index}
                                                        avatarName={participant.participantName}
                                                        avatarUrl={participant.participantAvatar}
                                                        type={AvatarType.PARTICIPANT}
                                                        savedClassGroups={classGroups}
                                                        groupId={participant.groupId}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        key={index}
                                                        alt={participant.participantName}
                                                        className={index === 1 ? 'left_image' : 'right_image'}
                                                        src={utilConstants.IMAGE_URLS.multipleWinnerAvatar}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <Avatar className="noAvatar" alt="?" src={utilConstants.IMAGE_URLS.noAvatar} />
                                    )}
                                    <img src={utilConstants.IMAGE_URLS.crown} alt="crown" className="crown_img" />
                                </div>
                                <Typography variant="body1">{displayMultipleLeaders(firstGroup)}</Typography>
                                <div className="point_star_box">
                                    <img src={utilConstants.IMAGE_URLS.quizSummaryIconStar} alt="point_icon" />
                                    <Typography variant="body1">{firstGroup[0]?.participantSumPoints}</Typography>
                                </div>
                            </span>
                            <div className="bar">
                                <div className="level_display">
                                    <img
                                        src={
                                            calculateParticipantLevelAndBadge(
                                                firstGroup[0]?.participantSumPoints,
                                                getLevelsArray(presenter),
                                            ).badgeUrl
                                        }
                                        alt="level_icon"
                                    />
                                    <Typography variant="body1">
                                        Lv.{' '}
                                        {
                                            calculateParticipantLevelAndBadge(
                                                firstGroup[0]?.participantSumPoints,
                                                getLevelsArray(presenter),
                                            ).level
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </li>
                        <li className={`third ${!thirdGroup.length && 'no_place'}`}>
                            <span className="chart_tooltip">
                                <div className={`crown_avater ${thirdGroup.length === 2 && 'double'}`}>
                                    {thirdGroup.length ? (
                                        <div className="images">
                                            {thirdGroup.map((participant, index: number) => {
                                                return thirdGroup.length === 2 ? (
                                                    <CustomAvatar
                                                        key={index}
                                                        avatarName={participant.participantName}
                                                        avatarUrl={participant.participantAvatar}
                                                        type={AvatarType.PARTICIPANT}
                                                        savedClassGroups={classGroups}
                                                        groupId={participant.groupId}
                                                    />
                                                ) : index === 0 ? (
                                                    <CustomAvatar
                                                        key={index}
                                                        avatarName={participant.participantName}
                                                        avatarUrl={participant.participantAvatar}
                                                        type={AvatarType.PARTICIPANT}
                                                        savedClassGroups={classGroups}
                                                        groupId={participant.groupId}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        key={index}
                                                        alt={participant.participantName}
                                                        className={index === 1 ? 'left_image' : 'right_image'}
                                                        src={utilConstants.IMAGE_URLS.multipleWinnerAvatar}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <Avatar className="noAvatar" alt="?" src={utilConstants.IMAGE_URLS.noAvatar} />
                                    )}
                                </div>
                                <Typography variant="body1">{displayMultipleLeaders(thirdGroup)}</Typography>
                                <div className="point_star_box">
                                    <img src={utilConstants.IMAGE_URLS.quizSummaryIconStar} alt="point_icon" />
                                    <Typography variant="body1">{thirdGroup[0]?.participantSumPoints}</Typography>
                                </div>
                            </span>
                            <div className="bar">
                                <div className="level_display">
                                    <img
                                        src={
                                            calculateParticipantLevelAndBadge(
                                                thirdGroup[0]?.participantSumPoints,
                                                getLevelsArray(presenter),
                                            ).badgeUrl
                                        }
                                        alt="level_icon"
                                    />
                                    <Typography variant="body1">
                                        Lv.{' '}
                                        {
                                            calculateParticipantLevelAndBadge(
                                                thirdGroup[0]?.participantSumPoints,
                                                getLevelsArray(presenter),
                                            ).level
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                {participantRank > 0 && participantRank <= LimitParticipantRank && (
                    <div className="current_participent_rank">
                        <Typography variant="body1">
                            {t('lang_leaderboard.txt_your_rank')}
                            <Typography variant="caption">
                                <strong>{participantRank}</strong>
                            </Typography>
                        </Typography>
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default Leaderboard;
