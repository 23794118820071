import { Typography } from '@mui/material';
import { Fragment } from 'react';
import Constants from '../../../../../../constants';
import { ActivityTypes } from '../../../../../../constants/activity-types.enum';
import { useTranslation } from 'react-i18next';

function ActivityTitleHead({ activityType }: any) {
    const { t } = useTranslation();

    let icon = '';
    switch (activityType) {
        case ActivityTypes.MULTIPLE_CHOICE:
            icon = Constants.IMAGE_URLS.multichoice;
            break;
        case ActivityTypes.SHORT_ANSWER:
            icon = Constants.IMAGE_URLS.chaticon;
            break;
        case ActivityTypes.AUDIO_RECORD:
            icon = Constants.IMAGE_URLS.frequency;
            break;
        case ActivityTypes.SLIDE_DRAWING:
            icon = Constants.IMAGE_URLS.slideDrawingIcon;
            break;
        case ActivityTypes.IMAGE_UPLOAD:
            icon = Constants.IMAGE_URLS.imageUpload;
            break;
        case ActivityTypes.VIDEO_UPLOAD:
            icon = Constants.IMAGE_URLS.videoUpload;
            break;
        case ActivityTypes.FILL_IN_THE_BLANKS:
            icon = Constants.IMAGE_URLS.fillblanks;
            break;
        case ActivityTypes.WORD_CLOUD:
            icon = Constants.IMAGE_URLS.wordCloud;
            break;
        case ActivityTypes.QUICK_POLL:
            icon = Constants.IMAGE_URLS.qp_image;
            break;
        default:
            break;
    }

    return (
        <Fragment>
            <div className="active_title_head">
                <img src={icon} alt="msgicon" />
                <Typography variant="h4"> {t(`lang_activity_names.${activityType}`)}</Typography>
            </div>
        </Fragment>
    );
}

export default ActivityTitleHead;
